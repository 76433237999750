import React from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const KanbanDueDateExpressContainer = ({
  newPostJobDueDate,
  setNewPostJobDueDate,
  newPostJobExpress,
  setNewPostJobExpress,
  newPostJobExpressDueDate,
  setNewPostJobExpressDueDate,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        marginTop: 0,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Due Date"
          format="DD/MM/YYYY"
          value={dayjs(newPostJobDueDate) || null}
          onChange={(e) => {
            const newDate = e.toDate();
            console.log(e);
            try {
              newDate.setHours(17);
              newDate.setMinutes(30);

              console.log(newDate.toISOString());
              setNewPostJobDueDate(newDate.toISOString());
            } catch (err) {
              console.log(err);
              console.log(newDate.toISOString());
              setNewPostJobDueDate(null);
            }
          }}
          slotProps={{
            textField: {
              // fullWidth: true,
              sx: { width: "56%" },
              variant: "filled",
              InputProps: { disableUnderline: true },
            },
          }}

          // renderInput={(params) => (
          //   <TextField
          //     {...params}
          //     error={newPostJobDueDate ? false : true}
          //     sx={{ marginTop: 2, width: "56%" }}
          //   />
          // )}
        />
      </LocalizationProvider>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "100%",
        }}
      >
        <FormGroup
          style={{
            width: "80%",
            display: "flex",
            alignItems: "center",
          }}
          sx={{ marginTop: 2 }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={newPostJobExpress || false}
                onChange={(e) => {
                  setNewPostJobExpress(e.target.checked);
                }}
              />
            }
            label="Express"
          />
        </FormGroup>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label="Express Due Date"
            value={dayjs(newPostJobExpressDueDate) || null}
            format="DD/MM/YYYY hh:mm"
            onChange={(e) => {
              setNewPostJobExpressDueDate(e.toISOString() || null);
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: "filled",
                InputProps: { disableUnderline: true },
              },
            }}

            // renderInput={(params) => (
            //   <TextField {...params} fullWidth sx={{ marginTop: 2 }} />
            // )}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default KanbanDueDateExpressContainer;
