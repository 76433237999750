import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircleIcon from "@mui/icons-material/Circle";

import { useAtom } from "jotai";
import { themeAtom } from "../../-Atoms";

const CalendarSelector = ({
  isPostProductionJob,
  studio,
  setStudio,
  firebase,
  jobData,
  studios,
  calendarColours,
  canEdit,
}) => {
  const [theme] = useAtom(themeAtom);
  return (<>
    <FormControl
      fullWidth
      sx={{ marginTop: 1 }}
      style={{ display: isPostProductionJob ? "none" : "inline-flex" }}
      variant="filled"
    >
      <InputLabel id="studio-calendar">Studio / Calendar</InputLabel>
      <Select
        disableUnderline
        labelId="studio-calendar-select"
        value={studio || ""}
        label="Studio / Calendar"
        error={studio ? false : true}
        onChange={(e) => {
          if (!canEdit) return;
          setStudio(e.target.value);
          firebase
            .firestore()
            .collection("JobData")
            .doc(jobData.id)
            .update({ studio: e.target.value }, { merge: true });
        }}
      >
        {studios.map((s) => (
          <MenuItem key={s} value={s}>
            <span style={{ display: "flex", aliognItems: "center" }}>
              <CircleIcon
                style={{
                  fill:
                    theme === "dark"
                      ? calendarColours[s].darkBackground.replace(
                          /(#(?:[\da-fA-F]{6}|[\da-fA-F]{3}))[\da-fA-F]{2}/,
                          "$1"
                        ) || "#000000"
                      : calendarColours[s].background || "#000000",
                  marginRight: 10,
                }}
              />
              {s}
            </span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </>);
};

export default CalendarSelector;
