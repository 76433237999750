import React from "react";

import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import TextField from "@mui/material/TextField";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

const AdditionalDates = ({
  firebase,
  jobData,
  additionalDates,
  setAdditionalDates,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ marginBottom: 10 }}>
        {additionalDates &&
          additionalDates.map((additionalDate, index) => (
            <AdditionalDate
              key={`${additionalDate.start}-${index}`}
              index={index}
              start={additionalDate.start}
              end={additionalDate.end}
              additionalDates={additionalDates}
              setAdditionalDates={setAdditionalDates}
              jobData={jobData}
              firebase={firebase}
            />
          ))}
      </div>
    </LocalizationProvider>
  );
};

export default AdditionalDates;

const AdditionalDate = ({
  index,
  start,
  end,
  additionalDates,
  setAdditionalDates,
  jobData,
  firebase,
}) => {
  // Ensure that 'start' and 'end' are dayjs objects
  const startValue = start ? dayjs(start) : null;
  const endValue = end ? dayjs(end) : null;

  const handleStartChange = (newValue) => {
    try {
      additionalDates[index].start = newValue.toISOString();
      updateAdditionalDates(
        firebase,
        additionalDates,
        setAdditionalDates,
        jobData
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleEndChange = (newValue) => {
    try {
      additionalDates[index].end = newValue.toISOString();
      updateAdditionalDates(
        firebase,
        additionalDates,
        setAdditionalDates,
        jobData
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 15,
        marginTop: 15,
      }}
    >
      <DateTimePicker
        label="Additional Start Date"
        value={startValue}
        format="DD/MM/YYYY hh:mm A"
        onChange={handleStartChange}
        slotProps={{
          textField: {
            fullWidth: true,
            variant: "filled",
            InputProps: { disableUnderline: true },
          },
        }}
      />
      <DateTimePicker
        label="Additional End Date"
        value={endValue}
        format="DD/MM/YYYY hh:mm A"
        onChange={handleEndChange}
        slotProps={{
          textField: {
            fullWidth: true,
            variant: "filled",
            InputProps: { disableUnderline: true },
          },
        }}
      />
      <IconButton
        onClick={() => {
          additionalDates.splice(index, 1);
          updateAdditionalDates(
            firebase,
            additionalDates,
            setAdditionalDates,
            jobData
          );
        }}
      >
        <RemoveIcon />
      </IconButton>
    </div>
  );
};

const updateAdditionalDates = (
  firebase,
  additionalDates,
  setAdditionalDates,
  jobData
) => {
  setAdditionalDates([...additionalDates]);
  firebase
    .firestore()
    .collection("JobData")
    .doc(jobData.id)
    .update({ additionalDates });
};
