import React from "react";
import "./App.css";
import "./GarmentTracking.css";
import WIP from "./WIP";

const wip = true;
function GarmentTracking() {
  // const [value, onChange] = useState(new Date());
  return (
    <>
      {wip ? (
        <WIP />
      ) : (
        <table className="garmentTable">
          <thead>
            <tr>
              <th>Job Number</th>
              <th>Client Name</th>
              <th>Shoot Date</th>
              <th className="dateTime">Arrival Date</th>
              <th>Sample Count</th>
              <th>Ready To Be Packed</th>
              <th>Is Packed</th>
              <th>Departure Date</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="9" className="center addNewRowButton">
                Add New
              </td>
            </tr>
            <tr>
              <td>21009</td>
              <td>Sass & Bide</td>
              <td></td>
              <td className="dateTime"></td>
              <td className="centerTd">132</td>
              <td className="centerTd">
                <input type="checkbox" />
              </td>
              <td className="centerTd">
                <input type="checkbox" />
              </td>
              <td></td>
              <td className="notes"></td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
}

export default GarmentTracking;
