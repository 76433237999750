import React, { useState, useEffect, useRef } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CircularProgress from "@mui/material/CircularProgress";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { saveAs } from "file-saver";

let arrayRemove;

const FileSizeContainer = ({
  fs,
  firebase,
  client,
  showDeleteSupplySize,
  kind,
  theme,
}) => {
  arrayRemove = firebase.firestore.FieldValue.arrayRemove;
  const textFieldStyle = {
    margin: "10px",
    minWidth: 270,
    maxWidth: 270,
  };
  const textFieldStyleSml = {
    margin: "10px",
    maxWidth: "125px",
  };
  const cardStyle = {
    background:
      theme === "dark" ? "rgba(255, 255, 255, 0.05)" : "rgba(0, 0, 0, 0.02)",
    borderRadius: "10px",
    margin: "10px",
    paddingTop: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    flexWrap: "wrap",
    position: "relative",
    width:290
  };
  const fileFormats = ["psd", "jpg", "png", "webp", "tiff", "mp4", "webm"];
  const colourSpaces = ["sRGB", "Adobe 1998", "CMYK"];
  const fileSizeUnits = ["KB", "MB"];

  const fileUploadRef = useRef(null);

  const [title, setTitle] = useState(fs.title);
  const [width, setWidth] = useState(fs.width);
  const [height, setHeight] = useState(fs.height);
  const [dpi, setDpi] = useState(fs.dpi);
  const [fileFormat, setFileFormat] = useState(fs.fileFormat);
  const [removeITSBackground, setRemoveITSBackground] = useState(false);
  const [backgroundColour, setBackgroundColour] = useState(fs.backgroundColour);
  const [colourSpace, setColourSpace] = useState(fs.colourSpace);
  const [fileSize, setFileSize] = useState(fs.fileSize);
  const [fileSizeUnit, setFileSizeUnit] = useState(fs.fileSizeUnit);

  const [fileUploading, setFileUploading] = useState(false);

  useEffect(() => {
    setTitle(fs.title);
    setWidth(fs.width);
    setHeight(fs.height);
    setDpi(fs.dpi);
    setFileFormat(fs.fileFormat);
    setRemoveITSBackground(fs.removeITSBackground);
    setBackgroundColour(fs.backgroundColour);
    setColourSpace(fs.colourSpace);
    setFileSize(fs.fileSize);
    setFileSizeUnit(fs.fileSizeUnit);
  }, [fs]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    fs.title = e.target.value;
    updateFSOBJ(firebase, client.id, "fileSizes", client.fileSizes);
  };
  const handleWidthChange = (e) => {
    let val = parseInt(e.target.value);
    if (!val) {
      val = "";
    }
    setWidth(val);
    fs.width = val;
    updateFSOBJ(firebase, client.id, "fileSizes", client.fileSizes);
  };
  const handleHeightChange = (e) => {
    let val = parseInt(e.target.value);
    if (!val) {
      val = "";
    }
    setHeight(val);
    fs.height = val;
    updateFSOBJ(firebase, client.id, "fileSizes", client.fileSizes);
  };
  const handleDpiChange = (e) => {
    let val = parseInt(e.target.value);
    if (!val) {
      val = "";
    }
    setDpi(val);
    fs.dpi = val;
    updateFSOBJ(firebase, client.id, "fileSizes", client.fileSizes);
  };
  const handleFileFormatChange = (e, newValue) => {
    if (!newValue) {
      newValue = "";
    }
    setFileFormat(newValue);
    fs.fileFormat = newValue;
    updateFSOBJ(firebase, client.id, "fileSizes", client.fileSizes);
  };
  const handleRemoveITSBackgroundChange = (e) => {
    setRemoveITSBackground(e.target.checked);
    fs.removeITSBackground = e.target.checked;
    updateFSOBJ(firebase, client.id, "fileSizes", client.fileSizes);
  };
  const handleBackgroundColourChange = (e) => {
    setBackgroundColour(e.target.value);
    fs.backgroundColour = e.target.value;
    updateFSOBJ(firebase, client.id, "fileSizes", client.fileSizes);
  };
  const handleBackgroundColourChangeFileUpload = (url) => {
    setBackgroundColour(url);
    fs.backgroundColour = url;
    updateFSOBJ(firebase, client.id, "fileSizes", client.fileSizes);
  };
  const handleColourSpaceChange = (e, newValue) => {
    if (!newValue) {
      newValue = "";
    }
    setColourSpace(newValue);
    fs.colourSpace = newValue;
    updateFSOBJ(firebase, client.id, "fileSizes", client.fileSizes);
  };
  const handleFileSizeChange = (e) => {
    let val = parseInt(e.target.value);
    if (!val) {
      val = "";
    }
    setFileSize(val);
    fs.fileSize = val;
    updateFSOBJ(firebase, client.id, "fileSizes", client.fileSizes);
  };
  const handleFileSizeUnitChange = (e, newValue) => {
    if (!newValue) {
      newValue = "";
    }
    setFileSizeUnit(newValue);
    fs.fileSizeUnit = newValue;
    updateFSOBJ(firebase, client.id, "fileSizes", client.fileSizes);
  };
  const handleDeleteSupplySizeClick = () => {
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client.id)
      .update({ [`fileSizes.${kind.toLowerCase()}`]: arrayRemove(fs) });
  };

  return (
    <div style={cardStyle}>
      {showDeleteSupplySize ? (
        <div
          style={{
            background:
              theme === "dark"
                ? "rgba(255, 255, 255, 0.02)"
                : "rgba(0, 0, 0, 0.02)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 100,
            position: "absolute",
            borderRadius: "10px",
            top: "0px",
            bottom: "0px",
            left: "0px",
            right: "0px",
          }}
        >
          <IconButton onClick={handleDeleteSupplySizeClick}>
            <DeleteIcon
              style={{
                color: "#ff0033",
                width: 60,
                height: 60,
                filter: "drop-shadow(4px 4px 3px #00000060)",
              }}
            />
          </IconButton>
        </div>
      ) : null}
      <Tooltip title="Title" enterDelay={500}>
        <TextField
          variant="outlined"
          label="Title"
          size="small"
          value={title}
          onChange={handleTitleChange}
          inputProps={{ style: { fontSize: "0.8rem" } }}
          style={textFieldStyle}
        />
      </Tooltip>
      <div>
        <Tooltip title="Width" enterDelay={500}>
          <TextField
            variant="outlined"
            size="small"
            label="Width"
            value={width}
            onChange={handleWidthChange}
            style={textFieldStyleSml}
          />
        </Tooltip>
        <Tooltip title="Height" enterDelay={500}>
          <TextField
            variant="outlined"
            size="small"
            label="Height"
            value={height}
            onChange={handleHeightChange}
            style={textFieldStyleSml}
          />
        </Tooltip>
        <div style={{ display: "flex" }}>
          {kind === "Video" ? null : (
            <Tooltip title="Dpi" enterDelay={500}>
              <TextField
                variant="outlined"
                size="small"
                label="DPI"
                value={dpi}
                onChange={handleDpiChange}
                style={textFieldStyleSml}
              />
            </Tooltip>
          )}
          <Tooltip title="File format" enterDelay={500}>
            <Autocomplete
              value={fileFormat}
              onChange={handleFileFormatChange}
              inputValue={fileFormat}
              onInputChange={handleFileFormatChange}
              options={fileFormats}
              sx={{
                display: "flex",
                margin: "10px",
                maxWidth: "125px",
                minWidth: "125px",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  label="File Format"
                />
              )}
            />
          </Tooltip>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {kind === "Video" ? null : (
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 8,
                  marginBottom: 8,
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={removeITSBackground}
                        onChange={handleRemoveITSBackgroundChange}
                      />
                    }
                    label="Remove ITS Background"
                  />
                </FormGroup>
              </div>
              <Tooltip title="Background colour" enterDelay={500}>
                <TextField
                  variant="outlined"
                  size="small"
                  label="Background"
                  value={
                    typeof backgroundColour === "object"
                      ? backgroundColour.fileName
                      : backgroundColour
                  }
                  onChange={handleBackgroundColourChange}
                  style={textFieldStyle}
                  InputProps={{
                    endAdornment: (
                      <>
                        {!fileUploading ? (
                          <IconButton
                            onClick={async () => {
                              if (fileUploading) return;
                              fileUploadRef.current.value = null;
                              fileUploadRef.current.click();
                            }}
                          >
                            <FileUploadIcon />
                          </IconButton>
                        ) : (
                          <IconButton disabled>
                            <CircularProgress size={24} />
                          </IconButton>
                        )}
                        {typeof backgroundColour === "object" ? (
                          <IconButton
                            onClick={() => {
                              saveAs(
                                backgroundColour.url,
                                backgroundColour.fileName
                              );
                            }}
                          >
                            <FileDownloadIcon />
                          </IconButton>
                        ) : null}
                      </>
                    ),
                  }}
                />
              </Tooltip>
            </>
          )}
        </div>
        <div style={{ display: "flex" }}>
          {kind === "Video" ? null : (
            <>
              <Tooltip title="Colour space" enterDelay={500}>
                <Autocomplete
                  value={colourSpace}
                  onChange={handleColourSpaceChange}
                  inputValue={colourSpace}
                  onInputChange={handleColourSpaceChange}
                  options={colourSpaces}
                  sx={{
                    display: "flex",
                    margin: "10px",
                    maxWidth: 270,
                    minWidth: 270,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      label="Colour Space"
                    />
                  )}
                />
              </Tooltip>
            </>
          )}
        </div>
        <div style={{ display: "flex" }}>
          <Tooltip title="Compress size" enterDelay={500}>
            <TextField
              variant="outlined"
              size="small"
              label="Compress"
              value={fileSize}
              onChange={handleFileSizeChange}
              style={textFieldStyleSml}
            />
          </Tooltip>
          <Tooltip title="File size unit" enterDelay={500}>
            <Autocomplete
              value={fileSizeUnit}
              onChange={handleFileSizeUnitChange}
              inputValue={fileSizeUnit}
              onInputChange={handleFileSizeUnitChange}
              options={fileSizeUnits}
              sx={{
                display: "flex",
                margin: "10px",
                maxWidth: "125px",
                minWidth: "125px",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  label="File Size Unit"
                />
              )}
            />
          </Tooltip>
        </div>
      </div>
      <input
        ref={fileUploadRef}
        type="file"
        style={{ display: "none" }}
        accept="image/png, image/jpeg"
        onChange={async (e) => {
          setFileUploading(true);
          // await new Promise((r) => setTimeout(r, 1000));
          try {
            const base64 = await getBase64(e.target.files[0]);
            const blob = await fetch(base64).then((res) => res.blob());
            const storageRef = firebase
              .storage()
              .ref()
              .child(
                `/Client Background Plates/${client.brand}/${e.target.files[0].name}`
              );
            storageRef.put(blob).then(async (snapshot) => {
              const url = await storageRef.getDownloadURL();
              handleBackgroundColourChangeFileUpload({
                fileName: e.target.files[0].name,
                url: url,
              });
              setFileUploading(false);
            });
          } catch (e) {
            setFileUploading(false);
          }
        }}
      />
    </div>
  );
};

export default FileSizeContainer;

// const updateFSNow = debounce(async (firebase, id, field, value) => {
//     firebase.firestore().collection('ClientDatabase').doc(id).set({ [field]: value }, { merge: true });
// }, 0, true);
async function getBase64(file) {
  const promise = new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
  return await promise;
}

const updateFSOBJ = debounce(async (firebase, id, field, value) => {
  firebase
    .firestore()
    .collection("ClientDatabase")
    .doc(id)
    .set({ [field]: value }, { merge: true });
}, 600);

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
