import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";

// import { encode } from "base64-arraybuffer";
// const { Fido2Lib } = require("fido2-lib");

// const f2l = new Fido2Lib({
//   timeout: 600,
//   rpName: "https://staff.imagesthatsell.com.au",
//   rpIcon: "https://www.imagesthatsell.com.au/theme/ITS/img/logo.png",
//   challengeSize: 128,
//   attestation: "direct",
//   cryptoParams: [-7],
//   authenticatorAttachment: "platform",
//   authenticatorRequireResidentKey: false,
//   authenticatorUserVerification: "required",
// });

const StaffAvatar = ({
  staff,
  firebase,
  isDarkMode,
  auth,
  darkMode,
  lightMode,
  setLoadingVisible,
  autoLogin,
  ipAddress,
}) => {
  const avatarContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: 140,
    height: 165,
    cursor: "pointer",
    padding: "10px",
    margin: "5px",
    borderRadius: "10px",
    color: "white !important",
    backgroundColor: isDarkMode ? "#ffffff35" : "#00000015",
    "&:hover": {
      backgroundColor: isDarkMode ? "#ffffff25" : "#00000025",
    },
  };

  const avatarContainerStyleModal = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    margin: "5px",
    borderRadius: "10px",
    width: "120px",
    height: "170px",
    backgroundColor: isDarkMode ? "#ffffff35" : "#00000015",
  };

  const textColour = {
    color: isDarkMode ? "#e5e5e5" : darkMode,
  };

  const textFieldMulti = {
    '& [class*="MuiInputLabel-root"], & [class*="MuiOutlinedInput"], & [class*="Mui-focused"]': {
      color: isDarkMode ? "#e5e5e5 !important" : ``,
      borderColor: isDarkMode ? "#e5e5e5 !important" : ``,
    },
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [passwordless, setPasswordless] = useState(staff.passwordless);
  useEffect(() => {
    setPasswordless(staff.passwordless);
  }, [staff]);

  const [password, setPassword] = useState("");
  const [passwordLabelText, setPasswordLabelText] = useState("Password");
  const [passwordError, setPasswordError] = useState(false);
  // const [accountExists, setAccountExists] = useState(true);
  const [showLoginButton, setShowLoginButton] = useState(true);
  const [showForgotPasswordButton, setShowForgotPasswordButton] = useState(true);
  const [showPasswordResetSent, setShowPasswordResetSent] = useState(false);

  const handleClick = async () => {
    if (!staff.email) {
      return;
    }
    handleOpen();

    if (autoLogin) {
      setLoadingVisible(true);

      const getUtilityToken = firebase.functions().httpsCallable("getUtilityToken");

      const userToken = await getUtilityToken({
        uid: staff.uid,
      }).catch((e) => {
        setLoadingVisible(false);
      });

      if (userToken.data.error) {
        setPasswordless(false);
        setLoadingVisible(false);
        return;
      }
      await firebase.auth().signInWithCustomToken(userToken.data);
      setLoadingVisible(false);
      return;
    }

    // if (passwordless) {
    //   if (
    //     !(await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable())
    //   ) {
    //     setPasswordless(false);
    //     return;
    //   }
    //   const authnOptions = await f2l.assertionOptions();
    //   const buf = Buffer.from(authnOptions.challenge);
    //   const challenge = new Uint8Array(buf);
    //   const options = {
    //     publicKey: {
    //       challenge: challenge,
    //     },
    //   };
    //   const clientAssertionResponse = await navigator.credentials
    //     .get(options)
    //     .catch((e) => console.log(e));
    //   if (!clientAssertionResponse) {
    //     setPasswordless(false);
    //     return;
    //   }

    //   const clientAssertionResponseParsed = {
    //     authenticatorAttachment:
    //       clientAssertionResponse.authenticatorAttachment,
    //     id: clientAssertionResponse.id,
    //     rawId: encode(clientAssertionResponse.rawId),
    //     response: {
    //       authenticatorData: encode(
    //         clientAssertionResponse.response.authenticatorData
    //       ),
    //       clientDataJSON: encode(
    //         clientAssertionResponse.response.clientDataJSON
    //       ),
    //       signature: encode(clientAssertionResponse.response.signature),
    //       userHandle: encode(clientAssertionResponse.response.userHandle),
    //     },
    //     type: clientAssertionResponse.type,
    //   };
    //   setLoadingVisible(true);

    //   const getAuthToken = firebase.functions().httpsCallable("getAuthToken");

    //   const userToken = await getAuthToken({
    //     uid: staff.uid,
    //     clientAssertionResponse: clientAssertionResponseParsed,
    //     challenge: encode(authnOptions.challenge),
    //     origin: window.location.href.replace(/\/+$/, ""),
    //   }).catch((e) => {
    //     setLoadingVisible(false);
    //   });

    //   if (userToken.data.error) {
    //     setPasswordless(false);
    //     setLoadingVisible(false);
    //     return;
    //   }
    //   await firebase.auth().signInWithCustomToken(userToken.data);
    //   setLoadingVisible(false);
    // }

    // firebase
    //   .auth()
    //   .signInWithEmailAndPassword(staff.email, "password1234")
    //   .then((userCredential) => {})
    //   .catch((error) => {
    //     console.log(error.message);
    //     console.log(error.code);
    //     if (error.code === "auth/user-not-found") {
    //       setPasswordLabelText("Choose a Password");
    //       alert("No password set, please enter one");
    //       setAccountExists(false);
    //     }
    //   });
  };

  const handleLogin = () => {
    if (!password) {
      setPasswordError(true);
      return;
    }
    // if (password.toLowerCase().includes('its')) {
    // 	setPasswordError(true);
    // 	setPasswordLabelText('Choose a Stronger Password');
    // 	return;
    // }
    // if (password.length < 6) {
    // 	setPasswordError(true);
    // 	setPasswordLabelText('Choose a Stronger Password');
    // 	return;
    // }

    // setShowLoginButton(false);
    // setTimeout(() => {
    //   if (accountExists) {
    firebase
      .auth()
      .signInWithEmailAndPassword(staff.email, password)
      .then((userCredential) => {
        // userCredential.user.updateProfile({
        //   displayName: parseDisplayName(staff.firstName, staff.lastName),
        //   firstName: staff.firstName,
        //   lastName: staff.lastName,
        //   photoURL: staff.avatar,
        // });
        window.location.href = "/";
      })
      .catch((error) => {
        alert(error.message.replace("Firebase: ", ""));
        console.log(error.message);
        console.log(error.code);
        if (error.code === "auth/wrong-password") {
          setShowLoginButton(true);
          setPasswordError(true);
          setPasswordLabelText("Incorrect Password");
        }
      });
    //   } else {
    //     firebase
    //       .auth()
    //       .createUserWithEmailAndPassword(staff.email, password)
    //       .then((userCredential) => {
    //         userCredential.user.updateProfile({
    //           displayName: parseDisplayName(staff.firstName, staff.lastName),
    //           firstName: staff.firstName,
    //           lastName: staff.lastName,
    //           photoURL: staff.avatar,
    //         });
    //       })
    //       .catch((error) => {
    //         setShowLoginButton(true);
    //         console.log(error.message);
    //         console.log(error.code);
    //       });
    //   }
    // }, 10);
  };

  const modalBody = (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor: isDarkMode ? "rgba(0, 0, 0, 0.4)" : "rgba(0, 0, 0, 0.1)",
        }}
        onClick={handleClose}
      ></div>
      <div
        style={{
          position: "absolute",
          top: `50%`,
          left: `50%`,
          transform: `translate(-50%, -50%)`,
          zIndex: 100,
        }}
      >
        <div
          style={{
            width: 250,
            height: passwordless ? 250 : 370,
            borderRadius: "10px",
            backgroundColor: isDarkMode ? darkMode : lightMode,
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: passwordless ? "center" : "flex-start",
            alignItems: "center",
          }}
        >
          <div style={avatarContainerStyleModal}>
            <Avatar
              variant="circular"
              alt={`${staff.firstName} ${staff.lastName}`}
              src={staff.avatar}
              sx={{ width: 60, height: 60, fontSize: "2.5rem" }}
            />
            <span style={{ ...textColour, marginTop: "10px", fontSize: "0.8em" }}>
              {staff.preferredName ? staff.preferredName : staff.firstName}
            </span>
            <span style={{ ...textColour, fontSize: "0.8em" }}>{staff.lastName}</span>
          </div>
          <TextField
            style={{ display: "none" }}
            label="Username"
            autoFocus
            inputProps={{ readOnly: true }}
            type="email"
            value={staff.email}
          />
          {passwordless && (
            <RememberMe firebase={firebase} isDarkMode={isDarkMode} ipAddress={ipAddress} />
          )}
          {!passwordless && (
            <>
              <TextField
                style={{ marginTop: "20px", width: "200px" }}
                sx={textFieldMulti}
                label={passwordLabelText}
                autoFocus
                type="password"
                value={password || ""}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError(false);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
                error={passwordError}
              />
              <div style={{ position: "absolute", top: "212px", right: "15px" }}>
                <Tooltip
                  enterTouchDelay={10}
                  leaveTouchDelay={3000}
                  enterDelay={200}
                  leaveDelay={300}
                  title={
                    <div
                      style={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        margin: "10px",
                      }}
                    >
                      <span style={{ fontSize: "10px" }}>Password must not contain "its"</span>
                      <span style={{ fontSize: "10px", marginTop: "5px" }}>
                        Password must be longer than 6 characters
                      </span>
                    </div>
                  }
                >
                  <HelpIcon
                    style={{
                      color: isDarkMode ? "rgba(255, 255, 255, 0.6)" : "rgba(0, 0, 0, 0.2)",
                      width: "20px",
                    }}
                  />
                </Tooltip>
              </div>
              {showLoginButton && (
                <>
                  <Button
                    style={{ marginTop: "25px", width: "200px" }}
                    variant="outlined"
                    onClick={handleLogin}
                    sx={{
                      color: isDarkMode ? "#e5e5e5 !important" : ``,
                      borderColor: isDarkMode ? "#e5e5e5 !important" : ``,
                    }}
                  >
                    Login
                  </Button>
                  <RememberMe
                    firebase={firebase}
                    isDarkMode={isDarkMode}
                    ipAddress={ipAddress}
                  />
                  {showForgotPasswordButton && (
                    <Button
                      onClick={() => {
                        firebase
                          .auth()
                          .sendPasswordResetEmail(staff.email)
                          .then(() => {
                            setShowForgotPasswordButton(false);
                            setShowPasswordResetSent(true);
                          })
                          .catch((error) => {
                            console.log(error.code);
                            console.log(error.message);
                          });
                      }}
                      style={{
                        marginTop: "-5px",
                        fontSize: "13px",
                        textTransform: "none",
                      }}
                      size="small"
                    >
                      Forgot Password
                    </Button>
                  )}
                  {showPasswordResetSent && (
                    <p style={{ fontSize: "12px" }}>Password Reset Sent</p>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );

  return (
    <>
      <div style={avatarContainerStyle} onClick={handleClick}>
        <Avatar
          variant="circular"
          alt={`${staff.firstName} ${staff.lastName}`}
          src={staff.avatar}
          sx={{ width: 80, height: 80, fontSize: "2.5rem" }}
        />
        <span style={{ ...textColour, marginTop: "10px", fontSize: "0.9em" }}>
          {staff.preferredName ? staff.preferredName : staff.firstName}
        </span>
        <span style={{ ...textColour, fontSize: "0.9em" }}>{staff.lastName}</span>
      </div>
      <Modal hideBackdrop open={open} onClose={handleClose}>
        {modalBody}
      </Modal>
    </>
  );
};

export default StaffAvatar;

const RememberMe = ({
  firebase,
  isDarkMode,
  setLoginPersistance,
  ipAddress,
}) => {
  const [shouldDefaultChecked, setShouldDefaultChecked] = useState(false);
  useEffect(() => {
    if (ipAddress !== "61.69.92.2101") {
      setShouldDefaultChecked(true);
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    } else {
      setShouldDefaultChecked(false);
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
    }
  }, [ipAddress, setShouldDefaultChecked, firebase]);

  return (
    <FormGroup style={{ marginTop: 5 }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={shouldDefaultChecked}
            onChange={(e) => {
              e.target.checked
                ? firebase
                    .auth()
                    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
                : firebase
                    .auth()
                    .setPersistence(firebase.auth.Auth.Persistence.SESSION);
              console.log(firebase.auth.Auth.Persistence);
              setShouldDefaultChecked(e.target.checked);
            }}
            sx={{
              color: isDarkMode ? "#e5e5e5 !important" : ``,
              borderColor: isDarkMode ? "#e5e5e5 !important" : ``,
            }}
          />
        }
        label="Remember Me"
        sx={{
          color: isDarkMode ? "#e5e5e5 !important" : ``,
          borderColor: isDarkMode ? "#e5e5e5 !important" : ``,
        }}
      />
    </FormGroup>
  );
};

// function parseDisplayName(firstName, lastName) {
//   let name = firstName;
//   if (lastName) {
//     name = `${firstName} ${lastName}`;
//   }
//   return name;
// }
