import React, { useState, useEffect } from "react";

import Button from "@mui/material/Button";
import MDEditor, { commands } from "@uiw/react-md-editor";

const MarkdownNotesEditor = ({
  label,
  jobValue,
  setJobValue,
  saveJobValue,
  clientValue,
  isDarkMode,
  startShooting = false,
}) => {
  const [toggleEdit, settoggleEdit] = useState("preview");
  const [maxHeight, setMaxHeight] = useState(startShooting ? 180 : 250);

  useEffect(() => {
    const addTargetBlank = () => {
      const aTags = Array.from(document.getElementsByTagName("a"));
      aTags.forEach((a) => {
        a.target = "_blank";
      });
      //   console.log(aTags);
    };
    addTargetBlank();
    setInterval(addTargetBlank, 5000);
  }, []);

  return (
    <div
      style={{
        width: "90%",
        // maxWidth: 500,
        display: "flex",
        justifyContent: "center",
        cursor: "text",
        marginBottom: 20,
        minHeight: 80,
        maxHeight: maxHeight,
        overflow: "hidden",
      }}
      onClick={(e) => {
        if (startShooting) return;
        if (toggleEdit === "edit") return;
        if (toggleEdit === "preview") {
          settoggleEdit("edit");
          setMaxHeight(800);
        }
      }}
    >
      <fieldset
        data-color-mode={isDarkMode ? "dark" : "light"}
        style={{
          background: isDarkMode ? "#3A3A3A":"#E0E0E0",
          borderRadius: 4,
          width: "100%",
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 6,
          paddingBottom: 6,
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <legend style={{ float: "left" }}>
          <span
            style={{
              marginLeft: 4,
              marginRight: 4,
              fontWeight: 400,
              color: isDarkMode ? "rgb(192, 194, 195)" : "rgba(0, 0, 0, 0.6)",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontSize: startShooting ? "0.8rem" : "0.9rem",
              letterSpacing: "0.00938em",
            }}
          >
            {label}
          </span>
        </legend>

        <div
          style={{
            marginTop: 10,
            // width: "82vw",
            maxWidth: 500,
          }}
        >
          {toggleEdit === "edit" ? (  
            <>
              <MDEditor
                onFocus={(e) => {
                  e.target.selectionStart = e.target.value.length + 1;
                  e.target.selectionEnd = e.target.value.length + 1;
                }}
                value={jobValue}
                onChange={setJobValue}
                autoFocus={true}
                preview={toggleEdit}
                commands={[
                  commands.bold,
                  commands.italic,
                  commands.strikethrough,
                  commands.hr,
                  commands.title4,
                  commands.divider,
                  commands.link,
                  commands.quote,
                  commands.code,
                  commands.codeBlock,
                  commands.divider,
                  commands.unorderedListCommand,
                  commands.orderedListCommand,
                ]}
                extraCommands={[]}
                style={{
                  marginLeft: 15,
                }}
                height={200}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="outlined"
                  style={{ width: "80%", height: 24, margin: 5 }}
                  onClick={() => {
                    saveJobValue(jobValue);
                    settoggleEdit("preview");
                    setMaxHeight(200);
                  }}
                >
                  Save
                </Button>
              </div>
            </>
          ) : (
            <>
              {jobValue ? (
                <MDEditor.Markdown
                  source={jobValue}
                  style={{
                    padding: 4,
                    background: "transparent",
                    fontSize: startShooting ? 12 : 14,
                    marginBottom: 5,
                    marginTop: -8,
                  }}
                  height={200}
                />
              ) : null}
            </>
          )}
          {/* // {clientValue ? ( */}
          <div style={{ marginBottom: 5, marginTop: 0 }}>
            <MDEditor.Markdown
              source={clientValue}
              style={{
                padding: 4,
                background: "transparent",
                fontSize: startShooting ? 12 : 14,
                // marginBottom: 5,
              }}
              height={200}
            />
          </div>
          {/* // ) : null} */}
        </div>
      </fieldset>
    </div>
  );
};

export default MarkdownNotesEditor;
