import { useEffect, useRef } from "react";
import { Html5Qrcode } from "html5-qrcode";

import { performMonkeyPatches } from "./qrfix";
const qrcodeRegionId = "html5qr-code-full-region";

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props) => {
  let config = {};
  if (props.fps) {
    config.fps = props.fps;
  }
  if (props.qrbox) {
    config.qrbox = props.qrbox;
  }
  if (props.aspectRatio) {
    config.aspectRatio = props.aspectRatio;
  }
  if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip;
  }
  return config;
};

const QRScanner = (props) => {
  const html5QrCodeRef = useRef(null);

  useEffect(() => {
    const success = async (decodedText, decodedResult) => {
      try {
        const res = JSON.parse(decodedText);
        console.log(res);

        await props.firebase
          .firestore()
          .collection("RemoteLogin")
          .doc(res.id)
          .set({ scanned: true, userID: props.firebase.auth().currentUser.uid });

        try {
          html5QrCodeRef.current
            .stop()
            .then(() => {
              console.log("kill scanner");
              props.handleClose();
              html5QrCodeRef.current
                .clear()
                .then(() => {
                  props.handleClose();
                })
                .catch((error) => {
                  console.error("Failed to clear html5QrcodeScanner. ", error);
                });
            })
            .catch((err) => {});
        } catch (e) {
          console.log("kill error");
        }
      } catch (e) {
        console.log("success error?");
      }
    };

    performMonkeyPatches();
    const config = createConfig(props);
    html5QrCodeRef.current = new Html5Qrcode(qrcodeRegionId);
    html5QrCodeRef.current.start(
      { facingMode: "environment" },
      config,
      success,
      props.qrCodeErrorCallback
    );

    return () => {
      if (html5QrCodeRef.current) {
        try {
          html5QrCodeRef.current
            .stop()
            .then(() => {
              console.log("kill scanner");
              props.handleClose();
              html5QrCodeRef.current
                .clear()
                .then(() => {
                  props.handleClose();
                })
                .catch((error) => {
                  console.error("Failed to clear html5QrcodeScanner. ", error);
                });
            })
            .catch((err) => {});
        } catch (e) {
          props.handleClose();
          console.log(e);
        }
      }
    };
  }, [props]); // You can safely include props here

  return (
    <>
      <div id={qrcodeRegionId} style={{ width: "100%" }} />
    </>
  );
};

export default QRScanner;
