import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
// eslint-disable-next-line
import { RevoGrid, Template, BasePlugin } from "@revolist/react-datagrid";
import { createGlobalStyle } from "styled-components";
import {
  useMediaQuery,
  Tabs,
  Tab,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  FormGroup,
  Select,
  MenuItem,
  CircularProgress,
  TextField,
  IconButton,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import FileMenu from "./FileMenu";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CheckboxCell,
  ImageReferenceCell,
  WrapTextCell,
  FileNameCell,
  ShotCell,
  SetFolderCell,
} from "./CustomGridCells";
import RightClickMenu from "./RightClickMenu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useHistory } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { use100vh } from "react-div-100vh";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import { useAtom } from "jotai";
import { isMobileBrowserAtom } from "../-Atoms";
import { GithubPicker } from "react-color";

const columnDefaultsObjectLife = {
  category: { size: 160, hidden: true },
  capture: { size: 150, hidden: false },
  shot: { size: 90, hidden: false },
  fileName: { size: 300, hidden: false },
  imageReference: { size: 200, hidden: false },
  angles: { size: 200, hidden: true },
  shootType: { size: 200, hidden: true },
  videoRequired: { size: 170, hidden: false },
  stillsShot: { size: 170, hidden: false },
  videoShot: { size: 170, hidden: false },
  stylingNotes: { size: 280, hidden: false },
  styleCode: { size: 150, hidden: false },
  productName: { size: 180, hidden: false },
  colour: { size: 150, hidden: false },
  colourChange: { size: 180, hidden: false },
  comments: { size: 250, hidden: false },
};

const columnDefaultsObjectProduct = {
  category: { size: 160, hidden: false },
  capture: { size: 150, hidden: false },
  shot: { size: 90, hidden: false },
  fileName: { size: 300, hidden: false },
  imageReference: { size: 200, hidden: false },
  angles: { size: 200, hidden: false },
  shootType: { size: 200, hidden: false },
  videoRequired: { size: 170, hidden: true },
  stillsShot: { size: 170, hidden: false },
  videoShot: { size: 170, hidden: true },
  stylingNotes: { size: 280, hidden: false },
  styleCode: { size: 150, hidden: false },
  productName: { size: 180, hidden: false },
  colour: { size: 150, hidden: false },
  colourChange: { size: 180, hidden: false },
  comments: { size: 250, hidden: false },
};

const defaultCategories = [
  { name: "N/A", colour: "" },
  { name: "TOPS", colour: "#de9d9b" },
  { name: "JACKETS", colour: "#f1cda2" },
  { name: "DRESSES", colour: "#bcd6ac" },
  { name: "BOTTOMS", colour: "#b2a7d2" },
  { name: "ACCESSORIES", colour: "#a6c4e4" },
];

function CustomGrid({ firebase, user }) {
  const gridRef = useRef();
  const isStaff = user.staffData;
  const isElectron = isElectronFunc();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = useTheme();
  const height = use100vh();

  const [isMobileBrowser] = useAtom(isMobileBrowserAtom);

  const [zoomLevel, setZoomLevel] = useState(1);
  const [isLife, setIsLife] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isProduct, setIsProduct] = useState(false);

  const [isColumnDialogOpen, setIsColumnDialogOpen] = useState(false);

  useEffect(() => {
    window.receiveSwiftMessage = receiveSwiftMessage;
    window.sendMessageToSwift = sendMessageToSwift;
  }, []);

  function receiveSwiftMessage(message) {
    console.log("Message from Swift:", message);
  }
  function sendMessageToSwift(message) {
    console.log(message);
    try {
      window.webkit.messageHandlers.swiftHandler.postMessage(message);
    } catch (e) {
      console.log(e);
    }
    // console.log(window.webkit);
    // console.log(window.webkit.messageHandlers);
    // console.log(window.webkit.messageHandlers.jsMessageHandler);
    // if (
    //   window.webkit &&
    //   window.webkit.messageHandlers &&
    //   window.webkit.messageHandlers.jsMessageHandler
    // ) {
    //   window.webkit.messageHandlers.jsMessageHandler.postMessage(message);
    // }
  }

  useEffect(() => {
    const savedZoomLevel = localStorage.getItem("zoomLevel");
    if (savedZoomLevel) {
      let parsedZoomLevel = parseFloat(savedZoomLevel);
      if (!isNaN(parsedZoomLevel)) {
        parsedZoomLevel = Math.max(0.5, Math.min(parsedZoomLevel, 2));
        setZoomLevel(parsedZoomLevel);
      }
    }
  }, []);

  useEffect(() => {
    if (zoomLevel === 1) {
      localStorage.removeItem("zoomLevel");
    } else {
      localStorage.setItem("zoomLevel", zoomLevel.toString());
    }
  }, [zoomLevel]);

  const adjustedZoomLevel = useMemo(
    () => 1 + (zoomLevel - 1) * 0.6,
    [zoomLevel]
  );

  const GlobalStyle = createGlobalStyle`
    #menubar {
      zoom: ${zoomLevel};
    }

    .rgCell {
      font-size: ${Math.max(8, 12 * zoomLevel)}px;
      border: 1px solid rgba(0,0,0,0.08);
      padding: 0 !important;
    }

    .rgCell.disabled {
      background-color: rgba(0, 0, 0, 0.0) !important;
    }
    
    .videoShot, .blue {
      background-color: rgba(0, 0, 200, 0.15) !important;
    }

    .stillsShot, .green {
      background-color: rgba(0, 255, 0, 0.15) !important;
    }

    .bothShot, .teal {
      background-color: rgba(0, 255, 255, 0.1) !important;
    }

    .videoRequired, .purple {
      background-color: rgba(125, 65, 175, 0.1) !important;
    }

    .red {
      background-color: rgba(255, 0, 0, 0.2) !important;
    }

    .edit-input-wrapper > input{
      background-color:${prefersDarkMode ? "#2D3032" : ""};
    }

    @media (prefers-color-scheme: light) {
      .videoShot, .blue {
        background-color: rgba(0, 0, 200, 0.4) !important;
      }

      .stillsShot, .green {
        background-color: rgba(0, 200, 0, 0.4) !important;
      }

      .bothShot, .teal {
        background-color: rgba(0, 200, 200, 0.4) !important;
      }

      .videoRequired, .purple {
        background-color: rgba(125, 65, 175, 0.3) !important;
      }

      .red {
        background-color: rgba(210, 0, 0, 0.45) !important;
      }
    }

    @media print {
      @page {
        size: A4 landscape;
      }
      #container {
        display: none !important;
      }
      #tableContainer {
        zoom: 0.6 !important;
        display: block !important;
        color: black !important;
      }
    }
  `;

  const [contextMenu, setContextMenu] = useState(null);

  const history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);
  const clientID = queryParams.get("clientID");
  const jobID = queryParams.get("jobID");
  const styleGuide = queryParams.get("styleGuide");

  const [jobData, setJobData] = useState({});
  const [clientData, setClientData] = useState({});

  const [source, setSource] = useState([]);

  const [columnDefaults, setColumnDefaults] = useState(
    columnDefaultsObjectLife
  );

  const lifeButtons = {
    setFolder: true,
    makeFolder: false,
    makeFrontBack: false,
    makeFront: false,
    makeBack: false,
  };

  const productButtons = {
    setFolder: false,
    makeFolder: true,
    makeFrontBack: false,
    makeFront: false,
    makeBack: false,
  };

  const [visibleButtons, setVisibleButtons] = useState(lifeButtons);

  const blankRow = {
    fileName: "",
    imageReference: "",
    angles: "",
    shootType: "",
    videoRequired: false,
    videoShot: false,
    stillsShot: false,
    stylingNotes: "",
    styleCode: "",
    productName: "",
    colour: "",
    colourChange: "",
    comments: "",
    myRowClass: "",
    locked: false,
    category: "",
  };

  const [jobTabs, setJobTabs] = useState([]);

  const [categories, setCategories] = useState(defaultCategories);
  const [isCustomizeCategoriesDialogOpen, setIsCustomizeCategoriesDialogOpen] =
    useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [tableColumnsVisibility, setTableColumnsVisibility] = useState(() =>
    [
      { id: "shot", label: "Shot" },
      { id: "fileName", label: "File Name" },
      { id: "imageReference", label: "Image Reference" },
      { id: "videoRequired", label: "Video Required" },
      { id: "stillsShot", label: "Stills Shot" },
      { id: "videoShot", label: "Video Shot" },
      { id: "angles", label: "Angle/S" },
      { id: "shootType", label: "Shoot Type" },
      { id: "stylingNotes", label: "Styling Notes" },
      { id: "styleCode", label: "Style Code" },
      { id: "productName", label: "Product Name" },
      { id: "colour", label: "Colour" },
      { id: "colourChange", label: "Colour Change" },
      { id: "comments", label: "Comments" },
    ].reduce((acc, column) => {
      acc[column.id] = true;
      return acc;
    }, {})
  );

  useEffect(() => {
    let unsubscribe;
    if (clientID) {
      unsubscribe = firebase
        .firestore()
        .collection("ClientDatabase")
        .doc(clientID)
        .onSnapshot(async (doc) => {
          if (!doc.exists) return;
          const data = await doc.data();
          setClientData(data);
          if (data.shotListCategories) {
            setCategories(data.shotListCategories);
          } else {
            setCategories(defaultCategories);
          }
        });
    } else {
      setClientData({});
    }

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [clientID, firebase]);

  useEffect(() => {
    let unsubscribe;
    if (jobID) {
      unsubscribe = firebase
        .firestore()
        .collection("JobData")
        .doc(jobID)
        .onSnapshot(async (doc) => {
          if (!doc.exists) {
            return;
          }
          const data = await doc.data();
          data.id = doc.id;
          setJobData(data);
          if (data.shotList) {
            setSource(data.shotList);
          } else {
            setSource([
              ...Array.from({ length: 100 }, () => {
                return { id: uuidv4(), ...blankRow };
              }),
            ]);
          }

          const isLifeJob = data.jobTypeFull.includes("-L");
          const isVideoJob =
            data.jobTypeFull.includes("-V") || data.jobTypeFull.includes("-E");
          const isProductJob = data.jobTypeFull.includes("-P");

          setIsLife(isLifeJob);
          setIsVideo(isVideoJob);
          setIsProduct(isProductJob);

          if (data.shotListColumns) {
            setColumnDefaults(data.shotListColumns);
          } else {
            if (isLifeJob) {
              setColumnDefaults(columnDefaultsObjectLife);
            } else {
              setColumnDefaults(columnDefaultsObjectProduct);
            }
          }

          if (data.shotListVisibleButtons) {
            setVisibleButtons(data.shotListVisibleButtons);
          } else {
            if (isProductJob) {
              setVisibleButtons(productButtons);
            } else {
              setVisibleButtons(lifeButtons);
            }
          }
        });
    } else {
      setSource([]);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [jobID, firebase]);

  useEffect(() => {
    let unsubscribe;
    if (clientData?.brand) {
      unsubscribe = firebase
        .firestore()
        .collection("JobData")
        .where("client", "==", clientData.brand)
        .orderBy("bookedStart", "desc")
        .limit(20)
        .onSnapshot((snapshot) => {
          const jobs = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            jobs.push(data);
          });

          const filteredJobs = jobs.filter((job) => {
            const jobTypeFull = job.jobTypeFull || "";
            const confirmationStatus = job.confirmationStatus || "";
            const shotListOverride = job.shotListOverride || false;
            const bookedStart = job.bookedStart
              ? job.bookedStart.toDate
                ? job.bookedStart.toDate()
                : new Date(job.bookedStart)
              : null;
            const eightDaysAgo = new Date();
            // eightDaysAgo.setDate(eightDaysAgo.getDate() - 8);
            eightDaysAgo.setDate(eightDaysAgo.getDate() - 15);

            return (
              bookedStart &&
              bookedStart >= eightDaysAgo &&
              (shotListOverride === true ||
                (!jobTypeFull.includes("-E") &&
                  !jobTypeFull.includes("-R") &&
                  !jobTypeFull.includes("-M") &&
                  !jobTypeFull.includes("-D") &&
                  (confirmationStatus === "confirmed" ||
                    confirmationStatus === "pending")))
            );
          });

          const jobsByDate = {};
          filteredJobs.forEach((job) => {
            const bookedStart = job.bookedStart;
            if (!bookedStart) return;
            const date = bookedStart.toDate
              ? bookedStart.toDate()
              : new Date(bookedStart);
            const dateStr = date.toISOString().split("T")[0];
            if (!jobsByDate[dateStr]) {
              jobsByDate[dateStr] = [];
            }
            jobsByDate[dateStr].push(job);
          });

          const finalJobs = [];
          for (const dateStr in jobsByDate) {
            const jobsOnDate = jobsByDate[dateStr];
            const hasLJob = jobsOnDate.some(
              (job) => job.jobTypeFull && job.jobTypeFull.includes("-L")
            );
            if (hasLJob) {
              const filteredJobsOnDate = jobsOnDate.filter(
                (job) => !(job.jobTypeFull && job.jobTypeFull.includes("-V"))
              );
              finalJobs.push(...filteredJobsOnDate);
            } else {
              finalJobs.push(...jobsOnDate);
            }
          }

          finalJobs.sort((a, b) => {
            const dateA = a.bookedStart
              ? a.bookedStart.toDate
                ? a.bookedStart.toDate()
                : new Date(a.bookedStart)
              : new Date(0);
            const dateB = b.bookedStart
              ? b.bookedStart.toDate
                ? b.bookedStart.toDate()
                : new Date(b.bookedStart)
              : new Date(0);
            return dateB - dateA;
          });

          setJobTabs(finalJobs);
        });
      return () => {
        if (unsubscribe) unsubscribe();
      };
    } else {
      setJobTabs([]);
    }
  }, [clientData, firebase]);

  const [selectedTab, setSelectedTab] = useState(() => {
    if (styleGuide === "true") {
      return 0;
    } else if (jobID) {
      const jobIndex = jobTabs.findIndex((job) => job.id === jobID);
      return jobIndex >= 0 ? jobIndex + 1 : 0;
    }
    return 0;
  });

  useEffect(() => {
    if (jobTabs.length > 0 && jobID) {
      const jobIndex = jobTabs.findIndex((job) => job.id === jobID);
      const newTabIndex = jobIndex >= 0 ? jobIndex + 1 : 0;
      if (newTabIndex !== selectedTab) {
        setSelectedTab(newTabIndex);
      }
    } else if (styleGuide === "true" && selectedTab !== 0) {
      setSelectedTab(0);
    }
  }, [jobTabs, jobID, styleGuide, selectedTab]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    const queryParams = new URLSearchParams(window.location.search);

    const clientIDParam = queryParams.get("clientID");

    if (newValue === 0) {
      queryParams.delete("jobID");
      queryParams.set("styleGuide", "true");
    } else {
      const selectedJob = jobTabs[newValue - 1];
      queryParams.delete("styleGuide");
      queryParams.set("jobID", selectedJob.id);
    }

    if (clientIDParam) {
      queryParams.set("clientID", clientIDParam);
    }

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    if (newUrl !== `${window.location.pathname}?${window.location.search}`) {
      history.replace(newUrl);
    }
  };

  const updateShotList = useCallback(
    (updatedSource) => {
      firebase
        .firestore()
        .collection("JobData")
        .doc(jobID)
        .update({ shotList: updatedSource });
    },
    [firebase, jobID]
  );

  const getRowClass = useCallback(
    (videoShot, stillsShot, videoRequired, category) => {
      if (videoShot && stillsShot) return "bothShot";
      if (videoShot) return "videoShot";
      if (stillsShot) return "stillsShot";
      if (videoRequired) return "videoRequired";
      return "";
    },
    []
  );

  const illegalChars = ["'", "/", ":", '"', "", ";", ","];
  const escapeRegExp = (char) => char.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const escapedIllegalChars = illegalChars.map((char) => escapeRegExp(char));
  const regexPattern = `[${escapedIllegalChars.join("")}]`;
  const illegalCharsRegex = new RegExp(regexPattern, "g");
  const newLineRegex = /[\r\n]/g;

  const sanitizeValue = useCallback((value) => {
    if (typeof value === "string") {
      const sanitized = value
        .replace(illegalCharsRegex, "")
        .replace(newLineRegex, "");
      return sanitized;
    }
    return value;
  }, []);

  const handleCheckboxChange = useCallback(
    (id, prop, checked) => {
      setSource((prevSource) => {
        const updatedSource = prevSource.map((row) => {
          if (row.id === id) {
            const updatedRow = { ...row, [prop]: checked };
            const newRowClass = getRowClass(
              updatedRow.videoShot,
              updatedRow.stillsShot,
              updatedRow.videoRequired,
              updatedRow.category
            );
            if (newRowClass !== row.myRowClass) {
              updatedRow.myRowClass = newRowClass;
            }
            return updatedRow;
          }
          return row;
        });
        updateShotList(updatedSource);
        return updatedSource;
      });
    },
    [getRowClass, updateShotList]
  );

  const handleImageUpload = useCallback(
    (id, url) => {
      setSource((prevSource) => {
        const updatedSource = prevSource.map((row) => {
          if (row.id === id) {
            const updatedRow = { ...row, imageReference: url };
            const newRowClass = getRowClass(
              updatedRow.videoShot,
              updatedRow.stillsShot,
              updatedRow.videoRequired,
              updatedRow.category
            );
            if (newRowClass !== row.myRowClass) {
              updatedRow.myRowClass = newRowClass;
            }
            return updatedRow;
          }
          return row;
        });
        updateShotList(updatedSource);
        return updatedSource;
      });
    },
    [getRowClass, updateShotList]
  );

  useEffect(() => {
    return () => {
      if (Array.isArray(source)) {
        source.forEach((row) => {
          if (row.imageReference && row.imageReference.startsWith("blob:")) {
            URL.revokeObjectURL(row.imageReference);
          }
        });
      }
    };
  }, [source]);

  const handleRightClick = (event, model, kind = "cell") => {
    event.preventDefault();
    event.stopPropagation();

    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
            rowId: model.id,
            isLocked: model.locked,
            kind: kind,
            model: model,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleGetSelectedCells = useCallback(async () => {
    if (
      gridRef.current &&
      typeof gridRef.current.getSelectedRange === "function"
    ) {
      try {
        const selectedRange = await gridRef.current.getSelectedRange();
        let selectedRows = [];

        if (selectedRange) {
          const ranges = Array.isArray(selectedRange)
            ? selectedRange
            : [selectedRange];

          ranges.forEach((range) => {
            const { y, y1 } = range;
            for (let rowIndex = y; rowIndex <= y1; rowIndex++) {
              const row = source[rowIndex];
              if (row && !selectedRows.some((r) => r.id === row.id)) {
                selectedRows.push(row);
              }
            }
          });
        }

        return selectedRows;
      } catch {
        return [];
      }
    } else {
      return [];
    }
  }, [source]);

  const handleLockRow = useCallback(async () => {
    const selectedRows = await handleGetSelectedCells();

    if (selectedRows.length > 0) {
      setSource((prevSource) => {
        const updatedSource = prevSource.map((row) => {
          if (selectedRows.some((selectedRow) => selectedRow.id === row.id)) {
            return { ...row, locked: true };
          }
          return row;
        });
        updateShotList(updatedSource);
        return updatedSource;
      });
    } else {
      if (gridRef.current && typeof gridRef.current.getFocused === "function") {
        try {
          const focusedCell = await gridRef.current.getFocused();
          if (focusedCell) {
            const { y } = focusedCell;
            const focusedRow = source[y];
            if (focusedRow) {
              setSource((prevSource) => {
                const updatedSource = prevSource.map((row) => {
                  if (row.id === focusedRow.id) {
                    return { ...row, locked: true };
                  }
                  return row;
                });
                updateShotList(updatedSource);
                return updatedSource;
              });
            }
          }
        } catch {}
      }
    }

    handleClose();
  }, [handleGetSelectedCells, updateShotList, source]);

  const handleUnlockRow = useCallback(async () => {
    const selectedRows = await handleGetSelectedCells();

    if (selectedRows.length > 0) {
      setSource((prevSource) => {
        const updatedSource = prevSource.map((row) => {
          if (selectedRows.some((selectedRow) => selectedRow.id === row.id)) {
            return { ...row, locked: false };
          }
          return row;
        });
        updateShotList(updatedSource);
        return updatedSource;
      });
    } else {
      if (gridRef.current && typeof gridRef.current.getFocused === "function") {
        try {
          const focusedCell = await gridRef.current.getFocused();
          if (focusedCell) {
            const { y } = focusedCell;
            const focusedRow = source[y];
            if (focusedRow) {
              setSource((prevSource) => {
                const updatedSource = prevSource.map((row) => {
                  if (row.id === focusedRow.id) {
                    return { ...row, locked: false };
                  }
                  return row;
                });
                updateShotList(updatedSource);
                return updatedSource;
              });
            }
          }
        } catch {}
      }
    }

    handleClose();
  }, [handleGetSelectedCells, updateShotList, source]);

  const parseShotNumber = (index) => {
    const shotNumber = index + 1;
    return shotNumber < 10 ? `0${shotNumber}` : `${shotNumber}`;
  };

  const handleCreateFolders = useCallback(() => {
    console.log("handleCreateFolders");

    const validRows = source
      .map((row, index) => {
        if (row.fileName) {
          const shotFolderName = `S${parseShotNumber(index)}-${row.fileName}`;
          return { shotFolderName, rowId: row.id };
        }
        return null;
      })
      .filter(Boolean);

    const args = {
      shouldPrompt: false,
      existingHandler: "useExisting",
      browseHandler: "create",
    };

    const batchSize = 15;
    for (let i = 0; i < validRows.length; i += batchSize) {
      const batch = validRows.slice(i, i + batchSize);
      if (batch.length > 0) {
        const folderNames = batch.map((item) => item.shotFolderName);
        folderNames.unshift("-Test");
        sendMessageToSwift(
          `createFoldersBatch|createFolderWithShotNumber|${JSON.stringify(
            args
          )}|${JSON.stringify(folderNames)}`
        );
        // window.ipcRenderer?.send(
        //   "eval",
        //   `createFoldersBatch("createFolderWithShotNumber", ${JSON.stringify(
        //     args
        //   )}, ${JSON.stringify(folderNames)})`
        // );
      }
    }

    const lockedRowIds = validRows.map((item) => item.rowId);
    setSource((prevSource) => {
      const updatedSource = prevSource.map((row) => {
        if (lockedRowIds.includes(row.id)) {
          return { ...row, locked: true };
        }
        return row;
      });
      updateShotList(updatedSource);
      return updatedSource;
    });
  }, [source, isElectron, parseShotNumber, updateShotList]);

  const ColumnHeaderTemplate = useCallback(
    (model) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: `${Math.max(8, 12 * zoomLevel)}px`,
          }}
          onContextMenu={(event) => {
            handleRightClick(event, model, "column");
          }}
        >
          <span style={{ textTransform: "uppercase" }}>{model.name}</span>
          <ArrowDropDownIcon
            sx={{
              cursor: "pointer",
              fontSize: `${Math.max(15, 20 * zoomLevel)}px`,
            }}
            onClick={(event) => {
              handleRightClick(event, model, "column");
            }}
          />
        </div>
      );
    },
    [handleRightClick, zoomLevel]
  );

  const calculateRowSize = () => {
    const baseSize = (() => {
      const visibleCount = Object.values(visibleButtons).filter(Boolean).length;

      if (visibleCount <= 1) return 140;
      if (visibleCount === 2) return 150;
      if (visibleCount === 3) return 160;
      if (visibleCount === 4) return 180;
      if (visibleCount >= 5) return 210;

      return 140;
    })();

    return baseSize * zoomLevel;
  };
  const parseCategoryBackground = (category) => {
    const categoryObj = categories.find((cat) => cat.name === category);
    if (!categoryObj) return "";
    return prefersDarkMode
      ? adjustHexColor(categoryObj.colour, 40, 60)
      : categoryObj.colour;
  };
  const categoryColumn = useMemo(() => {
    if (!isProduct) return null;
    return {
      name: "CATEGORY",
      prop: "category",
      columnType: "text",
      size: 200 * adjustedZoomLevel,
      editable: true,
      cellTemplate: Template(({ model }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            background: parseCategoryBackground(model.category),
          }}
        >
          <Select
            value={model.category || "N/A"}
            onChange={(e) =>
              handleCheckboxChange(model.id, "category", e.target.value)
            }
            variant="filled"
            disableUnderline
            sx={{
              width: "100%",
              height: "100%",
              color: prefersDarkMode ? "#C8C8C8" : "#212121",
              "& .MuiSelect-icon": {
                color: prefersDarkMode ? "#C8C8C8" : "#212121",
              },
              "& .MuiFilledInput-input": {
                paddingTop: "25px",
                paddingBottom: "20px",
                paddingLeft: "15px",
                paddingRight: "32px",
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: prefersDarkMode ? "#2D3032" : "#FFFFFF",
                  color: prefersDarkMode ? "#C8C8C8" : "#212121",
                },
              },
            }}
          >
            {categories.map((cat) => (
              <MenuItem key={cat.name} value={cat.name}>
                {cat.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      )),
      columnTemplate: Template(ColumnHeaderTemplate),
      pin: "colPinStart",
    };
  }, [
    isProduct,
    ColumnHeaderTemplate,
    adjustedZoomLevel,
    handleCheckboxChange,
    prefersDarkMode,
    categories,
    parseCategoryBackground,
  ]);

  const captureColumn = useMemo(() => {
    return {
      name: "CAPTURE",
      prop: "capture",
      sortable: false,
      cellTemplate: Template(({ model }) => {
        const index = source.findIndex((row) => row.id === model.id);
        return (
          <SetFolderCell
            fileName={model.fileName}
            model={model}
            index={index}
            handleCheckboxChange={handleCheckboxChange}
            handleRightClick={handleRightClick}
            visibleButtons={visibleButtons}
            zoomLevel={zoomLevel}
            sendMessageToSwift={sendMessageToSwift}
          />
        );
      }),
      columnTemplate: Template(ColumnHeaderTemplate),
      size: columnDefaults["capture"]?.size || 180 * adjustedZoomLevel,
      readonly: true,
      pin: "colPinStart",
    };
  }, [
    ColumnHeaderTemplate,
    columnDefaults,
    adjustedZoomLevel,
    handleCheckboxChange,
    handleRightClick,
    visibleButtons,
    zoomLevel,
    source,
  ]);

  const remainingColumns = useMemo(() => {
    const columns = [
      {
        name: "SHOT",
        prop: "shot",
        sortable: false,
        size: columnDefaults["shot"]?.size || 180 * adjustedZoomLevel,
        cellTemplate: Template(({ model }) => (
          <ShotCell
            index={source.findIndex((row) => row.id === model.id)}
            model={model}
            handleRightClick={handleRightClick}
          />
        )),
        columnTemplate: Template(ColumnHeaderTemplate),
        editable: false,
        readonly: true,
        pin: "colPinStart",
      },
      {
        name: "FILE NAME",
        prop: "fileName",
        editable: true,
        size: columnDefaults["fileName"]?.size || 180 * adjustedZoomLevel,
        cellTemplate: Template(({ model }) => (
          <FileNameCell
            text={model.fileName}
            model={model}
            handleRightClick={handleRightClick}
            zoomLevel={zoomLevel}
          />
        )),
        columnTemplate: Template(ColumnHeaderTemplate),
        autoSize: true,
        pin: "colPinStart",
        readonly: (params) => {
          return params.model.locked === true;
        },
      },
      {
        name: "IMAGE REFERENCE",
        prop: "imageReference",
        editable: true,
        size: columnDefaults["imageReference"]?.size || 180 * adjustedZoomLevel,
        cellTemplate: Template(({ model }) => (
          <ImageReferenceCell
            id={model.id}
            imageReference={model.imageReference}
            onImageUpload={(url) => handleImageUpload(model.id, url)}
            firebase={firebase}
            jobID={jobID}
            model={model}
            handleRightClick={handleRightClick}
          />
        )),
        columnTemplate: Template(ColumnHeaderTemplate),
      },
      {
        name: "VIDEO REQUIRED",
        prop: "videoRequired",
        columnType: "checkbox",
        readonly: true,
        cellTemplate: Template(({ model }) => (
          <CheckboxCell
            checked={model.videoRequired}
            onChange={(e) =>
              handleCheckboxChange(model.id, "videoRequired", e.target.checked)
            }
            model={model}
            handleRightClick={handleRightClick}
          />
        )),
        columnTemplate: Template(ColumnHeaderTemplate),
        size: columnDefaults["videoRequired"]?.size || 180 * adjustedZoomLevel,
      },
      {
        name: "STILLS SHOT",
        prop: "stillsShot",
        columnType: "checkbox",
        readonly: true,
        cellTemplate: Template(({ model }) => (
          <CheckboxCell
            checked={model.stillsShot}
            onChange={(e) =>
              handleCheckboxChange(model.id, "stillsShot", e.target.checked)
            }
            model={model}
            handleRightClick={handleRightClick}
          />
        )),
        columnTemplate: Template(ColumnHeaderTemplate),
        size: columnDefaults["stillsShot"]?.size || 180 * adjustedZoomLevel,
      },
      {
        name: "VIDEO SHOT",
        prop: "videoShot",
        columnType: "checkbox",
        readonly: true,
        cellTemplate: Template(({ model }) => (
          <CheckboxCell
            checked={model.videoShot}
            onChange={(e) =>
              handleCheckboxChange(model.id, "videoShot", e.target.checked)
            }
            model={model}
            handleRightClick={handleRightClick}
          />
        )),
        columnTemplate: Template(ColumnHeaderTemplate),
        size: columnDefaults["videoShot"]?.size || 180 * adjustedZoomLevel,
      },
      {
        name: "ANGLE/S",
        prop: "angles",
        editable: true,
        size: columnDefaults["angles"]?.size || 180 * adjustedZoomLevel,
        cellTemplate: Template(({ model }) => (
          <WrapTextCell
            text={model.angles}
            model={model}
            handleRightClick={handleRightClick}
          />
        )),
        columnTemplate: Template(ColumnHeaderTemplate),
        autoSize: true,
      },
      {
        name: "SHOOT TYPE",
        prop: "shootType",
        editable: true,
        size: columnDefaults["shootType"]?.size || 180 * adjustedZoomLevel,
        cellTemplate: Template(({ model }) => (
          <WrapTextCell
            text={model.shootType}
            model={model}
            handleRightClick={handleRightClick}
          />
        )),
        columnTemplate: Template(ColumnHeaderTemplate),
        autoSize: true,
      },
      {
        name: "STYLING NOTES",
        prop: "stylingNotes",
        editable: true,
        size: columnDefaults["stylingNotes"]?.size || 180 * adjustedZoomLevel,
        cellTemplate: Template(({ model }) => (
          <WrapTextCell
            text={model.stylingNotes}
            model={model}
            handleRightClick={handleRightClick}
          />
        )),
        columnTemplate: Template(ColumnHeaderTemplate),
        autoSize: true,
      },
      {
        name: "STYLE CODE",
        prop: "styleCode",
        editable: true,
        size: columnDefaults["styleCode"]?.size || 180 * adjustedZoomLevel,
        cellTemplate: Template(({ model }) => (
          <WrapTextCell
            text={model.styleCode}
            model={model}
            handleRightClick={handleRightClick}
          />
        )),
        columnTemplate: Template(ColumnHeaderTemplate),
        autoSize: true,
      },
      {
        name: "PRODUCT NAME",
        prop: "productName",
        editable: true,
        size: columnDefaults["productName"]?.size || 180 * adjustedZoomLevel,
        cellTemplate: Template(({ model }) => (
          <WrapTextCell
            text={model.productName}
            model={model}
            handleRightClick={handleRightClick}
          />
        )),
        columnTemplate: Template(ColumnHeaderTemplate),
        autoSize: true,
      },
      {
        name: "COLOUR",
        prop: "colour",
        editable: true,
        size: columnDefaults["colour"]?.size || 180 * adjustedZoomLevel,
        cellTemplate: Template(({ model }) => (
          <WrapTextCell
            text={model.colour}
            model={model}
            handleRightClick={handleRightClick}
          />
        )),
        columnTemplate: Template(ColumnHeaderTemplate),
        autoSize: true,
      },
      {
        name: "COLOUR CHANGE",
        prop: "colourChange",
        editable: true,
        size: columnDefaults["colourChange"]?.size || 180 * adjustedZoomLevel,
        cellTemplate: Template(({ model }) => (
          <WrapTextCell
            text={model.colourChange}
            model={model}
            handleRightClick={handleRightClick}
          />
        )),
        columnTemplate: Template(ColumnHeaderTemplate),
        autoSize: true,
      },
      {
        name: "COMMENTS",
        prop: "comments",
        editable: true,
        size: columnDefaults["comments"]?.size || 180 * adjustedZoomLevel,
        cellTemplate: Template(({ model }) => (
          <WrapTextCell
            text={model.comments}
            model={model}
            handleRightClick={handleRightClick}
          />
        )),
        columnTemplate: Template(ColumnHeaderTemplate),
        autoSize: true,
      },
    ];

    return columns.filter((col) => !columnDefaults[col.prop]?.hidden);
  }, [
    ColumnHeaderTemplate,
    columnDefaults,
    handleCheckboxChange,
    handleRightClick,
    adjustedZoomLevel,
    isProduct,
    categories,
  ]);

  const addRows = useCallback(
    (count = 100) => {
      const newRows = Array.from({ length: count }, () => {
        return { id: uuidv4(), ...blankRow };
      });
      setSource((prevSource) => {
        const newSource = [...prevSource, ...newRows];
        updateShotList(newSource);
        return newSource;
      });
    },
    [updateShotList]
  );

  const defaultRow = {
    fileName: "",
    imageReference: "",
    videoRequired: false,
    videoShot: false,
    stillsShot: false,
    stylingNotes: "",
    styleCode: "",
    productName: "",
    colour: "",
    colourChange: "",
    comments: "",
    locked: false,
    category: "",
  };

  const clearEmptyRows = () => {
    setSource((prevSource) => {
      const filteredSource = prevSource.filter((row) => {
        return Object.keys(defaultRow).some(
          (key) => row[key] !== defaultRow[key]
        );
      });
      updateShotList(filteredSource);
      return filteredSource;
    });
  };

  const handleAfterEdit = useCallback(() => {
    const sanitizedSource = source.map((row) => {
      const sanitizedFileName = sanitizeValue(row.fileName);
      if (sanitizedFileName !== row.fileName) {
        return {
          ...row,
          fileName: sanitizedFileName,
        };
      }
      return row;
    });

    setSource(sanitizedSource);
    updateShotList(sanitizedSource);
  }, [source, sanitizeValue, updateShotList]);

  const countRowsWithFileName = useCallback(() => {
    return source.filter((row) => row.fileName).length;
  }, [source]);

  const countRowsWithStillsShot = useCallback(() => {
    return source.filter((row) => row.stillsShot).length;
  }, [source]);

  const handleAfterColumnResize = useCallback(
    async (event) => {
      for (const property in event.detail) {
        const { prop, size } = event.detail[property];
        const adjustedSize = size / adjustedZoomLevel;
        columnDefaults[prop].size = adjustedSize;
      }
      setColumnDefaults({ ...columnDefaults });
      firebase.firestore().collection("JobData").doc(jobID).update({
        shotListColumns: columnDefaults,
      });
    },
    [firebase, jobID, columnDefaults, adjustedZoomLevel]
  );

  const handleShowColumn = useCallback(
    async (column) => {
      handleClose();
      columnDefaults[column].hidden = false;
      setColumnDefaults({ ...columnDefaults });
      firebase.firestore().collection("JobData").doc(jobID).update({
        shotListColumns: columnDefaults,
      });
    },
    [firebase, jobID, columnDefaults]
  );

  const CustomTabs = styled(Tabs)(({ theme, indicatorcolor }) => ({
    "& .MuiTabs-indicator": {
      backgroundColor: indicatorcolor || theme.palette.primary.main,
    },
  }));

  const CustomTab = styled(Tab)(
    ({ theme, tabbackgroundcolor, tabselectedbackgroundcolor }) => ({
      backgroundColor: tabbackgroundcolor,
      textTransform: "none",
      "&.Mui-selected": {
        backgroundColor: tabselectedbackgroundcolor,
        color: "inherit",
      },
    })
  );

  const computeIndicatorColor = (selectedTab) => {
    if (selectedTab === 0) {
      return theme.palette.primary.main;
    } else {
      const selectedJob = jobTabs[selectedTab - 1];
      if (selectedJob && selectedJob.bookedStart) {
        const bookedStartDate = new Date(selectedJob.bookedStart);
        const today = new Date();
        const timeDiff = bookedStartDate.getTime() - today.getTime();
        const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (dayDiff < -2) {
          return "rgba(255, 0, 0, 0.5)";
        } else if (dayDiff > 14) {
          return "rgba(0, 255, 0, 0.5)";
        } else {
          return theme.palette.primary.main;
        }
      } else {
        return theme.palette.primary.main;
      }
    }
  };

  const indicatorColor = computeIndicatorColor(selectedTab);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const jsonData = JSON.parse(e.target.result);
      console.log(jsonData);
      const rows = await Promise.all(
        jsonData.map(async (item) => {
          const fileName = item.file_name;
          const imageBase64 = item.image_base64;

          console.log(fileName, imageBase64);

          const fileRef = firebase
            .storage()
            .ref()
            .child(`SheetData/${jobID}/shotlist_images/${fileName}`);
          const uploadTaskSnapshot = await fileRef.putString(
            imageBase64,
            "data_url"
          );
          const imageReference = await uploadTaskSnapshot.ref.getDownloadURL();
          console.log(imageReference);

          return {
            id: uuidv4(),
            fileName,
            imageReference,
            videoRequired: false,
            videoShot: false,
            stillsShot: false,
            stylingNotes: "",
            styleCode: "",
            productName: "",
            colour: "",
            colourChange: "",
            comments: "",
            myRowClass: "",
            locked: false,
            category: "",
          };
        })
      );

      setSource((prevSource) => {
        const newSource = [...prevSource, ...rows];
        updateShotList(newSource);
        return newSource;
      });
    };
    reader.readAsText(file);
  };

  const tableColumns = useMemo(
    () => [
      { id: "shot", label: "Shot" },
      { id: "fileName", label: "File Name" },
      { id: "imageReference", label: "Image Reference" },
      { id: "videoRequired", label: "Video Required" },
      { id: "stylingNotes", label: "Styling Notes" },
      { id: "styleCode", label: "Style Code" },
      { id: "productName", label: "Product Name" },
      { id: "colour", label: "Colour" },
      { id: "colourChange", label: "Colour Change" },
      { id: "comments", label: "Comments" },
    ],
    []
  );

  const renderDynamicTable = () => {
    const filteredSource = source.filter((row) => {
      return Object.keys(defaultRow).some(
        (key) => row[key] !== defaultRow[key]
      );
    });
    if (filteredSource.length === 0) {
      return <div>No data available to display.</div>;
    }

    const visibleTableColumns = tableColumns.filter(
      (col) => tableColumnsVisibility[col.id]
    );

    return (
      <TableContainer component={Paper} style={{ maxWidth: "100%" }}>
        <Table
          sx={{
            minWidth: 650,
            backgroundColor: "white",
            color: "black",
          }}
        >
          <TableHead>
            <TableRow>
              {visibleTableColumns.map((column) => {
                return (
                  <TableCell
                    key={column.id}
                    align={column.id === "shot" ? "center" : "left"}
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      color: "black",
                      border: "1px solid #262626",
                    }}
                  >
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSource.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{
                  position: "relative",
                  height: "100px",
                  "& > *": {
                    verticalAlign: "middle",
                  },
                }}
              >
                {visibleTableColumns.map((column) => {
                  let cellContent;
                  switch (column.id) {
                    case "shot":
                      cellContent = index + 1;
                      break;
                    case "fileName":
                      cellContent = row.fileName;
                      break;
                    case "imageReference":
                      cellContent = row.imageReference ? (
                        <img
                          src={row.imageReference}
                          alt={`Shot ${index + 1}`}
                          style={{
                            width: "auto",
                            height: "100%",
                          }}
                        />
                      ) : (
                        ""
                      );
                      break;
                    case "videoRequired":
                      cellContent = row.videoRequired ? "Yes" : "No";
                      break;
                    case "stillsShot":
                      cellContent = row.stillsShot ? "Yes" : "No";
                      break;
                    case "videoShot":
                      cellContent = row.videoShot ? "Yes" : "No";
                      break;
                    case "stylingNotes":
                      cellContent = row.stylingNotes;
                      break;
                    case "styleCode":
                      cellContent = row.styleCode;
                      break;
                    case "productName":
                      cellContent = row.productName;
                      break;
                    case "colour":
                      cellContent = row.colour;
                      break;
                    case "colourChange":
                      cellContent = row.colourChange;
                      break;
                    case "comments":
                      cellContent = row.comments;
                      break;
                    default:
                      cellContent = row[column.id];
                  }

                  return (
                    <TableCell
                      key={column.id}
                      align={column.id === "shot" ? "center" : "left"}
                      sx={{
                        minWidth: 100,
                        maxWidth: 600,
                        textWrap: "wrap",
                        wordBreak: "break-all",
                        fontSize: 16,
                        height: "100px",
                        border: "1px solid #333333",
                        color: "black",
                        paddingTop: 1,
                        paddingBottom: 1,
                        paddingLeft: 4,
                        paddingRight: 4,
                      }}
                    >
                      {cellContent}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const pdfRef = useRef();

  const options = {
    filename: `${jobData.jobNumber}-${jobData.client}-SHOOTLIST.pdf`,
    method: "open",
    resolution: Resolution.NORMAL,
    page: {
      margin: Margin.NONE,
      format: "A4",
      orientation: "landscape",
    },
    canvas: {
      mimeType: "image/jpeg",
      qualityRatio: 0.8,
    },
    overrides: {
      pdf: {
        compress: true,
      },
      canvas: {
        useCORS: true,
      },
    },
  };

  const handleGeneratePdf = async () => {
    document.getElementById("tableContainer").style.display = "block";
    await generatePDF(() => document.getElementById("tableContainer"), options);
    document.getElementById("tableContainer").style.display = "none";
  };

  const handleOpenColumnDialog = () => {
    setIsColumnDialogOpen(true);
  };

  const handleCloseColumnDialog = () => {
    setIsColumnDialogOpen(false);
  };

  const handleColumnCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setTableColumnsVisibility((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleConfirmColumnDialog = async () => {
    setIsConfirmLoading(true);

    await handleGeneratePdf();
    setTimeout(() => {
      setIsColumnDialogOpen(false);
      setIsConfirmLoading(false);
    }, 200);
  };

  const handleCustomizeCategoriesOpen = () => {
    setIsCustomizeCategoriesDialogOpen(true);
  };

  const handleCustomizeCategoriesClose = () => {
    setIsCustomizeCategoriesDialogOpen(false);
  };

  const handleCategoryChange = (index, field, value) => {
    setCategories((prev) => {
      const updated = [...prev];
      updated[index][field] = value;
      return updated;
    });
  };

  const handleAddCategory = () => {
    setCategories((prev) => [
      ...prev,
      { name: `Category ${prev.length + 1}`, colour: "" },
    ]);
  };

  const handleRemoveCategory = (index) => {
    setCategories((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSaveCategories = () => {
    console.log(categories);
    firebase.firestore().collection("ClientDatabase").doc(clientID).update({
      shotListCategories: categories,
    });
    setIsCustomizeCategoriesDialogOpen(false);
  };

  const categoryColumnUpdated = useMemo(() => {
    if (!isProduct) return null;
    return {
      name: "CATEGORY",
      prop: "category",
      columnType: "text",
      size: 200 * adjustedZoomLevel,
      editable: true,
      cellTemplate: Template(({ model }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            background: parseCategoryBackground(model.category),
          }}
        >
          <Select
            value={model.category || "N/A"}
            onChange={(e) =>
              handleCheckboxChange(model.id, "category", e.target.value)
            }
            variant="filled"
            disableUnderline
            sx={{
              width: "100%",
              height: "100%",
              color: prefersDarkMode ? "#C8C8C8" : "#212121",
              "& .MuiSelect-icon": {
                color: prefersDarkMode ? "#C8C8C8" : "#212121",
              },
              "& .MuiFilledInput-input": {
                paddingTop: "25px",
                paddingBottom: "20px",
                paddingLeft: "15px",
                paddingRight: "32px",
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: prefersDarkMode ? "#2D3032" : "#FFFFFF",
                  color: prefersDarkMode ? "#C8C8C8" : "#212121",
                },
              },
            }}
          >
            {categories.map((cat) => (
              <MenuItem key={cat.name} value={cat.name}>
                {cat.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      )),
      columnTemplate: Template(ColumnHeaderTemplate),
      pin: "colPinStart",
    };
  }, [
    isProduct,
    ColumnHeaderTemplate,
    adjustedZoomLevel,
    handleCheckboxChange,
    prefersDarkMode,
    categories,
    parseCategoryBackground,
  ]);

  const columns = useMemo(() => {
    const cols = [];
    if (categoryColumnUpdated) {
      cols.push(categoryColumnUpdated);
    }
    if (isElectron || isStaff) {
      if (!columnDefaults["capture"].hidden) {
        cols.push(captureColumn);
      }
    }
    return cols.concat(remainingColumns);
  }, [
    categoryColumnUpdated,
    captureColumn,
    remainingColumns,
    visibleButtons,
    isElectron,
    isStaff,
    columnDefaults,
  ]);

  // class ClipboardJsonPlugin extends BasePlugin {
  //   constructor(grid) {
  //     super(grid);
  //     this.addEventListener("beforepaste", async (e) => {
  //       console.log("beforepaste", e);
  //     });
  //   }
  // }
  // useEffect(() => {
  //   const grid = gridRef.current;
  //   new ClipboardJsonPlugin(grid);
  // }, []);
  const handleBeforePasteApply = useCallback(
    async (e) => {
      e.preventDefault();
      // console.dir(e);
      const pastedData = e.detail.parsed;
      if (!pastedData || !Array.isArray(pastedData)) return;
      const pastedRowCount = pastedData.length;
      const selectedRange = await gridRef.current.getSelectedRange();
      const focusedCell = await gridRef.current.getFocused();

      console.log(focusedCell);
      const focusedCol = focusedCell.column.prop;
      if (!selectedRange) return;
      const firstRange = Array.isArray(selectedRange)
        ? selectedRange[0]
        : selectedRange;
      const startRow = firstRange.y;
      const availableRows = source.length - startRow;
      const rowsToAdd =
        availableRows < pastedRowCount ? pastedRowCount - availableRows : 0;
      if (rowsToAdd > 0) {
        const newRows = Array.from({ length: rowsToAdd + 1 }, () => ({
          id: uuidv4(),
          ...blankRow,
        }));
        setSource((prev) => {
          const updatedSource = [...prev, ...newRows];
          updateShotList(updatedSource);
          return updatedSource;
        });
      }
      setSource((prev) => {
        const updatedSource = [...prev];
        pastedData.forEach((rowData, index) => {
          const rowIndex = startRow + index;
          if (rowIndex < updatedSource.length) {
            updatedSource[rowIndex][focusedCol] = rowData[0];
          }
        });
        updateShotList(updatedSource);
        return updatedSource;
      });
      setTimeout(() => {
        gridRef.current.setCellsFocus(
          focusedCell.cell,
          {
            x: focusedCell.cell.x,
            y: focusedCell.cell.y + pastedRowCount - 1,
          },
          focusedCell.colType,
          focusedCell.rowType
        );
      }, 200);
    },
    [source, gridRef, setSource, updateShotList, blankRow]
  );
  return (
    <>
      <div
        id="container"
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          height: height,
          width: "100vw",
        }}
      >
        <FileMenu
          isStaff={isStaff}
          styleGuide={styleGuide}
          jobID={jobID}
          handleCreateFolders={handleCreateFolders}
          addRows={addRows}
          clearEmptyRows={clearEmptyRows}
          rowsWithFileNameCount={countRowsWithFileName()}
          rowsWithStillsShotCount={countRowsWithStillsShot()}
          handleUnlockRow={handleUnlockRow}
          handleLockRow={handleLockRow}
          clientName={clientData.brand}
          columnDefaults={columnDefaults}
          handleShowColumn={handleShowColumn}
          visibleButtons={visibleButtons}
          setVisibleButtons={setVisibleButtons}
          firebase={firebase}
          zoomLevel={zoomLevel}
          setZoomLevel={setZoomLevel}
          handleMenuPrintWindow={handleOpenColumnDialog}
          isLife={isLife}
          handleCustomizeCategoriesOpen={handleCustomizeCategoriesOpen}
        />

        <RightClickMenu
          firebase={firebase}
          jobID={jobID}
          columnDefaults={columnDefaults}
          setColumnDefaults={setColumnDefaults}
          updateShotList={updateShotList}
          contextMenu={contextMenu}
          handleClose={handleClose}
          isStaff={isStaff}
          handleUnlockRow={handleUnlockRow}
          handleLockRow={handleLockRow}
          setSource={setSource}
        />

        <GlobalStyle />

        <div
          id="gridContainer"
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RevoGrid
            id="grid"
            ref={gridRef}
            columns={columns}
            source={source}
            theme={prefersDarkMode ? "darkMaterial" : "material"}
            rowClass="myRowClass"
            resize={true}
            rowSize={calculateRowSize()}
            useClipboard={true}
            // onBeforeedit={(e) => {
            // e.preventDefault();
            // console.log("onBeforeedit", e);
            // }}
            onAfteredit={handleAfterEdit}
            applyOnClose={true}
            canFocus={true}
            range={true}
            hideAttribution={true}
            exporting={true}
            canMoveColumns={true}
            frameSize={40}
            autoSizeColumn={true}
            onAftercolumnresize={handleAfterColumnResize}
            onBeforefocuslost={(e) => {
              e.preventDefault();
            }}
            additionalData={{}}
            onBeforepasteapply={handleBeforePasteApply}
            // onBeforesourceset={(e) => {
            //   console.log(e);
            // }}
            // onBeforepasteapply={(e) => {
            //   console.log("onBeforepasteapply", e);
            //   e.preventDefault();
            // }}
            // onBeforepasteregion={(e) => {
            //   console.log("onBeforepasteregion", e);
            //   e.preventDefault();
            // }}
            // onAfterpasteapply={(e) => {
            //   e.preventDefault();
            // }}
          />
        </div>

        <div
          id="tabsContainer"
          style={{
            borderTop: "1px solid rgba(0, 0, 0, 0.12)",
            marginBottom: isMobileBrowser ? 10 : 0,
          }}
        >
          <CustomTabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            indicatorcolor={indicatorColor}
          >
            <CustomTab
              label="STYLE GUIDE"
              tabbackgroundcolor="rgba(200, 200, 0, 0.2)"
              tabselectedbackgroundcolor="rgba(200, 200, 0, 0.3)"
            />
            {jobTabs.map((job, index) => {
              let title;
              let dateStr = "";
              let tabBackgroundColor = "transparent";
              let tabSelectedBackgroundColor = "rgba(0, 0, 0, 0.1)";

              if (job.bookedStart) {
                const bookedStartDate = new Date(job.bookedStart);
                dateStr = `${bookedStartDate
                  .getDate()
                  .toString()
                  .padStart(2, "0")}/${(bookedStartDate.getMonth() + 1)
                  .toString()
                  .padStart(2, "0")}/${bookedStartDate.getFullYear()}`;

                const today = new Date();
                const timeDiff = bookedStartDate.getTime() - today.getTime();
                const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

                if (dayDiff < -2) {
                  tabBackgroundColor = "rgba(200, 20, 20, 0.05)";
                  tabSelectedBackgroundColor = "rgba(200, 20, 20, 0.1)";
                } else if (dayDiff > 14) {
                  tabBackgroundColor = "rgba(20, 200, 20, 0.05)";
                  tabSelectedBackgroundColor = "rgba(20, 200, 20, 0.1)";
                } else {
                  tabBackgroundColor = "transparent";
                  tabSelectedBackgroundColor = "rgba(0, 0, 0, 0.1)";
                }
              }

              if (job.jobNumber) {
                title = `${job.jobNumber} - ${job.jobTypeFull.replace(
                  / \(.*/,
                  ""
                )} - ${dateStr}`;
              } else {
                title = `${job.jobTypeFull.replace(/ \(.*/, "")} - ${dateStr}`;
              }
              return (
                <CustomTab
                  key={job.id}
                  label={title}
                  tabbackgroundcolor={tabBackgroundColor}
                  tabselectedbackgroundcolor={tabSelectedBackgroundColor}
                />
              );
            })}
          </CustomTabs>
        </div>
      </div>

      <div
        id="tableContainer"
        style={{
          display: "none",
        }}
        ref={pdfRef}
      >
        {renderDynamicTable()}
      </div>

      <Dialog
        open={isCustomizeCategoriesDialogOpen}
        onClose={handleCustomizeCategoriesClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            maxHeight: "700px",
          },
        }}
      >
        <DialogTitle>Customize Categories</DialogTitle>
        <DialogContent>
          {categories.map((category, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 16,
              }}
            >
              <TextField
                label="Category Name"
                size="small"
                variant="filled"
                InputProps={{ disableUnderline: true }}
                value={category.name}
                onChange={(e) =>
                  handleCategoryChange(
                    index,
                    "name",
                    e.target.value.toUpperCase()
                  )
                }
                style={{ flex: 1, marginRight: 16 }}
                sx={{
                  maxWidth: 550,
                  background: prefersDarkMode
                    ? adjustHexColor(category.colour) || ""
                    : category.colour || "",
                }}
              />
              <GithubPicker
                color={category.colour}
                onChangeComplete={(color) =>
                  handleCategoryChange(index, "colour", color.hex)
                }
                colors={[
                  "#00000000",
                  "#de9d9b",
                  "#f1cda2",
                  "#bcd6ac",
                  "#b2a7d2",
                  "#a6c4e4",
                ]}
              />
              <IconButton
                onClick={() => handleRemoveCategory(index)}
                style={{ marginLeft: 16 }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleAddCategory}>
            Add Category
          </Button>
          <Button onClick={handleCustomizeCategoriesClose}>Cancel</Button>
          <Button
            onClick={handleSaveCategories}
            variant="contained"
            disabled={isConfirmLoading}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isColumnDialogOpen} onClose={handleCloseColumnDialog}>
        <DialogTitle>Which columns should be printed?</DialogTitle>
        <DialogContent>
          <FormGroup>
            {tableColumns.map((column) => (
              <FormControlLabel
                key={column.id}
                control={
                  <Checkbox
                    checked={tableColumnsVisibility[column.id]}
                    onChange={handleColumnCheckboxChange}
                    name={column.id}
                  />
                }
                label={column.label}
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseColumnDialog}>Cancel</Button>
          <Button
            onClick={handleConfirmColumnDialog}
            variant="contained"
            disabled={isConfirmLoading}
            startIcon={isConfirmLoading ? <CircularProgress size={20} /> : null}
          >
            {isConfirmLoading ? "Generating PDF" : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomGrid;

function isElectronFunc() {
  if (
    typeof window !== "undefined" &&
    typeof window.process === "object" &&
    window.process.type === "renderer"
  ) {
    return true;
  }

  if (
    typeof process !== "undefined" &&
    typeof process.versions === "object" &&
    !!process.versions.electron
  ) {
    return true;
  }

  if (
    typeof navigator === "object" &&
    typeof navigator.userAgent === "string" &&
    navigator.userAgent.indexOf("Electron") >= 0
  ) {
    return true;
  }

  return false;
}

function adjustHexColor(
  hex,
  darkenPercentage = 20,
  saturationPercentage = 100
) {
  darkenPercentage = Math.min(100, Math.max(0, darkenPercentage));
  saturationPercentage = Math.min(100, Math.max(0, saturationPercentage));

  hex = hex.replace(/^#/, "");
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  const rgbToHsl = (r, g, b) => {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;
    if (max === min) {
      h = s = 0;
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }
    return [h, s, l];
  };

  const hslToRgb = (h, s, l) => {
    let r, g, b;
    if (s === 0) {
      r = g = b = l;
    } else {
      const hueToRgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hueToRgb(p, q, h + 1 / 3);
      g = hueToRgb(p, q, h);
      b = hueToRgb(p, q, h - 1 / 3);
    }
    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  };

  let [h, s, l] = rgbToHsl(r, g, b);

  l = (l * (100 - darkenPercentage)) / 100;
  s = s * (saturationPercentage / 100);

  [r, g, b] = hslToRgb(h, s, l);

  const toHex = (value) => value.toString(16).padStart(2, "0");
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}
