import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";

import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";

import { useAtom } from "jotai";
import {
  themeAtom,
  isMobileAtom,
  isAdminAtom,
  isDebouncingAtom,
  jobTypesAtom,
} from "../../../../-Atoms";

const BreakdownItem = ({
  item,
  items,
  setItems,
  index,
  showHiddenItems,
  showRemoveBreakdownItem,
  firebase,
  jobData,
  updateFS,
  imageBreakdownClient,
}) => {
  const [isAdmin] = useAtom(isAdminAtom);
  const [isMobile] = useAtom(isMobileAtom);
  const [theme] = useAtom(themeAtom);
  const [isDebouncing] = useAtom(isDebouncingAtom);
  const [jobTypes] = useAtom(jobTypesAtom);

  const [descriptionFocus, setDescriptionFocus] = useState(false);
  const [quantityProcessedFocus, setQuantityProcessedFocus] = useState(false);
  const [filesInPostFocus, setFilesInPostFocus] = useState(false);
  const [quantitySuppliedFocus, setQuantitySuppliedFocus] = useState(false);

  const [description, setDescription] = useState("");
  const [jobTypesFilter, setJobTypesFilter] = useState([]);
  const [quantityProcessed, setQuantityProcessed] = useState("");
  const [filesInPost, setFilesInPost] = useState("");
  const [quantitySupplied, setQuantitySupplied] = useState("");
  const [expectedPerHour, setExpectedPerHour] = useState("");
  const [costPerFile, setCostPerFile] = useState("");
  const [existingItem, setExistingItem] = useState("");
  const [itemHidden, setItemHidden] = useState("");

  const [isMatching, setIsMatching] = useState(
    isJobTypeMatching(jobTypes, jobTypesFilter, jobData?.jobTypeFull)
  );

  useEffect(() => {
    setIsMatching(
      isJobTypeMatching(jobTypes, jobTypesFilter, jobData?.jobTypeFull)
    );
  }, [setIsMatching, jobTypes, jobTypesFilter, jobData]);

  useEffect(() => {
    if (!arrayHasId(imageBreakdownClient, item.id)) {
      if (quantityProcessed !== item.quantityProcessed) {
        setQuantityProcessed(item.quantityProcessed);
      }
      if (filesInPost !== item.filesInPost) {
        setFilesInPost(item.filesInPost);
      }
      if (quantitySupplied !== item.quantitySupplied) {
        setQuantitySupplied(item.quantitySupplied);
      }
      if (itemHidden !== item.itemHidden) {
        setItemHidden(item.itemHidden);
      }
      if (description !== item.description) {
        setDescription(item.description);
      }
      if (expectedPerHour !== item.expectedPerHour) {
        setExpectedPerHour(item.expectedPerHour);
      }
      if (costPerFile !== item.costPerFile) {
        setCostPerFile(item.costPerFile);
      }
      if (existingItem !== item.existingItem) {
        setExistingItem(item.existingItem);
      }
    } else {
      if (quantityProcessed !== item.quantityProcessed) {
        setQuantityProcessed(item.quantityProcessed);
      }
      if (filesInPost !== item.filesInPost) {
        setFilesInPost(item.filesInPost);
      }
      if (quantitySupplied !== item.quantitySupplied) {
        setQuantitySupplied(item.quantitySupplied);
      }
      if (itemHidden !== item.itemHidden) {
        setItemHidden(item.itemHidden);
      }
      setExistingItem(true);
    }
  }, [
    item,
    imageBreakdownClient,
    description,
    existingItem,
    costPerFile,
    expectedPerHour,
    itemHidden,
    quantitySupplied,
    filesInPost,
    quantityProcessed,
  ]);

  useEffect(() => {
    if (arrayHasId(imageBreakdownClient, item.id)) {
      const index = findIndexById(imageBreakdownClient, item.id);
      setDescription(imageBreakdownClient[index].description);
      setExpectedPerHour(imageBreakdownClient[index].expectedPerHour);
      setCostPerFile(imageBreakdownClient[index].costPerFile);
      setJobTypesFilter(imageBreakdownClient[index].jobTypesFilter);

      let push = false;
      if (item.description !== imageBreakdownClient[index].description) {
        item.description = imageBreakdownClient[index].description;
        push = true;
      }
      if (
        item.expectedPerHour !== imageBreakdownClient[index].expectedPerHour
      ) {
        item.expectedPerHour = imageBreakdownClient[index].expectedPerHour;
        push = true;
      }
      if (item.costPerFile !== imageBreakdownClient[index].costPerFile) {
        item.costPerFile = imageBreakdownClient[index].costPerFile;
        push = true;
      }
      try {
        if (
          !arraysAreEqual(
            item.jobTypesFilter,
            imageBreakdownClient[index].jobTypesFilter,
            imageBreakdownClient[index]
          )
        ) {
          item.jobTypesFilter = imageBreakdownClient[index].jobTypesFilter;
          push = true;
        }
      } catch (e) {
        item.jobTypesFilter = [];
        push = true;
      }
      if (push) {
        firebase
          .firestore()
          .collection("JobData")
          .doc(jobData.id)
          .update({ imageBreakdown: items }, { merge: true })
          .catch((e) => console.log(e));
      }
    }
  }, [firebase, imageBreakdownClient, item, items, jobData]);

  function arraysAreEqual(array1, array2, index) {
    if (array1.length !== array2.length) {
      return false;
    }
    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }
    return true;
  }

  const removeBreakdownItem = () => {
    items.splice(index, 1);
    setItems([...items]);
    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ imageBreakdown: items }, { merge: true })
      .catch((e) => console.log(e));
  };

  function arrayHasId(array, id) {
    return array.some((item) => item.id === id);
  }
  function findIndexById(array, targetId) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === targetId) {
        return i;
      }
    }
    return -1;
  }
  
  function isJobTypeMatching(jobTypes, noteJobTypes, jobType) {
    let res = false;
    if (!noteJobTypes) {
      return true;
    }
    if (noteJobTypes.length === 0) {
      return true;
    }

    jobTypes.forEach((jt) => {
      const combined = `${jt.name} (-${jt.nameShort})`;
      if (combined === jobType) {
        noteJobTypes.forEach((njt) => {
          if (jt.id === njt) res = true;
        });
      }
    });
    return res;
  }

  if (!isMatching) return null;
  return (
    (<div
                            style={{
                              display: showHiddenItems ? "block" : itemHidden ? "none" : "block",
                              background: itemHidden
                                ? theme === "dark"
                                  ? "rgba(0,0,0,0.2)"
                                  : "rgba(100,100,100,0.2)"
                                : "",
                              borderRadius: 5,
                              padding: 10,
                            }}
                          >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          gap: 15,
          marginBottom: 4,
        }}
      >
        <TextField
          inputProps={{
            style: {
              fontSize: 16,
              fontWeight: existingItem ? "bold" : "",
              color:
                theme === "dark"
                  ? "rgba(255, 255, 255, 0.6)"
                  : "rgba(0, 0, 0, 0.6)",
            },
          }}
          InputLabelProps={{ style: { fontSize: 16 } }}
          placeholder="Replace Me - e.g Ghost / Life / Jackets / T-Shirts etc..."
          variant="standard"
          size="small"
          multiline
          disabled={
            Boolean(existingItem) ? true : isDebouncing && !descriptionFocus
          }
          value={`${description}${
            isAdmin && costPerFile ? ` - $${costPerFile}++ Per File` : ""
          }`}
          onChange={(e) => {
            if (existingItem) return;
            setDescription(e.target.value);
            item.description = e.target.value;
            updateFS(firebase, jobData.id, "imageBreakdown", [...items]);
          }}
          sx={{
            width: "100%",
            opacity: itemHidden ? 0.4 : 1,
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor:
                theme === "dark"
                  ? "rgba(255, 255, 255, 0.6)"
                  : "rgba(0, 0, 0, 0.6)",
            },
          }}
          InputProps={{
            endAdornment: existingItem ? (
              quantityProcessed === "" ? (
                <InputAdornment position="end">
                  <IconButton
                    disabled={isDebouncing ? true : false}
                    onClick={() => {
                      setItemHidden((prev) => !prev);
                      item.itemHidden = !itemHidden;
                      setItems([...items]);
                      updateFS(firebase, jobData.id, "imageBreakdown", [
                        ...items,
                      ]);
                    }}
                  >
                    {!itemHidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ) : null
            ) : showRemoveBreakdownItem ? (
              <InputAdornment position="end">
                <IconButton onClick={removeBreakdownItem}>
                  <RemoveIcon />
                </IconButton>
              </InputAdornment>
            ) : null,
            disableUnderline: true,
          }}
          onFocus={() => setDescriptionFocus(true)}
          onBlur={() => setTimeout(() => setDescriptionFocus(false), 300)}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 18,
          marginBottom: 5,
          flexWrap: isMobile ? "wrap" : "nowrap",
        }}
      >
        <TextField
          inputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          label="Processed"
          disabled={
            itemHidden ? itemHidden : isDebouncing && !quantityProcessedFocus
          }
          variant="filled"
          value={quantityProcessed}
          onChange={(e) => {
            const numericValue = e.target.value.replace(/[^0-9]/g, "");
            setQuantityProcessed(numericValue);
            item.quantityProcessed = numericValue;
            updateFS(firebase, jobData.id, "imageBreakdown", [...items]);
          }}
          size="small"
          sx={{
            width: isMobile ? "47%" : "100%",
            opacity: itemHidden ? 0.4 : 1,
          }}
          InputProps={{
            disableUnderline: true,
          }}
          onFocus={() => setQuantityProcessedFocus(true)}
          onBlur={() => setTimeout(() => setQuantityProcessedFocus(false), 300)}
        />
        <TextField
          inputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          variant="filled"
          label={isVideoJob(jobData) ? "Edited" : "Files In Post"}
          size="small"
          sx={{
            width: isMobile ? "47%" : "100%",
            opacity: itemHidden ? 0.4 : 1,
          }}
          value={filesInPost}
          onChange={(e) => {
            const numericValue = e.target.value.replace(/[^0-9]/g, "");
            setFilesInPost(numericValue);
            item.filesInPost = numericValue;
            updateFS(firebase, jobData.id, "imageBreakdown", [...items]);
          }}
          InputProps={{
            disableUnderline: true,
          }}
          disabled={itemHidden ? itemHidden : isDebouncing && !filesInPostFocus}
          onFocus={() => setFilesInPostFocus(true)}
          onBlur={() => setTimeout(() => setFilesInPostFocus(false), 300)}
        />
        <TextField
          inputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          label="Supplied"
          variant="filled"
          value={quantitySupplied}
          onChange={(e) => {
            const numericValue = e.target.value.replace(/[^0-9]/g, "");
            setQuantitySupplied(numericValue);
            item.quantitySupplied = numericValue;
            updateFS(firebase, jobData.id, "imageBreakdown", [...items]);
          }}
          size="small"
          sx={{
            width: isMobile ? "47%" : "100%",
            opacity: itemHidden ? 0.4 : 1,
            backgroundColor: quantitySupplied
              ? theme === "dark"
                ? "rgba(120, 175, 200, 0.3)"
                : "rgba(120, 175, 200, 0.3)"
              : "",
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
          }}
          InputProps={{
            disableUnderline: true,
          }}
          disabled={
            itemHidden ? itemHidden : isDebouncing && !quantitySuppliedFocus
          }
          onFocus={() => setQuantitySuppliedFocus(true)}
          onBlur={() => setTimeout(() => setQuantitySuppliedFocus(false), 300)}
        />
        {!isVideoJob(jobData) ? (
          <TextField
            inputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            variant="filled"
            label={"Expected Per Hour"}
            size="small"
            sx={{
              width: isMobile ? "47%" : "100%",
              opacity: itemHidden ? 0.4 : 1,
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
            }}
            value={expectedPerHour ? expectedPerHour : "---"}
            disabled={true}
            InputProps={{
              disableUnderline: true,
            }}
          />
        ) : null}
      </div>
    </div>)
  );
};

export default BreakdownItem;

const isVideoJob = (jobData) => {
  if (jobData.jobTypeFull.includes("-V")) return true;
  if (jobData.jobTypeFull.includes("-E")) return true;
  return false;
};
