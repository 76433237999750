import React, { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";

import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import MailIcon from "@mui/icons-material/Mail";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { useAtom } from "jotai";
import {
  currentStaffDataAtom,
  themeAtom,
} from "../../../-Atoms";
import { useDynamicDebounceCallback } from "react-dynamic-debounce";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

let deleteTimeout;
let deleteJobTimeout;

const Outsourcers = ({
  jobData,
  clientData,
  staffData,
  findStaff,
  firebase,
  isDarkMode,
  modalWidth,
  isMobile,
  setEmailModalOpen,
  setEmailKind,
}) => {
  const [currentStaffData] = useAtom(currentStaffDataAtom);
  const [theme] = useAtom(themeAtom);
  const [showDeleteJob, setShowDeleteJob] = useState(false);
  const [outsourcerList, setOutsourcerList] = useState([]);
  const [outsourcerJobs, setOutsourcerJobs] = useState(jobData.outsourcers);
  const [showAllOutsourcers, setShowAllOutsourcers] = useState(false);

  useEffect(() => {
    setOutsourcerJobs(jobData.outsourcers);
  }, [jobData]);

  useEffect(() => {
    const fetchData = async () => {
      const tmp = [];
      await firebase
        .firestore()
        .collection("Outsourcers")
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            const data = doc.data();
            if (!showAllOutsourcers) {
              if (data.isPrimary) tmp.push(data);
            } else {
              tmp.push(data);
            }
          });
        });
      setOutsourcerList(tmp);
    };
    fetchData();
  }, [firebase, showAllOutsourcers]);

  const [updateFS] = useDynamicDebounceCallback(
    (firebase, id, field, value) => {
      firebase
        .firestore()
        .collection("JobData")
        .doc(id)
        .update({ [field]: value }, { merge: true })
        .catch((error) => {
          console.log(error);
        });
    },
    {
      defaultDelay: 1000,
      maxDelay: 6000,
      minSamples: 6,
      maxSamples: 4,
      delayFunction: (averageGap) => Math.floor(averageGap + 300),
    }
  );

  const handleNewJob = () => {
    jobData.outsourcers.push({
      outsourcer: "",
      dueDate: null,
      sentDate: null,
      staff: currentStaffData ? currentStaffData.firstName : "",
      lineItems: [{ type: "", images: "", price: "" }],
      includeRetouchingNotes: shouldDefaultRetouchingNotes() ? true : false,
    });
    setOutsourcerJobs([...jobData.outsourcers]);
    updateFS(firebase, jobData.id, "outsourcers", jobData.outsourcers);
  };
  const shouldDefaultRetouchingNotes = () => {
    if (jobData.jobTypeFull.includes("L")) return true;
    if (jobData.jobTypeFull.includes("LC")) return true;
    return false;
  };

  const handleDeleteClick = () => {
    if (deleteJobTimeout) {
      clearTimeout(deleteJobTimeout);
      deleteJobTimeout = null;
    } else {
      deleteJobTimeout = setTimeout(() => {
        setShowDeleteJob(false);
        deleteJobTimeout = null;
      }, 15000);
    }
    setShowDeleteJob(!showDeleteJob);
  };

  return (
    <div style={{ position: "relative" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <IconButton onClick={handleDeleteClick}>
          <RemoveIcon />
        </IconButton>
        <IconButton onClick={handleNewJob}>
          <AddIcon />
        </IconButton>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={showAllOutsourcers}
                onChange={(e, checked) => {
                  setShowAllOutsourcers(checked);
                }}
                size="small"
              />
            }
            sx={{
              cursor: "pointer",
              MozUserSelect: "none",
              WebkitUserSelect: "none",
              msUserSelect: "none",
            }}
            label="Show All Outsourcers"
          />
        </FormGroup>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: `calc(${modalWidth} - 50px) `,
          overflow: "hidden",
        }}
      >
        {outsourcerJobs?.map((job, index) => (
          <OutsourcerJob
            key={`outsourcerJob-${index}`}
            jobData={jobData}
            firebase={firebase}
            outsourcerList={outsourcerList}
            job={job}
            index={index}
            showDeleteJob={showDeleteJob}
            setOutsourcerJobs={setOutsourcerJobs}
            isDarkMode={isDarkMode}
            modalWidth
            setEmailModalOpen={setEmailModalOpen}
            setEmailKind={setEmailKind}
            updateFS={updateFS}
          />
        ))}
        <div
          style={{
            width: "90%",
            borderBottom: `2px solid grey`,
            maskImage: `linear-gradient(90deg, transparent, ${
              theme === "dark" ? "rgb(240, 240, 240)" : "rgb(30, 30, 30)"
            } 200px, ${
              theme === "dark" ? "rgb(240, 240, 240)" : "rgb(30, 30, 30)"
            } calc(100% - 200px), transparent)`,
            WebkitMaskImage: `linear-gradient(90deg, transparent, ${
              theme === "dark" ? "rgb(240, 240, 240)" : "rgb(30, 30, 30)"
            } 200px, ${
              theme === "dark" ? "rgb(240, 240, 240)" : "rgb(30, 30, 30)"
            } calc(100% - 200px), transparent)`,
            marginTop: 30,
            marginBottom: 30,
          }}
        >
          &zwnj;
        </div>
      </div>
    </div>
  );
};

export default Outsourcers;

const typeArray2 = [
  {
    name: "Complete Retouch - Ghost & Deep Etch",
    price: "2.00",
  },
  {
    name: "Level 1",
    price: "1.50",
  },
  {
    name: "Level 2",
    price: "2.00",
  },
  {
    name: "Level 3",
    price: "2.50",
  },
  {
    name: "Level 4",
    price: "3.00",
  },
  {
    name: "Content",
    price: "3.00",
  },
];

const priceArray = [
  "0.80",
  "1.00",
  "1.20",
  "1.40",
  "1.50",
  "2.00",
  "2.50",
  "3.00",
  "3.50",
  "4.00",
];

const updateTotal = (job, setTotal) => {
  let total = 0;
  job.lineItems.forEach((item) => {
    if (!item.price) {
      return;
    }
    if (!item.images) {
      return;
    }
    total = total + item.price * item.images;
  });
  setTotal(`$${total.toFixed(2)}`);
};

const OutsourcerLineItem = ({
  jobData,
  firebase,
  job,
  item,
  index,
  jobIndex,
  setTotal,
  modalWidth,
  showDelete,
  updateFS,
}) => {
  const [type, setType] = useState(item.type);
  const [images, setImages] = useState(item.images);
  const [price, setPrice] = useState(item.price);

  useEffect(() => {
    setType(item.type);
    setImages(item.images);
    setPrice(item.price);
    updateTotal(job, setTotal);
  }, [item, job, setTotal]);

  const handlePriceChange = (value) => {
    job.lineItems[index].price = value;
    setPrice(value);
    updateFS(firebase, jobData.id, "outsourcers", jobData.outsourcers);
    updateTotal(job, setTotal);
  };

  const handleImagesChange = (e) => {
    job.lineItems[index].images = e.target.value;
    setImages(e.target.value);
    updateFS(firebase, jobData.id, "outsourcers", jobData.outsourcers);
    updateTotal(job, setTotal);
  };
  const handleTypeChange = (value) => {
    job.lineItems[index].type = value;
    setType(value);
    updateFS(firebase, jobData.id, "outsourcers", jobData.outsourcers);
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 10,
        width: modalWidth,
      }}
    >
      <label style={{ marginLeft: 8, marginRight: 8 }}>{index + 1}.</label>
      <Autocomplete
        value={type}
        onChange={(event, newValue) => {
          handleTypeChange(
            typeof newValue === "string"
              ? newValue.replace(/ - \$.*/, "")
              : newValue?.name || ""
          );
          if (newValue.price) {
            handlePriceChange(newValue.price);
          }
        }}
        onInputChange={(event, newInputValue) => {
          handleTypeChange(newInputValue);
        }}
        options={typeArray2}
        freeSolo
        getOptionLabel={(option) => {
          return typeof option === "string"
            ? option
            : `${option.name} - $${option.price}`;
        }}
        disableClearable
        fullWidth
        style={{ width: showDelete ? 325 : 365 }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
            placeholder="Select Type"
            label="Image Type"
            size="small"
          />
        )}
        isOptionEqualToValue={(option, value) => option.name === value}
      />
      <TextField
        inputclassname={`Job-${jobIndex}-LineItemImages`}
        label="Images"
        variant="filled"
        InputProps={{
          disableUnderline: true,
        }}
        value={images || ""}
        onChange={handleImagesChange}
        fullWidth
        sx={{ maxWidth: 100 }}
        size="small"
      />
      <Autocomplete
        disableClearable
        className={`Job-${jobIndex}-LineItemPrice`}
        value={price}
        onChange={(event, newValue) => {
          handlePriceChange(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          handlePriceChange(newInputValue);
        }}
        options={priceArray}
        fullWidth
        sx={{ maxWidth: 100 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Price"
            fullWidth
            sx={{ maxWidth: 100 }}
            variant="filled"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
            size="small"
          />
        )}
      />
    </div>
  );
};

const OutsourcerJob = ({
  jobData,
  firebase,
  outsourcerList,
  job,
  index,
  showDeleteJob,
  setOutsourcerJobs,
  isDarkMode,
  modalWidth,
  setEmailModalOpen,
  setEmailKind,
  updateFS,
}) => {
  const [showDelete, setShowDelete] = useState(false);

  const [outsourcer, setOutsourcer] = useState(job.outsourcer);
  const [dueDate, setDueDate] = useState(parseDate(job.dueDate));
  const [staff, setStaff] = useState(job.staff);
  const [lineItems, setLineItems] = useState(job.lineItems);
  const [total, setTotal] = useState("");
  const [includeRetouchingNotes, setIncludeRetouchingNotes] = useState(
    job.includeRetouchingNotes
  );
  const [emailSent, setEmailSent] = useState(job.emailSent || false);

  useEffect(() => {
    setOutsourcer(job.outsourcer);
    setDueDate(parseDate(job.dueDate));
    setStaff(job.staff);
    setLineItems(job.lineItems);
    setEmailSent(job.emailSent);
  }, [job]);

  const sendEmail = () => {
    if (!dueDate) {
      alert("Enter due date");
      return;
    }
    if (!staff) {
      alert("Enter staff");
      return;
    }
    if (!outsourcer) {
      alert("Enter outsourcer");
      return;
    }

    setEmailModalOpen(true);
    setEmailKind(
      `Outsourcers-${
        includeRetouchingNotes ? "includeRetouchingNotes" : "noRetouchingNotes"
      }-${index}`
    );
  };

  const handleNewLine = () => {
    job.lineItems.push({ type: "", images: "", price: "" });
    setLineItems([...job.lineItems]);
    updateFS(firebase, jobData.id, "outsourcers", jobData.outsourcers);
  };
  const handleDeleteLine = (index) => {
    job.lineItems.splice(index, 1);
    setLineItems([...job.lineItems]);
    updateFS(firebase, jobData.id, "outsourcers", jobData.outsourcers);
  };
  const handleShowDelete = () => {
    if (deleteTimeout) {
      clearTimeout(deleteTimeout);
      deleteTimeout = null;
    } else {
      deleteTimeout = setTimeout(() => {
        setShowDelete(false);
        deleteTimeout = null;
      }, 10000);
    }
    setShowDelete(!showDelete);
  };
  const handleDeleteJob = () => {
    jobData.outsourcers.splice(index, 1);
    setOutsourcerJobs([...jobData.outsourcers]);
    updateFS(firebase, jobData.id, "outsourcers", jobData.outsourcers);
  };
  const handleOutsourcerChange = (value) => {
    job.outsourcer = value;
    setOutsourcer(value);
    updateFS(firebase, jobData.id, "outsourcers", jobData.outsourcers);
  };

  const [alertDueDate, setAlertDueDate] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  const handleDueDateChange = (value) => {
    const moreThanFour = isMoreThanFourDaysFuture(value);
    if (!moreThanFour) {
      job.dueDate = value.toISOString();
      setDueDate(value);
      updateFS(firebase, jobData.id, "outsourcers", jobData.outsourcers);
    } else {
      setAlertDueDate(value);
      setOpenAlert(true);
    }
  };

  const handleStaffChange = (e) => {
    job.staff = e.target.value;
    setStaff(e.target.value);
    updateFS(firebase, jobData.id, "outsourcers", jobData.outsourcers);
  };

  const handleIncludeRetouchingNotesChange = (e, checked) => {
    job.includeRetouchingNotes = checked;
    setIncludeRetouchingNotes(checked);
    updateFS(firebase, jobData.id, "outsourcers", jobData.outsourcers);
  };

  function isMoreThanFourDaysFuture(date) {
    const currentDate = dayjs().startOf("day");
    const selectedDate = dayjs(date).startOf("day");
    const differenceInDays = selectedDate.diff(currentDate, "day");
    return differenceInDays > 4;
  }

  return (
    <div
      style={{
        position: "relative",
        padding: "10px 50px",
        marginTop: 20,
        marginBottom: 0,
        marginLeft: 8,
        marginRight: 8,
      }}
    >
      <AlertDialog
        open={openAlert}
        onClose={handleCloseAlert}
        alertDueDate={alertDueDate}
        setDueDate={setDueDate}
        updateFS={updateFS}
        jobData={jobData}
        firebase={firebase}
        job={job}
      />
      {showDeleteJob ? (
        <div
          style={{
            background: "#00000015",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "100",
            position: "absolute",
            borderRadius: "10px",
            top: "0px",
            bottom: "0px",
            left: "0px",
            right: "0px",
          }}
        >
          <IconButton onClick={handleDeleteJob}>
            <RemoveIcon
              style={{
                color: "#ff0033",
                width: 60,
                height: 60,
                filter: "drop-shadow(4px 4px 3px #00000060)",
              }}
            />
          </IconButton>
        </div>
      ) : null}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: isDarkMode ? "rgb(192, 194, 195)" : "rgba(0, 0, 0, 0.6)",
          marginBottom: 10,
        }}
      >
        <span style={{ fontWeight: "bold", fontSize: "1.6rem" }}>
          {numToLetter(index + 1)}
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeRetouchingNotes}
                  onChange={handleIncludeRetouchingNotesChange}
                  size="small"
                />
              }
              sx={{
                cursor: "pointer",
                MozUserSelect: "none",
                WebkitUserSelect: "none",
                msUserSelect: "none",
              }}
              label="Retouching Notes"
            />
          </FormGroup>
          <div>
            <IconButton sx={{ marginLeft: 2 }} onClick={handleShowDelete}>
              <RemoveIcon />
            </IconButton>
            <IconButton onClick={handleNewLine}>
              <AddIcon />
            </IconButton>
            <IconButton
              disabled={emailSent}
              onClick={sendEmail}
              sx={{ marginLeft: 2 }}
            >
              <MailIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <Autocomplete
          value={outsourcer}
          disableClearable
          onChange={(event, newValue) => {
            handleOutsourcerChange(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            handleOutsourcerChange(newInputValue);
          }}
          options={outsourcerList?.map((o) => o.companyName)}
          getOptionLabel={(opts) => opts}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
              placeholder="Select Outsourcer"
              label="Outsourcer"
              size="small"
            />
          )}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          gap: 10,
          marginTop: 10,
        }}
      >
        <TextField
          label="Staff"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          value={staff || ""}
          onChange={handleStaffChange}
          fullWidth
          size="small"
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            label={"Due"}
            format="DD/MM"
            value={dueDate || null}
            onChange={(d) => {
              handleDueDateChange(d);
            }}
            slotProps={{
              textField: {
                variant: "filled",
                InputProps: {
                  disableUnderline: true,
                },
                fullWidth: true,
                size: "small",
              },
            }}
          />
        </LocalizationProvider>
        <TextField
          label="Total"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          disabled={true}
          value={total || ""}
          fullWidth
          size="small"
          inputProps={{ readOnly: true }}
        />
      </div>
      {lineItems?.map((item, i) => (
        <div
          key={`outsourcerJob-${index}-lineItem-${i}-Div`}
          style={{
            display: "flex",
            marginTop: 10,
            alignItems: "center",
          }}
        >
          {showDelete ? (
            <IconButton
              size="small"
              style={{
                width: 40,
                height: 40,
              }}
              key={`outsourcerJob-${index}-lineItem-${i}-Delete`}
              onClick={() => {
                handleDeleteLine(i);
              }}
            >
              <RemoveIcon
                style={{
                  width: 25,
                  height: 25,
                }}
              />
            </IconButton>
          ) : null}
          <OutsourcerLineItem
            key={`outsourcerJob-${index}-lineItem-${i}`}
            jobData={jobData}
            firebase={firebase}
            job={job}
            item={item}
            index={i}
            jobIndex={index}
            setTotal={setTotal}
            modalWidth={modalWidth}
            showDelete={showDelete}
            updateFS={updateFS}
          />
        </div>
      ))}
    </div>
  );
};

function AlertDialog({
  open,
  onClose,
  alertDueDate,
  setDueDate,
  updateFS,
  jobData,
  firebase,
  job,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Is the due date correct?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          The due date selected (
          {formatISODateToDayMonthWithDaysFromNow(alertDueDate)}) is pretty far
          in the future, is this correct?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Oops I'll change that!
        </Button>
        <Button
          onClick={() => {
            job.dueDate = alertDueDate.toISOString();
            setDueDate(alertDueDate);
            updateFS(firebase, jobData.id, "outsourcers", jobData.outsourcers);
            onClose();
          }}
          autoFocus
        >
          Yep that's correct!
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function formatISODateToDayMonthWithDaysFromNow(date) {
  const inputDate = dayjs(date).startOf("day");
  const currentDate = dayjs().startOf("day");

  const day = inputDate.date();
  const month = inputDate.month() + 1;

  const differenceInDays = inputDate.diff(currentDate, "day");

  return `${day}/${month} - ${differenceInDays} Days`;
}

function numToLetter(num) {
  let s = "",
    t;

  while (num > 0) {
    t = (num - 1) % 26;
    s = String.fromCharCode(65 + t) + s;
    num = ((num - t) / 26) | 0;
  }
  return s || undefined;
}

const parseDate = (date) => {
  if (!date) {
    return null;
  }
  return dayjs(date);
};
