import React, { useState, useEffect } from "react";

import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

let deleteTimeout;
let arrayRemove;

const Checklists = ({ client, firebase, theme }) => {
  arrayRemove = firebase.firestore.FieldValue.arrayRemove;

  const [generalChecklist, setGeneralChecklist] = useState(
    client.generalChecklist
  );
  const [lifeChecklist, setLifeChecklist] = useState(client.lifeChecklist);
  const [productChecklist, setProductChecklist] = useState(
    client.productChecklist
  );
  const [videoChecklist, setVideoChecklist] = useState(client.videoChecklist);

  useEffect(() => {
    setGeneralChecklist(client.generalChecklist);
    setLifeChecklist(client.lifeChecklist);
    setProductChecklist(client.productChecklist);
    setVideoChecklist(client.videoChecklist);
  }, [client]);

  return (
    <div
      style={{
        overflow: "auto",
        maxHeight: "calc(100vh - 220px)",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      <ChecklistContainer
        key={`${client.id}-generalChecklist`}
        checklist={generalChecklist}
        setChecklist={setGeneralChecklist}
        client={client}
        kind="generalChecklist"
        firebase={firebase}
        theme={theme}
      />
      <ChecklistContainer
        key={`${client.id}-lifeChecklist`}
        checklist={lifeChecklist}
        setChecklist={setLifeChecklist}
        client={client}
        kind="lifeChecklist"
        firebase={firebase}
        theme={theme}
      />
      <ChecklistContainer
        key={`${client.id}-productChecklist`}
        checklist={productChecklist}
        setChecklist={setProductChecklist}
        client={client}
        kind="productChecklist"
        firebase={firebase}
        theme={theme}
      />
      <ChecklistContainer
        key={`${client.id}-videoChecklist`}
        checklist={videoChecklist}
        setChecklist={setVideoChecklist}
        client={client}
        kind="videoChecklist"
        firebase={firebase}
        theme={theme}
      />
    </div>
  );
};

export default Checklists;

const ChecklistContainer = ({
  checklist,
  setChecklist,
  client,
  kind,
  firebase,
  theme,
}) => {
  const cardStyle = {
    // background: '#00000005',
    background:
      theme === "dark" ? "rgba(255, 255, 255, 0.05)" : "rgba(0, 0, 0, 0.02)",

    borderRadius: "10px",
    padding: "10px",
    margin: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    // minWidth: '200px',
    // maxWidth: '300px',
    maxHeight: "550px",
    minHeight: "300px",
  };
  const [showDelete, setShowDelete] = useState(false);
  const widthStyle = { width: "110px" };
  const handleShowDelete = () => {
    if (deleteTimeout) {
      clearTimeout(deleteTimeout);
      deleteTimeout = null;
    } else {
      deleteTimeout = setTimeout(() => {
        setShowDelete(false);
        deleteTimeout = null;
      }, 15000);
    }
    setShowDelete(!showDelete);
  };
  const handleNewChecklistItem = () => {
    setShowDelete(false);
    checklist.push("");
    setChecklist([...checklist]);
    updateFS(firebase, client.id, kind, client[kind]);
  };
  const handleDeleteChecklistItem = (item) => {
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client.id)
      .update({ [kind]: arrayRemove(item) });
  };
  const checklistButtons = [
    <Button
      style={widthStyle}
      onClick={handleNewChecklistItem}
      key={`${client.id}-${kind}-NewChecklistItem`}
    >
      New Item
    </Button>,
    <Button
      style={widthStyle}
      onClick={handleShowDelete}
      key={`${client.id}-${kind}-DeleteChecklistItem`}
    >
      Delete Item
    </Button>,
  ];
  return (
    <Tooltip title={camelToSentence(kind)} enterDelay={500}>
      <div style={cardStyle}>
        <h3>{camelToSentence(kind)}</h3>
        <ButtonGroup size="small" style={{ paddingBottom: "10px" }}>
          {checklistButtons}
        </ButtonGroup>
        <List
          style={{
            width: "35vw",
            maxWidth: "360px",
            maxHeight: "100%",
            overflow: "auto",
          }}
        >
          {checklist &&
            checklist.map((item, index) => (
              <div
                key={`${client.id}-${kind}-${index}-Cont`}
                style={{ display: "flex" }}
              >
                {showDelete ? (
                  <IconButton
                    onClick={() => {
                      handleDeleteChecklistItem(item);
                    }}
                  >
                    <DeleteIcon
                      style={{
                        color: "#ff0033",
                        width: 30,
                        height: 30,
                      }}
                    />
                  </IconButton>
                ) : null}
                <CheckListItem
                  key={`${client.id}-${kind}-${index}`}
                  index={index}
                  item={item}
                  client={client}
                  kind={kind}
                  firebase={firebase}
                />
              </div>
            ))}
        </List>
      </div>
    </Tooltip>
  );
};

const CheckListItem = ({ index, item, client, kind, firebase }) => {
  const [itemContent, setItemContent] = useState(item);

  useEffect(() => {
    setItemContent(item);
  }, [item]);

  const handleChange = (e) => {
    setItemContent(e.target.value);
    client[kind][index] = e.target.value;
    updateFS(firebase, client.id, kind, client[kind]);
  };

  return (
    <ListItem>
      <TextField
        size="small"
        variant="outlined"
        label={`Item ${index + 1}`}
        style={{ width: "100%" }}
        inputProps={{
          style: { fontSize: 14 },
        }}
        value={itemContent}
        onChange={handleChange}
      />
    </ListItem>
  );
};

function camelToSentence(str) {
  const result = str.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}
const updateFS = debounce(async (firebase, id, field, value) => {
  firebase
    .firestore()
    .collection("ClientDatabase")
    .doc(id)
    .set({ [field]: value }, { merge: true });
}, 800);
function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
