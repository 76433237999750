import React, { useState, useEffect } from "react";

// import Contacts from "./Contacts";
import Contacts from "../Contacts";
// import Models from "./Models";
import ModelsContainer from "../../ModelsContainer";
import HMUContainer from "../../HMUContainer";
// import HMUs from "./HMUs";

const People = ({ jobData, clientData, firebase }) => {
  const [models, setModels] = useState(jobData?.models);

  useEffect(() => {
    setModels(jobData?.models);
  }, [jobData]);
  return (
    <div style={{ padding: 10 }}>
      <Contacts client={clientData} firebase={firebase} />
      <ModelsContainer
        jobData={jobData}
        models={models}
        setModels={setModels}
        firebase={firebase}
      />
      <HMUContainer jobData={jobData} firebase={firebase} />
    </div>
  );
};

export default People;
