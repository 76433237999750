import React, { useState } from "react";
import {
  Button,
  AppBar,
  Toolbar,
  Typography,
  Box,
  useMediaQuery,
  Menu,
  MenuItem,
  Checkbox,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";

const FileMenu = ({
  isStaff,
  styleGuide,
  handleCreateFolders,
  rowsWithFileNameCount,
  rowsWithStillsShotCount,
  handleLockRow,
  handleUnlockRow,
  clientName,
  columnDefaults,
  handleShowColumn,
  visibleButtons,
  setVisibleButtons,
  firebase,
  jobID,
  addRows,
  clearEmptyRows,
  zoomLevel,
  setZoomLevel,
  handleMenuPrintWindow,
  isLife,
  handleCustomizeCategoriesOpen,
}) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  // isStaff = false;
  const buttonStyle = {
    fontSize: "0.7rem",
    borderRadius: 0.4,
  };

  const camelCaseToSentenceCase = (str) => {
    const result = str.replace(/([A-Z])/g, " $1");
    return result
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const hiddenColumns = Object.keys(columnDefaults).filter(
    (key) => columnDefaults[key].hidden
  );

  const [anchorElColumns, setAnchorElColumns] = useState(null);
  const [anchorElButtons, setAnchorElButtons] = useState(null);
  const [anchorElZoom, setAnchorElZoom] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rowCount, setRowCount] = useState(100);

  const handleMenuOpenColumns = (event) => {
    setAnchorElColumns(event.currentTarget);
  };

  const handleMenuCloseColumns = () => {
    setAnchorElColumns(null);
  };

  const handleMenuOpenButtons = (event) => {
    setAnchorElButtons(event.currentTarget);
  };

  const handleMenuCloseButtons = () => {
    setAnchorElButtons(null);
  };

  const handleMenuOpenZoom = (event) => {
    setAnchorElZoom(event.currentTarget);
  };

  const handleMenuCloseZoom = () => {
    setAnchorElZoom(null);
  };

  const handleZoomChange = (value) => {
    setZoomLevel(value);
    handleMenuCloseZoom();
  };

  const buttonOptions = [
    { name: "setFolder", label: "Set Folder" },
    { name: "makeFolder", label: "Make Folder" },
    { name: "makeFrontBack", label: "Make Front & Back" },
    { name: "makeFront", label: "Make Front" },
    { name: "makeBack", label: "Make Back" },
  ];

  const handleCheckboxToggle = (name) => {
    setVisibleButtons((prev) => {
      const newValue = {
        ...prev,
        [name]: !prev[name],
      };
      firebase.firestore().collection("JobData").doc(jobID).update({
        shotListVisibleButtons: newValue,
      });
      return newValue;
    });
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogConfirm = () => {
    setDialogOpen(false);
    addRows(rowCount);
    setRowCount(100);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleDialogConfirm();
    }
  };

  const zoomOptions = Array.from({ length: 13 }, (_, i) => 0.3 + i * 0.1);

  return (
    <AppBar position="static" color="default" id="menubar">
      <Toolbar
        style={{
          display: "flex",
          alignItems: "center",
          padding: 8,
          gap: 15,
          position: "relative",
        }}
      >
        <DescriptionIcon
          sx={{
            fontSize: 50,
            color: isStaff
              ? prefersDarkMode
                ? "rgba(0, 255, 0, 0.2)"
                : "rgba(0, 200, 0, 0.4)"
              : prefersDarkMode
              ? "rgba(0, 255, 255, 0.3)"
              : "rgba(0, 200, 200, 0.4)",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="h6"
            style={{
              fontSize: "1.1rem",
              marginLeft: -8,
            }}
          >
            {clientName?.toUpperCase ? `${clientName.toUpperCase()} - ` : ""}
            {styleGuide || !jobID ? "STYLE GUIDE" : "SHOTLIST"}
          </Typography>
          <Box sx={{ marginLeft: -1.8, marginTop: 0 }}>
            {isStaff && (
              <>
                {isLife ? (
                  <Button
                    color="inherit"
                    sx={buttonStyle}
                    onClick={handleCreateFolders}
                  >
                    Create Folders
                  </Button>
                ) : null}
                <Button
                  color="inherit"
                  sx={buttonStyle}
                  onClick={handleMenuOpenButtons}
                >
                  Show Buttons
                </Button>
                <Button
                  color="inherit"
                  sx={buttonStyle}
                  onClick={handleLockRow}
                >
                  Lock Rows
                </Button>
                <Button
                  color="inherit"
                  sx={buttonStyle}
                  onClick={handleUnlockRow}
                >
                  Unlock Rows
                </Button>
              </>
            )}
            <Button
              color="inherit"
              sx={buttonStyle}
              onClick={handleMenuPrintWindow}
            >
              Print
            </Button>
            <Button
              color="inherit"
              sx={buttonStyle}
              onClick={handleMenuOpenZoom}
            >
              Zoom
            </Button>
            <Menu
              anchorEl={anchorElZoom}
              open={Boolean(anchorElZoom)}
              onClose={handleMenuCloseZoom}
              disablePortal
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {zoomOptions.map((zoom) => (
                <MenuItem
                  key={zoom}
                  selected={zoom === zoomLevel}
                  onClick={() => handleZoomChange(zoom)}
                >
                  {(zoom * 100).toFixed(0)}%
                </MenuItem>
              ))}
            </Menu>
            <Button color="inherit" sx={buttonStyle} onClick={handleDialogOpen}>
              Add Rows
            </Button>
            <Button color="inherit" sx={buttonStyle} onClick={clearEmptyRows}>
              Clear Empty Rows
            </Button>

            {hiddenColumns.length > 0 && (
              <>
                <Button
                  color="inherit"
                  sx={buttonStyle}
                  onClick={handleMenuOpenColumns}
                >
                  Show Columns
                </Button>
                <Menu
                  anchorEl={anchorElColumns}
                  open={Boolean(anchorElColumns)}
                  onClose={handleMenuCloseColumns}
                >
                  {hiddenColumns.map((column) => (
                    <MenuItem
                      key={column}
                      onClick={() => {
                        handleMenuCloseColumns();
                        handleShowColumn(column);
                      }}
                    >
                      {camelCaseToSentenceCase(column)}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
            <Button
              color="inherit"
              sx={buttonStyle}
              onClick={handleCustomizeCategoriesOpen}
            >
              Customise Categories
            </Button>
            <Menu
              anchorEl={anchorElButtons}
              open={Boolean(anchorElButtons)}
              onClose={handleMenuCloseButtons}
            >
              {buttonOptions.map((option) => (
                <MenuItem
                  key={option.name}
                  onClick={() => handleCheckboxToggle(option.name)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={visibleButtons[option.name]}
                      tabIndex={-1}
                      disableRipple
                      size="small"
                      inputProps={{
                        "aria-labelledby": `${option.name}-label`,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={`${option.name}-label`}
                    primary={option.label}
                  />
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </div>

        <div
          style={{
            width: "100%",
            paddingLeft: 8,
            paddingRight: 8,
            paddingBottom: 8,
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            top: 8,
            right: 15,
            fontWeight: "bold",
          }}
        >
          Styles Shot: {rowsWithStillsShotCount}/{rowsWithFileNameCount}
        </div>
      </Toolbar>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>How many rows do you want to add?</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            type="number"
            fullWidth
            variant="standard"
            value={rowCount}
            onChange={(e) => setRowCount(Number(e.target.value))}
            onKeyDown={handleKeyDown}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogConfirm}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
};

export default FileMenu;

// const parseCategoryBackground = (category) => {
//   if (category === "N/A") return "";
//   if (category === "TOPS")
//     return prefersDarkMode
//       ? "rgba(125, 66, 64, 1)"
//       : "rgba(222, 157, 155, 1)";
//   if (category === "JACKETS")
//     return prefersDarkMode
//       ? "rgba(169, 123, 92, 1)"
//       : "rgba(241, 205, 162, 1)";
//   if (category === "DRESSES")
//     return prefersDarkMode
//       ? "rgba(121, 154, 100, 1)"
//       : "rgba(188, 214, 172, 1)";
//   if (category === "BOTTOMS")
//     return prefersDarkMode
//       ? "rgba(113, 98, 161, 1)"
//       : "rgba(178, 167, 210, 1)";
//   if (category === "ACCESSORIES")
//     return prefersDarkMode
//       ? "rgba(97, 133, 179, 1)"
//       : "rgba(166, 196, 228, 1)";
// };
