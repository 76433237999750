import React, { useState } from "react";
import FileSizeContainer from "./FileSizeContainer";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

let deleteSupplySizeTimeout;

const marginTop = 25;

const FileSizeCategory = ({
  kind,
  fileSizes,
  firebase,
  client,
  topMargin,
  theme,
}) => {
  console.log();
  const [isOpen, setIsOpen] = useState(kind === "Product" ? true : false);
  const [showDeleteSupplySize, setShowDeleteSupplySize] = useState(false);

  const style = {
    background:
      theme === "dark" ? "rgba(255, 255, 255, 0.02)" : "rgba(0, 0, 0, 0.02)",
    // width: "95%",
    maxWidth: "95%",
    minWidth: 670,
    minHeight: "40px",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    borderRadius: "6px",
    position: "relative",
    marginTop: topMargin ? `${marginTop}px` : "0px",
    right: -17,
  };
  const title = {
    paddingLeft: 10,
    cursor: "pointer",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
  };
  const buttonGroupStyle = {
    marginLeft: 10,
    paddingRight: 10,
    minWidth: "310px",
  };
  const arrowStyle = { paddingLeft: 10, cursor: "pointer" };

  const handleNewSupplySizeClick = () => {
    client.fileSizes[`${kind.toLowerCase()}`].push({
      title: "",
      width: "",
      height: "",
      dpi: "",
      backgroundColour: "",
      fileSize: "",
      fileSizeUnit: "",
      fileFormat: "",
      colourSpace: "",
    });
    updateFSNow(firebase, client.id, "fileSizes", client.fileSizes);
  };

  const handleDeleteSupplySizeClick = () => {
    if (deleteSupplySizeTimeout) {
      clearTimeout(deleteSupplySizeTimeout);
      deleteSupplySizeTimeout = null;
    } else {
      deleteSupplySizeTimeout = setTimeout(() => {
        setShowDeleteSupplySize(false);
        deleteSupplySizeTimeout = null;
      }, 15000);
    }
    setShowDeleteSupplySize(!showDeleteSupplySize);
  };
  const contactsButtons = [
    <Button
      key={`${client.id}-${kind}-NewSupplySize`}
      onClick={handleNewSupplySizeClick}
    >
      New
    </Button>,
    <Button
      key={`${client.id}-${kind}-DeleteSupplySize`}
      onClick={handleDeleteSupplySizeClick}
    >
      Delete
    </Button>,
  ];
  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div style={style}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          onClick={handleClick}
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          {isOpen ? (
            <ArrowDropDownIcon style={arrowStyle} />
          ) : (
            <ArrowRightIcon style={arrowStyle} />
          )}{" "}
          <h3 style={title}>{kind}</h3>
        </div>
        {isOpen ? (
          <div style={buttonGroupStyle}>
            <ButtonGroup size="small" aria-label="contact button group">
              {contactsButtons}
            </ButtonGroup>
          </div>
        ) : (
          <div
            style={{
              paddingRight: "40px",
              fontSize: "1.8em",
              color: theme === "dark" ? "#ffffff" : "#1f1f1f",
            }}
          >
            {fileSizes.length}
          </div>
        )}
      </div>
      {isOpen ? (
        <div
          style={{
            minHeight: "40px",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {fileSizes.map((fs, index) => (
            <FileSizeContainer
              key={`FileSizeContainer-${index}`}
              fs={fs}
              firebase={firebase}
              client={client}
              showDeleteSupplySize={showDeleteSupplySize}
              kind={kind}
              theme={theme}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default FileSizeCategory;

const updateFSNow = debounce(
  async (firebase, id, field, value) => {
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(id)
      .set({ [field]: value }, { merge: true });
  },
  0,
  true
);
// const updateFSOBJ = debounce(async (firebase, id, field, value) => {
//     firebase.firestore().collection('ClientDatabase').doc(id).set({ [field]: value }, { merge: true });
// }, 600);
function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
