import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
} from "@mui/material";
import debounce from "lodash/debounce";

import { useAtom } from "jotai";
import { isAdminAtom } from "../../-Atoms";

const CameraSettings = ({ clientData, jobData, firebase }) => {
  const [isAdmin] = useAtom(isAdminAtom);

  const [retouching, setRetouching] = useState("ITS Retouching");
  const [backgroundType, setBackgroundType] = useState("");

  // Separate state variables for Life and Product
  const [cameraModelLife, setCameraModelLife] = useState("");
  const [cameraModelProduct, setCameraModelProduct] = useState("");

  const [cameraRatioLife, setCameraRatioLife] = useState("");
  const [cameraRatioProduct, setCameraRatioProduct] = useState("");

  const [cropInCaptureLife, setCropInCaptureLife] = useState("");
  const [cropInCaptureProduct, setCropInCaptureProduct] = useState("");

  const [supplyRatioLife, setSupplyRatioLife] = useState("");
  const [supplyRatioProduct, setSupplyRatioProduct] = useState("");

  // Determine job type
  const isLife = jobData?.jobTypeFull?.includes("-L");
  const isProduct = jobData?.jobTypeFull?.includes("-P");

  useEffect(() => {
    if (clientData) {
      setRetouching(
        clientData.noRetouching === true ? "No Retouching" : "ITS Retouching"
      );

      setBackgroundType(
        clientData.backgroundType
          ? clientData.backgroundType
          : clientData.isDigitalBackground
          ? "Digital Background"
          : "Organic Background"
      );

      // Set Life and Product states separately
      setCameraModelLife(clientData.cameraModelLife || "");
      setCameraModelProduct(clientData.cameraModelProduct || "");

      setCameraRatioLife(clientData.cameraRatioLife || "");
      setCameraRatioProduct(clientData.cameraRatioProduct || "");

      setCropInCaptureLife(clientData.cropInCaptureLife || "");
      setCropInCaptureProduct(clientData.cropInCaptureProduct || "");

      setSupplyRatioLife(clientData.supplyRatioLife || "");
      setSupplyRatioProduct(clientData.supplyRatioProduct || "");
    }
  }, [clientData]);

  const updateFirestore = (field, value, variant) => {
    console.log(clientData);
    if (!clientData || !clientData.id) return;

    let updateField = field;
    let updateValue = value;

    // Adjust field based on the variant (Life/Product)
    if (variant) {
      updateField = `${field}${variant}`;
    }

    if (field === "backgroundType") {
      updateField = "isDigitalBackground";
      updateValue = value === "Digital Background";
    }

    if (field === "retouching") {
      updateField = "noRetouching";
      updateValue = value === "No Retouching";
    }

    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(clientData.id)
      .update({ [updateField]: updateValue })
      .catch((e) => console.error(e));
  };

  const handleFieldChange = (setter, field, variant) => (event) => {
    const value = event.target.value;
    setter(value);
    updateFirestore(field, value, variant);
  };

  const debouncedUpdateSupplyRatio = useCallback(
    debounce((value, variant) => {
      updateFirestore("supplyRatio", value, variant);
    }, 500),
    [clientData]
  );

  useEffect(() => {
    return () => {
      debouncedUpdateSupplyRatio.cancel();
    };
  }, [debouncedUpdateSupplyRatio]);

  const handleSupplyRatioChange = (setter, variant) => (event) => {
    const value = event.target.value;
    setter(value);
    debouncedUpdateSupplyRatio(value, variant);
  };

  return (
    <Box
      sx={{
        width: "100%",
        margin: "20px 0 0",
        paddingTop: 2.5,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "stretch",
      }}
    >
      {/* Retouching Select */}
      <FormControl
        variant="filled"
        size="small"
        sx={{ width: "100%" }}
        disabled={!isAdmin}
      >
        <InputLabel id="retouching-label">Retouching</InputLabel>
        <Select
          labelId="retouching-label"
          id="retouching"
          value={retouching}
          onChange={handleFieldChange(setRetouching, "retouching")}
          disableUnderline
        >
          <MenuItem value="ITS Retouching">ITS Retouching</MenuItem>
          <MenuItem value="No Retouching">No Retouching</MenuItem>
        </Select>
      </FormControl>

      {/* Background Type Select */}
      <FormControl
        variant="filled"
        size="small"
        sx={{ width: "100%" }}
        disabled={!isAdmin}
      >
        <InputLabel id="background-type-label">Background Type</InputLabel>
        <Select
          labelId="background-type-label"
          id="background-type"
          value={backgroundType}
          onChange={handleFieldChange(setBackgroundType, "backgroundType")}
          disableUnderline
        >
          <MenuItem value="Digital Background">Digital Background</MenuItem>
          <MenuItem value="Plated Background">Plated Background</MenuItem>
          <MenuItem value="Organic Background">Organic Background</MenuItem>
        </Select>
      </FormControl>

      <Grid container spacing={2}>
        {isLife && (
          <>
            {/* Life Camera Model */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl
                variant="filled"
                size="small"
                sx={{ width: "100%" }}
                disabled={!isAdmin}
              >
                <InputLabel id="camera-model-life-label">
                  Camera Model
                </InputLabel>
                <Select
                  labelId="camera-model-life-label"
                  id="camera-model-life"
                  value={cameraModelLife}
                  onChange={handleFieldChange(
                    setCameraModelLife,
                    "cameraModel",
                    "Life"
                  )}
                  disableUnderline
                >
                  <MenuItem value="R3">R3</MenuItem>
                  <MenuItem value="R5">R5</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Life Camera Ratio */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl
                variant="filled"
                size="small"
                sx={{ width: "100%" }}
                disabled={!isAdmin}
              >
                <InputLabel id="camera-ratio-life-label">
                  Camera Ratio
                </InputLabel>
                <Select
                  labelId="camera-ratio-life-label"
                  id="camera-ratio-life"
                  value={cameraRatioLife}
                  onChange={handleFieldChange(
                    setCameraRatioLife,
                    "cameraRatio",
                    "Life"
                  )}
                  disableUnderline
                >
                  <MenuItem value="FULL">FULL</MenuItem>
                  <MenuItem value="3x4">3x4</MenuItem>
                  <MenuItem value="1x1">1x1</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Life Supply Ratio */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                variant="filled"
                size="small"
                id="supply-ratio-life"
                label="Supply Ratio"
                disabled={!isAdmin}
                value={supplyRatioLife}
                onChange={handleSupplyRatioChange(setSupplyRatioLife, "Life")}
                sx={{
                  width: "100%",
                }}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>

            {/* Life Crop In Capture */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl
                variant="filled"
                size="small"
                sx={{ width: "100%" }}
                disabled={!isAdmin}
              >
                <InputLabel id="crop-in-capture-life-label">
                  Crop In Capture
                </InputLabel>
                <Select
                  labelId="crop-in-capture-life-label"
                  id="crop-in-capture-life"
                  value={cropInCaptureLife}
                  onChange={handleFieldChange(
                    setCropInCaptureLife,
                    "cropInCapture",
                    "Life"
                  )}
                  disableUnderline
                >
                  <MenuItem value="Camera Ratio">Camera Ratio</MenuItem>
                  <MenuItem value="Supply Ratio">Supply Ratio</MenuItem>
                  <MenuItem value="NO CROP">NO CROP</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </>
        )}

        {isProduct && (
          <>
            {/* Product Camera Model */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl
                variant="filled"
                size="small"
                sx={{ width: "100%" }}
                disabled={!isAdmin}
              >
                <InputLabel id="camera-model-product-label">
                  Camera Model
                </InputLabel>
                <Select
                  labelId="camera-model-product-label"
                  id="camera-model-product"
                  value={cameraModelProduct}
                  onChange={handleFieldChange(
                    setCameraModelProduct,
                    "cameraModel",
                    "Product"
                  )}
                  disableUnderline
                >
                  <MenuItem value="R3">R3</MenuItem>
                  <MenuItem value="R5">R5</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Product Camera Ratio */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl
                variant="filled"
                size="small"
                sx={{ width: "100%" }}
                disabled={!isAdmin}
              >
                <InputLabel id="camera-ratio-product-label">
                  Camera Ratio
                </InputLabel>
                <Select
                  labelId="camera-ratio-product-label"
                  id="camera-ratio-product"
                  value={cameraRatioProduct}
                  onChange={handleFieldChange(
                    setCameraRatioProduct,
                    "cameraRatio",
                    "Product"
                  )}
                  disableUnderline
                >
                  <MenuItem value="FULL">FULL</MenuItem>
                  <MenuItem value="3x4">3x4</MenuItem>
                  <MenuItem value="1x1">1x1</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Product Supply Ratio */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                variant="filled"
                size="small"
                id="supply-ratio-product"
                label="Supply Ratio"
                disabled={!isAdmin}
                value={supplyRatioProduct}
                onChange={handleSupplyRatioChange(
                  setSupplyRatioProduct,
                  "Product"
                )}
                sx={{
                  width: "100%",
                }}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>

            {/* Product Crop In Capture */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl
                variant="filled"
                size="small"
                sx={{ width: "100%" }}
                disabled={!isAdmin}
              >
                <InputLabel id="crop-in-capture-product-label">
                  Crop In Capture
                </InputLabel>
                <Select
                  labelId="crop-in-capture-product-label"
                  id="crop-in-capture-product"
                  value={cropInCaptureProduct}
                  onChange={handleFieldChange(
                    setCropInCaptureProduct,
                    "cropInCapture",
                    "Product"
                  )}
                  disableUnderline
                >
                  <MenuItem value="Camera Ratio">Camera Ratio</MenuItem>
                  <MenuItem value="Supply Ratio">Supply Ratio</MenuItem>
                  <MenuItem value="NO CROP">NO CROP</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default CameraSettings;
