import React, { useState, useCallback, useEffect } from "react";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import debounce from "lodash.debounce";

const AdvancedTextField = ({
  firebase,
  field,
  collectionName,
  docId,
  debounceDelay = 700,
  value,
  setValue,
  numbersOnly = false,
  setStopModalClose = () => {},
  ...props
}) => {
  // console.log(setStopModalClose)
  const [isTyping, setIsTyping] = useState(false);

  const updateDatabase = (field, value) => {
    firebase
      .firestore()
      .collection(collectionName)
      .doc(docId)
      .update({ [field]: value }, { merge: true })
      .catch((error) => {
        console.log(error);
      });
  };
// eslint-disable-next-line
  const debouncedUpdate = useCallback(
    debounce((field, value) => {
      updateDatabase(field, value);
      setIsTyping(false);
      setStopModalClose(false); // Set to false after debouncing is complete
    }, debounceDelay),
    [firebase, collectionName, docId]
  );

  const handleChange = (e) => {
    let newValue = e.target.value;
    if (numbersOnly) {
      newValue = newValue.replace(/[^0-9.]/g, "");
    }
    console.log(newValue);
    setValue(newValue);
    setIsTyping(true);
    setStopModalClose(true); // Set to true when debouncing starts
    debouncedUpdate(field, newValue);
  };

  useEffect(() => {
    return () => {
      debouncedUpdate.cancel();
    };
  }, [debouncedUpdate]);

  return (
    <TextField
      {...props}
      value={value}
      onChange={handleChange}
      InputProps={{
        ...props.InputProps,
        endAdornment: isTyping ? (
          <InputAdornment position="end">
            <CircularProgress size={20} />
          </InputAdornment>
        ) : null,
      }}
    />
  );
};

export default AdvancedTextField;
