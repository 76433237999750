import React, { useState, useEffect, useRef } from "react";

import "./emailModal.css";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import Fab from "@mui/material/Fab";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Menu from "@mui/material/Menu";
import Popover from "@mui/material/Popover";
import CancelIcon from "@mui/icons-material/Cancel";

import LoadingFullScreen from "./LoadingFullScreen";
import SuccessFullScreen from "./SuccessFullScreen";
import ErrorFullScreen from "./ErrorFullScreen";
import MDEditor, { commands } from "@uiw/react-md-editor";

import AllSupplyEmailContainer from "./Email Components/AllSupplyEmailContainer";

import { useAtom } from "jotai";
import { labelsAtom } from "../../-Atoms";

import { render } from "@react-email/components";
import DeleteIcon from '@mui/icons-material/Delete';

import getJobName from "../../GlobalFunctions/getJobName";

const { DateTime } = require("luxon");

const fetch = require("isomorphic-fetch");
const Dropbox = require("dropbox").Dropbox;
const dbx = new Dropbox({
  fetch: fetch,
  accessToken:
    "b-TxMhg7wmcAAAAAABiOl_5JiyeefA0elglApZxmxg3Uc9n7uXMt65Um5ajpJjuC",
});

const EmailModal = ({
  firebase,
  theme,
  width,
  emailKind,
  jobData,
  clientData,
  setEmailModalOpen,
  isMobile,
}) => {
  const [labels] = useAtom(labelsAtom);

  const sendEmail = firebase.functions().httpsCallable("sendEmail");

  // eslint-disable-next-line
  const [error, setError] = useState(false);
  // eslint-disable-next-line
  const [errorReason, setErrorReason] = useState(
    "I'm not sure what went wrong here..."
  );
  const [toEmails, setToEmails] = useState([]);
  const [ccEmails, setCcEmails] = useState([]);
  const [bccEmails, setBccEmails] = useState([]);
  const [replyToEmails, setReplyToEmails] = useState([]);
  const [ccVisible, setCcVisible] = useState(false);
  const [bccVisible, setBccVisible] = useState(false);
  const [subject, setSubject] = useState("");
  // eslint-disable-next-line
  const [attachments, setAttachments] = useState([]);
  const [fromEmail, setFromEmail] = useState("");
  const [selectsUrl, setSelectsUrl] = useState("");

  const [loadingVisible, setLoadingVisible] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [successVisible, setSuccessVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // const [showStoryboardsUploadScreen, setShowStoryboardsUploadScreen] =
  //   useState(false);
  // const [
  //   showStoryboardSelectsUploadScreen,
  //   setShowStoryboardSelectsUploadScreen,
  // ] = useState(false);
  // const [showQuickproofsUploadScreen, setShowQuickproofsUploadScreen] =
  //   useState(false);

  const [links, setLinks] = useState([]);

  const [linkButtons, setLinkButtons] = useState("");

  const [previewText, setPreviewText] = useState("");

  useEffect(() => {
    makeLinkButtons(links, setLinkButtons);
  }, [links]);

  const makeLinkButtons = (links, setLinkButtons) => {
    let tmp = "";
    links.forEach((l) => {
      // if (!tmp) tmp = "\n";
      tmp = `${tmp}<a href="${l.url}" data-id="react-email-button" target="_blank" style="line-height: 100%; text-decoration: none; display: inline-block; max-width: 100%; padding: 12px 20px; background-color: rgb(0, 0, 0); border-radius: 0.25rem; color: rgb(255, 255, 255); font-size: 12px; font-weight: 600; text-align: center;">
  <span style="max-width: 100%; min-width: 185px; display: inline-block; line-height: 120%;">
    <span style="text-transform: uppercase;">${l.label}</span>
  </span>
</a>
<br>
<br>`;
    });
    setLinkButtons(tmp);
  };

  useEffect(() => {
    const main = async () => {
      if (emailKind === "Video") {
        setLoadingMessage("Getting Dropbox Link");
        setLoadingVisible(true);
        const path = `/${jobData.client}/${jobData.jobNumber}-${jobData.client}${jobData.jobTypeShort}`;
        const linkObj = {
          label: "Click Here",
          url: await getDropboxLink(path),
        };
        setLinks([...links, linkObj]);
        setLoadingMessage("");
        setLoadingVisible(false);
      }
      if (emailKind === "Supply") {
        setLoadingMessage("Getting Dropbox Link");
        setLoadingVisible(true);
        const path = `/${jobData.client}/${jobData.jobNumber}-${jobData.client}${jobData.jobTypeShort}`;
        const linkObj = {
          label: "Click Here",
          url: await getDropboxLink(path),
        };
        setLinks([...links, linkObj]);
        setLoadingMessage("");
        setLoadingVisible(false);
      }
      if (emailKind === "Storyboards") {
        setLoadingMessage("Getting Storyboards Dropbox Link");
        setLoadingVisible(true);
        const basePath = `/${jobData.client}/${jobData.jobNumber}-${
          jobData.client
        }${jobData.jobTypeShort}${
          jobData.multipleDays ? `/${jobData.multipleDays}` : ``
        }`;

        // getDropboxLink(`${basePath}/Storyboards`, () => {});
        // getDropboxLink(`${basePath}/Storyboard Selects`, () => {});

        const storyboardsUrl = await getDropboxLink(
          `${basePath}/Storyboards`,
          noop()
          // setShowStoryboardsUploadScreen
        );

        const linkObj = {
          label: "Storyboards",
          url: storyboardsUrl,
        };

        const storyboardSelectsUrl = await getDropboxLink(
          `${basePath}/Storyboard Selects`,
          noop()
          // setShowStoryboardSelectsUploadScreen
        );
        const linkObj1 = {
          label: "Storyboard Selects",
          url: storyboardSelectsUrl,
        };

        setLinks([...links, linkObj, linkObj1]);

        setLoadingMessage("");
        setLoadingVisible(false);
      }
      if (emailKind === "Storyboards & Quickproofs") {
        setLoadingMessage("Getting Storyboards Dropbox Link");
        setLoadingVisible(true);
        const basePath = `/${jobData.client}/${jobData.jobNumber}-${
          jobData.client
        }${jobData.jobTypeShort}${
          jobData.multipleDays ? `/${jobData.multipleDays}` : ``
        }`;

        // getDropboxLink(`${basePath}/Storyboards`, () => {});
        // getDropboxLink(`${basePath}/Storyboard Selects`, () => {});
        // getDropboxLink(`${basePath}/Quickproofs`, () => {});

        const storyboards = await getDropboxLink(
          `${basePath}/Storyboards`,
          noop() // setShowStoryboardsUploadScreen
        );
        const linkObj1 = {
          label: "Storyboards",
          url: storyboards,
        };

        const storyboardSelectsUrl = await getDropboxLink(
          `${basePath}/Storyboard Selects`,
          noop()
          // setShowStoryboardSelectsUploadScreen
        );
        const linkObj3 = {
          label: "Storyboard Selects",
          url: storyboardSelectsUrl,
        };

        setLoadingMessage("Getting Quickproofs Dropbox Link");

        const quickproofs = await getDropboxLink(
          `${basePath}/Quickproofs`,
          noop()
          // setShowQuickproofsUploadScreen
        );
        const linkObj2 = {
          label: "Quickproofs",
          url: quickproofs,
        };

        setLinks([...links, linkObj1, linkObj3, linkObj2]);

        setLoadingMessage("");
        setLoadingVisible(false);
      }
      if (emailKind === "Storyboards, Quickproofs & Selects") {
        setLoadingMessage("Getting Storyboards Dropbox Link");
        setLoadingVisible(true);
        const basePath = `/${jobData.client}/${jobData.jobNumber}-${
          jobData.client
        }${jobData.jobTypeShort}${
          jobData.multipleDays ? `/${jobData.multipleDays}` : ``
        }`;
        // getDropboxLink(`${basePath}/Storyboards`, () => {});
        // getDropboxLink(`${basePath}/Storyboard Selects`, () => {});
        // getDropboxLink(`${basePath}/Quickproofs`, () => {});

        getDropboxFileRequest(
          `${basePath}/Final Client Selects`,
          getJobName(jobData)
        );

        const storyboards = await getDropboxLink(
          `${basePath}/Storyboards`,
          noop()
          // setShowStoryboardsUploadScreen
        );
        const linkObj1 = {
          label: "Storyboards",
          url: storyboards,
        };

        const storyboardSelectsUrl = await getDropboxLink(
          `${basePath}/Storyboard Selects`,
          noop()
          // setShowStoryboardSelectsUploadScreen
        );
        const linkObj3 = {
          label: "Storyboard Selects",
          url: storyboardSelectsUrl,
        };

        setLoadingMessage("Getting Quickproofs Dropbox Link");

        const quickproofs = await getDropboxLink(
          `${basePath}/Quickproofs`,
          noop()
          // setShowQuickproofsUploadScreen
        );
        const linkObj2 = {
          label: "Quickproofs",
          url: quickproofs,
        };

        setLinks([...links, linkObj1, linkObj3, linkObj2]);

        setLoadingMessage("Getting Selects Dropbox Link");

        const selects = await getDropboxFileRequest(
          `${basePath}/Final Client Selects`,
          getJobName(jobData)
        );
        setSelectsUrl(selects);

        setLoadingMessage("");
        setLoadingVisible(false);
      }
    };
    main();
    // eslint-disable-next-line
  }, []);

  const emailContainerMainStyle = {
    width: width,
    zIndex: 100,
  };
  const emailContainerStyle = {
    // width: "100%",
    margin: 15,
    marginTop: 20,
    position: "relative",
  };
  const toggleCC = () => setCcVisible(!ccVisible);
  const toggleBCC = () => setBccVisible(!bccVisible);
  const getEmailsFromClientObjectArray = (arr) => {
    let tmpArr = [];

    arr.forEach((c) => {
      if (c) {
        if (c.email) {
          tmpArr.push(c.email);
        }
      }
    });
    return tmpArr;
  };

  const parseJobIndex = (kind) => {
    return parseInt(kind.replace(/Outsourcers.*-/, ""));
  };

  const [, setJobIndex] = useState(0);
  const [, setJobLetter] = useState("");

  // const [jobIndex, setJobIndex] = useState(0);
  // const [jobLetter, setJobLetter] = useState("");

  const [thisJobsData, setThisJobsData] = useState({});
  const [outsourcerData, setOutsourcerData] = useState({});
  const addOrdinalSuffix = (n) => {
    var s = ["th", "st", "nd", "rd"];
    var v = n % 100;
    return s[(v - 20) % 10] || s[v] || s[0];
  };
  const parseDueDate = () => {
    if (!thisJobsData.dueDate) return "7am Sydney Time";
    const dateObject = DateTime.fromISO(thisJobsData.dueDate).setZone(
      "Australia/Sydney"
    );
    const formattedDay = addOrdinalSuffix(dateObject.day);
    const formattedDate = dateObject.toFormat(`EEEE d'${formattedDay} 'MMMM`);
    return `${formattedDate}, 7am Sydney Time`;
  };
  const parseLineItems = () => {
    let linesData = "";
    thisJobsData?.lineItems?.forEach((line, index) => {
      let tmp;
      if (outsourcerData.companyName === "OMMS") {
        tmp = `${index + 1}. ${line.type} = ${line.images}\n\n`;
      } else {
        tmp = `${index + 1}. ${line.type} = ${line.images} @ $${
          line.price
        }\n\n`;
      }
      linesData = linesData + tmp;
    });
    // console.log(linesData);
    return linesData;
  };

  const parseTotal = () => {
    let total = 0;
    thisJobsData?.lineItems?.forEach((line, index) => {
      let linePrice = parseInt(line.images) * parseFloat(line.price);
      total = total + linePrice;
    });
    return `$${parseFloat(total).toFixed(2)} AUD`;
  };

  useEffect(() => {
    const init = async () => {
      if (emailKind === "Video") {
        setFromEmail("production@imagesthatsell.com.au");
        setReplyToEmails([{ email: "production@imagesthatsell.com.au" }]);
        setSubject(getJobName(jobData));
        setToEmails([{ email: "adam@imagesthatsell.com.au" }]);
        setCcEmails([
          { email: "alex@imagesthatsell.com.au" },
          { email: "amy@imagesthatsell.com.au" },
          { email: "production@imagesthatsell.com.au" },
        ]);
      }

      if (emailKind === "Supply" || emailKind === "COD") {
        setFromEmail("production@imagesthatsell.com.au");
        if (jobData.client !== "Morrissey Media") {
          setCcEmails([{ email: "accounts@imagesthatsell.com.au" }]);
        }
        setReplyToEmails([{ email: "production@imagesthatsell.com.au" }]);
        setSubject(getJobName(jobData));
        setToEmails(
          // eslint-disable-next-line
          clientData.contacts.map((contact) => {
            if (contact.isSupply)
              return { name: contact.name, email: contact.email };
          })
        );
      }
      if (emailKind.includes("Outsourcers")) {
        let CCEmails = [];
        // let CCEmails = [{ email: "georgia@imagesthatsell.com.au" }];
        setFromEmail("production@imagesthatsell.com.au");
        setReplyToEmails([{ email: "production@imagesthatsell.com.au" }]);
        const jobIndexTmp = parseJobIndex(emailKind);
        const jobLetterTmp = numToLetter(jobIndexTmp + 1);
        const thisJobsDataTmp = jobData.outsourcers[jobIndexTmp];
        setJobIndex(jobIndexTmp);
        setJobLetter(jobLetterTmp);
        setThisJobsData(thisJobsDataTmp);

        const outsourcerDataRaw = await firebase
          .firestore()
          .collection("Outsourcers")
          .where("companyName", "==", thisJobsDataTmp.outsourcer)
          .get();
        const outsourcerDataTmp = outsourcerDataRaw.docs[0].data();
        setOutsourcerData(outsourcerDataTmp);

        if (jobLetterTmp === "A") setSubject(getJobName(jobData));
        else setSubject(`${getJobName(jobData)}-${jobLetterTmp}`);

        // setToEmails([{ email: "alexmorrisseysmith@gmail.com" }]);
        setToEmails([{ email: outsourcerDataTmp.mainEmail }]);
        outsourcerDataTmp.ccEmails.forEach((cc) => {
          CCEmails.push({ email: cc });
        });
        setCcEmails([...CCEmails]);

        const linkObj = {
          label: "Retouching Notes",
          url: `https://staff.imagesthatsell.com.au/outsourcerjob/${jobData.id}`,
        };
        setLinks([linkObj]);
      }
      if (
        emailKind === "Storyboards" ||
        emailKind === "Storyboards & Quickproofs" ||
        emailKind === "Storyboards, Quickproofs & Selects"
      ) {
        setFromEmail("production@imagesthatsell.com.au");
        if (jobData.client !== "Morrissey Media") {
          setCcEmails([
            { email: "jobs@imagesthatsell.com.au" },
            { email: "bookings@imagesthatsell.com.au" },
          ]);
        }
        setReplyToEmails([{ email: "production@imagesthatsell.com.au" }]);
        setSubject(getJobName(jobData));
        setToEmails(
          // eslint-disable-next-line
          clientData.contacts.map((contact) => {
            if (contact.isSupply)
              return { name: contact.name, email: contact.email };
          })
        );
      }
    };
    init();
    // eslint-disable-next-line
  }, [emailKind]);

  const [addLinkButtonMenu, setAddLinkButtonMenu] = useState(null);
  const handleLinkButtonMenuClick = (event) => {
    setAddLinkButtonMenu(event.currentTarget);
  };
  const handleLinkButtonMenuClose = () => {
    setAddLinkButtonMenu(null);
  };

  const [newLinkLabel, setNewLinkLabel] = useState("");
  const [newLinkURL, setNewLinkURL] = useState("");
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [emailMarkdown, setEmailMarkdown] = useState(``);

  const [markdownEditorOpen, setMarkdownEditorOpen] = useState(null);
  const handleMarkdownEditorClick = (event) => {
    if (!markdownEditorOpen) {
      setMarkdownEditorOpen(event.currentTarget);
    } else {
      handleMarkdownEditorClose();
    }
  };

  const handleMarkdownEditorClose = () => {
    setMarkdownEditorOpen(null);
  };

  useEffect(() => {
    if (emailKind === "Supply") {
      setPreviewText("Your files are complete and ready for download.");
      setEmailMarkdown(`# THANK YOU SO MUCH
#### WE REALLY APPRECIATE YOUR BUSINESS

Your latest job is complete:
### ${subject}

The files are currently uploading to Dropbox and should be available for download within the next hour.
Please find the direct link below:


{{links}}


Let us know if you have any trouble locating the files or if you have any questions at all.`);
    } else if (emailKind === "COD") {
      setPreviewText("Please send through remittance for immediate supply.");
      setEmailMarkdown(`# YOUR FILES ARE READY!
Your latest job is complete:
### ${subject}

Accounts will supply you with your invoice shortly.

For immediate file supply please email a remittance to production@imagesthatsell.com.au.`);
    } else if (emailKind === "Video") {
      setPreviewText(subject);
      setEmailMarkdown(`Hi Adam,


We did ${
        jobData?.imageBreakdown?.find((obj) =>
          obj.description.includes("Videos")
        )?.quantityProcessed || 0
      } videos for the **${subject}** job today,

${
  jobData?.imageBreakdown?.find((obj) => obj.description.includes("Videos"))
    ?.filesInPost || 0
} of ${
        jobData?.imageBreakdown?.find((obj) =>
          obj.description.includes("Videos")
        )?.quantityProcessed || 0
      } have been edited.


It should take **REPLACE ME** hours to complete the edits.


{{links}}


Thanks,
      
${
  firebase.auth().currentUser.staffData.preferredName
    ? firebase.auth().currentUser.staffData.preferredName
    : firebase.auth().currentUser.staffData.firstName
}
      `);
    } else if (
      emailKind.includes("Outsourcers") &&
      emailKind.includes("includeRetouchingNotes")
    ) {
      setPreviewText("Your next job with ITS is ready to download.");
      setEmailMarkdown(`Hi ${outsourcerData.contactName},


Our latest job is: **${subject}**


Please follow all instructions, these can be found either:

**Inside the job folder**

**Written on the images**

**In the retouching notes below**


<colour #F23620>**Please see below the retouching notes for this job, check them off as you go along.**</colour>


<colour #F23620>**If there is a little image icon next to the note, click it to open a reference image for that note.**</colour>


{{links}}


IMAGES:

${parseLineItems()}


Job Total: ${parseTotal()}


Deadline: ${parseDueDate()}


Thanks,

${thisJobsData.staff}


`);
    } else if (
      emailKind.includes("Outsourcers") &&
      emailKind.includes("noRetouchingNotes")
    ) {
      setPreviewText("Your next job with ITS is ready to download.");
      setEmailMarkdown(`Hi ${outsourcerData.contactName},


Our latest job is: **${subject}**


Please follow all instructions, these can be found either:

**Inside the job folder**

**Written on the images**


IMAGES:

${parseLineItems()}


Job Total: ${parseTotal()}


Deadline: ${parseDueDate()}


Thanks,

${thisJobsData.staff}


`);
    } else if (
      emailKind === "Storyboards" ||
      emailKind === "Storyboards & Quickproofs" ||
      emailKind === "Storyboards, Quickproofs & Selects"
    ) {
      setPreviewText("Your Storyboards are ready.");
      if (jobData.noRetouching) {
        setEmailMarkdown(`### YOUR STORYBOARDS ARE READY!

The storyboards from your latest job are ready:
### ${subject}

We have been instructed that this job does not require our post production service.


Therefore we will supply your files based on the specifications you have outlined with the team.


{{links}}


`);
      } else {
        let extraText = "";
        if (jobData.dayType) {
          if (jobData.dayType === "Full Day") {
            if (jobData.imagesProcessedNumbers > clientData.fullDayRate.images)
              extraText = `out of an allocated **${clientData.fullDayRate.images} images**


<colour #F23620>Addtional files above your allocated file count are billed at $15++ per file. Please let us know if you require a quote prior to proceeding otherwise the post prodctuion will proceed.</colour>`;
          } else if (jobData.dayType === "Half Day") {
            if (jobData.imagesProcessedNumbers > clientData.halfDayRate.images)
              extraText = `of an allocated **${clientData.halfDayRate.images} images**


<colour #F23620>Addtional files above your allocated file count are billed at $15++ per file. Please let us know if you require a quote prior to proceeding otherwise the post prodctuion will proceed.</colour>`;
          }
        }
        setEmailMarkdown(`### YOUR STORYBOARDS ARE READY!
        
The storyboards from your latest job are ready:
### ${getJobName(jobData)}

${
  jobData.imagesProcessedNumbers
    ? `The file count for this shoot is: 

**${jobData.imagesProcessedNumbers} images** ${extraText}

`
    : ""
}
This email is confirmation that you are happy to proceed with all of the files in the Storyboard.
Therefore, post production will now begin working on your files in order to meet your deadline.


Please email [production@imagesthatsell.com.au](mailto:production@imagesthatsell.com.au) immediately if you wish to reselect images or reduce the shot count.


Reselecting or adding images will put your job on hold until the selects are confirmed.
Your job going on hold will affect the final supply date. The post production team will inform you of the updated due date.


Fees apply.


{{links}}


${
  emailKind.includes("Selects")
    ? ""
    : "If you have any questions, please let us know."
}`);
      }
    }
    // eslint-disable-next-line
  }, [emailKind, subject]);
  return (<>
    {error ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItem: "center",
          flexDirection: "column",
          padding: "50px",
        }}
      >
        <p>An error occurred</p>
        <p>{errorReason}</p>
      </div>
    ) : (
      <>
        <LoadingFullScreen
          loadingVisible={loadingVisible}
          loadingMessage={loadingMessage}
          theme={theme}
        />
        <SuccessFullScreen successVisible={successVisible} theme={theme} />
        <ErrorFullScreen
          errorVisible={errorVisible}
          errorMessage={errorMessage}
          theme={theme}
        />
        {/* <UploadStoryboardsContainer
          showStoryboardsUploadScreen={showStoryboardsUploadScreen}
          setShowStoryboardsUploadScreen={setShowStoryboardsUploadScreen}
          theme={theme}
          jobData={jobData}
        />
        <UploadStoryboardSelectsContainer
          showStoryboardSelectsUploadScreen={
            showStoryboardSelectsUploadScreen
          }
          setShowStoryboardSelectsUploadScreen={
            setShowStoryboardSelectsUploadScreen
          }
          theme={theme}
          jobData={jobData}
        />
        <UploadQuickproofsContainer
          showQuickproofsUploadScreen={showQuickproofsUploadScreen}
          setShowQuickproofsUploadScreen={setShowQuickproofsUploadScreen}
          theme={theme}
          jobData={jobData}
        /> */}

        <Menu
          anchorEl={addLinkButtonMenu}
          open={Boolean(addLinkButtonMenu)}
          onClose={handleLinkButtonMenuClose}
        >
          <div
            style={{
              padding: 15,
              width: 320,
            }}
          >
            {links &&
              links.map((link, index) => (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <TextField
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        label="Label"
                        size="small"
                        sx={{ margin: 1 }}
                        fullWidth={true}
                        inputProps={{ style: { fontSize: 12 } }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        value={link.label}
                        onChange={(e) => {
                          links[index].label = e.target.value;
                          setLinks([...links]);
                        }}
                      />
                      <TextField
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        label="URL"
                        size="small"
                        sx={{ margin: 1 }}
                        fullWidth={true}
                        inputProps={{ style: { fontSize: 12 } }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        value={link.url}
                        onChange={(e) => {
                          links[index].url = e.target.value;
                          setLinks([...links]);
                        }}
                      />
                    </div>
                    <IconButton
                      onClick={() => {
                        links.splice(index, 1);
                        setLinks([...links]);
                      }}
                      sx={{ marginLeft: 2 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                  <Divider sx={{ marginBottom: 1.5, marginTop: 1.5 }} />
                </>
              ))}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextField
                variant="filled"
                InputProps={{ disableUnderline: true }}
                label="Label"
                size="small"
                sx={{ margin: 1 }}
                value={newLinkLabel}
                onChange={(e) => {
                  setNewLinkLabel(e.target.value);
                }}
              />
              <TextField
                variant="filled"
                InputProps={{ disableUnderline: true }}
                label="URL"
                size="small"
                sx={{ margin: 1 }}
                value={newLinkURL}
                onChange={(e) => {
                  setNewLinkURL(e.target.value);
                }}
              />
              <Button
                size="small"
                variant="contained"
                sx={{ margin: 1 }}
                onClick={() => {
                  links.push({
                    label: newLinkLabel,
                    url: newLinkURL,
                  });
                  handleLinkButtonMenuClose();
                  setLinks(links);
                  makeLinkButtons(links, setLinkButtons);
                  forceUpdate();
                  setNewLinkLabel("");
                  setNewLinkURL("");
                }}
              >
                Add Link
              </Button>
              <Divider sx={{ marginBottom: 1.5, marginTop: 1.5 }} />

              <Button
                size="small"
                variant="contained"
                sx={{ margin: 1 }}
                color="error"
                onClick={handleLinkButtonMenuClose}
              >
                Close
              </Button>
            </div>
          </div>
        </Menu>
        <div
          style={{
            background: theme === "dark" ? "#1f1f1f" : "#ffffff",
            height: "100%",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <div style={emailContainerMainStyle}>
            <div style={emailContainerStyle}>
              <EmailField
                emails={toEmails}
                setEmails={setToEmails}
                kind={"To"}
                visible={true}
                marginRight={20}
              />
              <Divider />
              <EmailField
                emails={ccEmails}
                setEmails={setCcEmails}
                kind={"Cc"}
                visible={ccVisible}
                marginRight={0}
              />
              <Divider style={{ display: ccVisible ? "flex" : "none" }} />
              <EmailField
                emails={bccEmails}
                setEmails={setBccEmails}
                kind={"Bcc"}
                visible={bccVisible}
                marginRight={0}
              />
              <Divider style={{ display: bccVisible ? "flex" : "none" }} />
              <div
                style={{
                  width: "100%",
                  marginTop: 5,
                  marginBottom: 5,
                  display: "flex",
                  justifyContent: "space-between",
                  // justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      marginTop: 5,
                      marginBottom: 5,
                      marginRight: 10,
                      fontSize: "0.8em",
                    }}
                  >
                    Subject:
                  </p>
                  <TextField
                    size="small"
                    variant="standard"
                    sx={{
                      width: "100%",
                    }}
                    inputProps={{
                      sx: {
                        marginTop: "5px",
                        height: 20,
                        fontSize: "14px",
                      },
                    }}
                    InputProps={{ disableUnderline: true }}
                    value={subject || ""}
                    onChange={(e) => {
                      setSubject(e.target.value);
                    }}
                  />
                </div>
                <ButtonGroup
                  size="small"
                  style={{ height: 24, marginRight: 25 }}
                >
                  <Button
                    variant={ccVisible ? "contained" : "outlined"}
                    onClick={toggleCC}
                  >
                    CC
                  </Button>
                  <Button
                    variant={bccVisible ? "contained" : "outlined"}
                    onClick={toggleBCC}
                  >
                    BCC
                  </Button>
                </ButtonGroup>
              </div>
              <Divider />
              <EmailField
                emails={replyToEmails}
                setEmails={setReplyToEmails}
                kind={"Reply To"}
                visible={true}
                marginRight={0}
              />
              <Divider />
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                <p
                  style={{
                    marginTop: 5,
                    marginBottom: 5,
                    marginRight: 10,
                    fontSize: "0.8em",
                  }}
                >
                  From:
                </p>
                <TextField
                  size="small"
                  variant="standard"
                  inputProps={{
                    sx: {
                      marginTop: "5px",
                      height: 20,
                      width: 320,
                      fontSize: "14px",
                    },
                  }}
                  InputProps={{ disableUnderline: true }}
                  value={fromEmail || ""}
                  onChange={(e) => {
                    setFromEmail(e.target.value);
                  }}
                />
              </div>
              <Divider /> */}
            </div>
          </div>
          <Fab
            color="primary"
            style={{
              width: "50px",
              height: "50px",
              zIndex: 200,
              position: "absolute",
              bottom: 14,
              right: 14,
            }}
            onClick={() => {
              let emailComponent = (
                <AllSupplyEmailContainer
                  jobData={jobData}
                  editMode={true}
                  emailMarkdown={emailMarkdown}
                  setEmailMarkdown={setEmailMarkdown}
                  markdownEditorOpen={markdownEditorOpen}
                  handleMarkdownEditorClose={handleMarkdownEditorClose}
                  handleMarkdownEditorClick={handleMarkdownEditorClick}
                  Popover={Popover}
                  links={links}
                  linkButtons={linkButtons}
                  selectsUrl={selectsUrl}
                  previewText={previewText}
                  isMobile={isMobile}
                  emailKind={emailKind}
                />
              );

              if (emailKind === "Supply") {
                addLabelToCardFB(
                  firebase,
                  jobData,
                  "5e6881d6af988c41f2bf6e12"
                );
              }
              if (emailKind === "COD") {
                addLabelToCardFB(
                  firebase,
                  jobData,
                  "5f0e8644f6ee37295e76981e"
                );
              }
              if (emailKind === "Video Email") {
                addLabelToCardFB(
                  firebase,
                  jobData,
                  "482f6fba-69a6-4103-9c75-1eabcdffa6d5"
                );
              }
              if (emailKind === "Storyboards") {
                addLabelToCardFB(
                  firebase,
                  jobData,
                  "c9324864-f503-4267-a539-2c5accd94f7f"
                );
              }
              if (emailKind === "Storyboards & Quickproofs") {
                addLabelToCardFB(
                  firebase,
                  jobData,
                  "6c39be8a-a4dc-4fd6-880e-dd940849cb1a"
                );
              }
              if (emailKind === "Storyboards, Quickproofs & Selects") {
                addLabelToCardFB(
                  firebase,
                  jobData,
                  "1f990729-d969-49d6-b61c-e53b33b8fc43"
                );
              }
              if (emailKind.includes("Outsourcers")) {
                const jobIndexTmp = parseJobIndex(emailKind);
                let labelToAdd = "";
                labels.forEach((label) => {
                  if (
                    label.name.toLowerCase() ===
                    jobData.outsourcers[jobIndexTmp].outsourcer.toLowerCase()
                  ) {
                    labelToAdd = label.id;
                  }
                });
                addLabelToCardFB(firebase, jobData, labelToAdd);

                jobData.outsourcers[jobIndexTmp].emailSent = true;
                jobData.outsourcers[jobIndexTmp].sentDate = DateTime.now()
                  .setZone("Australia/Sydney")
                  .toUTC()
                  .toISO();
                firebase
                  .firestore()
                  .collection("JobData")
                  .doc(jobData.id)
                  .update(
                    { outsourcers: jobData.outsourcers },
                    { merge: true }
                  )
                  .catch((error) => {
                    console.log(error);
                  });
              }

              const emailHtml = render(emailComponent);
              setLoadingVisible(true);
              sendEmail({
                emailBody: emailHtml,
                fromEmail,
                toEmails: getEmailsFromClientObjectArray(toEmails),
                ccEmails: getEmailsFromClientObjectArray(ccEmails),
                bccEmails: getEmailsFromClientObjectArray(bccEmails),
                replyToEmails: getEmailsFromClientObjectArray(replyToEmails),
                subject,
                attachments,
              }).then((res) => {
                if (res.data.error) {
                  setLoadingVisible(false);
                  setErrorMessage(res.data.errortext);
                  setErrorVisible(true);
                } else {
                  setLoadingVisible(false);
                  setSuccessVisible(true);
                  setEmailModalOpen(false);
                }
              });
            }}
          >
            <SendIcon
              sx={{ marginLeft: "4px", width: "30px", height: "30px" }}
            />
          </Fab>
          <div
            style={{
              width: "100%",
              height: "72vh",
              marginTop: 0,
              position: "relative",
            }}
          >
            <div
              style={{
                margin: 5,
                display: "flex",
                justifyContent: "center",
                // width: "100%",
              }}
            >
              <ButtonGroup size="small">
                <Button
                  variant={markdownEditorOpen ? "contained" : "outlined"}
                  onClick={handleMarkdownEditorClick}
                >
                  Edit Email
                </Button>
                <Button
                  variant="contained"
                  onClick={handleLinkButtonMenuClick}
                >
                  Edit Links
                </Button>
              </ButtonGroup>
            </div>

            <div
              data-color-mode="light"
              style={{
                display: markdownEditorOpen ? "block" : "none",
                width: "100%",
                // maxWidth: isMobile ? "90vw" : "40vw",
                border: "1px solid grey",
                marginBottom: 5,
              }}
            >
              <MDEditor
                preview="edit"
                commands={[
                  commands.bold,
                  commands.italic,
                  commands.strikethrough,
                  commands.group(
                    [
                      commands.title1,
                      commands.title2,
                      commands.title3,
                      commands.title4,
                      commands.title5,
                      commands.title6,
                    ],
                    {
                      name: "title",
                      groupName: "title",
                      buttonProps: { "aria-label": "Insert title" },
                    }
                  ),
                  commands.group([], {
                    name: "Colour",
                    groupName: "Colour",
                    icon: (
                      <svg width="12px" height="12px" viewBox="0 0 16 16">
                        <g id="surface1">
                          <path
                            fill="currentColor"
                            style={{
                              transform:
                                "scale(1.4) translateX(-2px) translateY(-2px)",
                            }}
                            d="M 3.332031 12 L 12.667969 12 L 12.667969 14 L 3.332031 14 Z M 8.332031 2.667969 L 7.667969 2.667969 C 7.398438 2.667969 7.15625 2.828125 7.050781 3.074219 L 3.828125 10.667969 L 5.277344 10.667969 L 6.128906 8.667969 L 9.863281 8.667969 L 10.707031 10.667969 L 12.15625 10.667969 L 8.949219 3.074219 C 8.84375 2.828125 8.601562 2.667969 8.332031 2.667969 Z M 6.691406 7.332031 L 8 4.257812 L 9.300781 7.332031 Z M 6.691406 7.332031 "
                          />
                        </g>
                      </svg>
                    ),
                    children: ({ close, execute, getState, textApi }) => {
                      const setColour = (hex) => {
                        const state = getState();
                        const api = textApi;
                        const startPos = state.selection.start;
                        // const endPos = state.selection.end;
                        const text = state.text;
                        const replacementText = `<colour ${hex}>${state.selectedText}</colour>`;

                        const startSub = text.substring(0, startPos);
                        // const endSub = text.substring(endPos);
                        // const newText = `${startSub}${replacementText}${endSub}`;

                        api.replaceSelection(replacementText);
                        api.setSelectionRange({
                          start:
                            startSub.length +
                            replacementText.indexOf(state.selectedText),
                          end:
                            startSub.length +
                            replacementText.indexOf(state.selectedText) +
                            state.selectedText.length,
                        });
                        close();
                      };

                      const resetText = () => {
                        const state = getState();
                        const api = textApi;

                        const replacementText = state.text.replace(
                          /(<colou?r (#[A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})>)(.*?)(<\/colou?r>)/gm,
                          state.selectedText
                        );
                        api.setSelectionRange({
                          start: 0,
                          end: textApi.textArea.value.length,
                        });
                        api.replaceSelection(replacementText);
                        close();
                      };

                      const buttonStyle = {
                        width: 16,
                        height: 16,
                        cursor: "pointer",
                        margin: 0.5,
                      };

                      const greyArray = [
                        "#FFFFFF",
                        "#E8E8E8",
                        "#D1D1D1",
                        "#BABABA",
                        "#A2A2A2",
                        "#8B8B8B",
                        "#747474",
                        "#5D5D5D",
                        "#454545",
                        "#2F2F2F",
                        "#171717",
                        "#000000",
                      ];

                      const colourArrays = {
                        redArray: [
                          "#300402",
                          "#600F07",
                          "#911D0E",
                          "#C12917",
                          "#F23620",
                          "#F14A3D",
                          "#F4716A",
                          "#F69E9C",
                          "#FACECD",
                        ],
                        orangeArray: [
                          "#311B05",
                          "#63350E",
                          "#935019",
                          "#C46C25",
                          "#F4852F",
                          "#F79D49",
                          "#F9B571",
                          "#FACD9F",
                          "#FDE5CF",
                        ],
                        yellowArray: [
                          "#333209",
                          "#67651B",
                          "#9A972D",
                          "#CDCA40",
                          "#FFFC52",
                          "#FFFD5F",
                          "#FFFD7E",
                          "#FFFDA4",
                          "#FFFED2",
                        ],
                        limeArray: [
                          "#1F3208",
                          "#3D6319",
                          "#5C952A",
                          "#7AC63B",
                          "#99F94C",
                          "#ACFA5B",
                          "#BFFA7B",
                          "#D5FCA4",
                          "#E9FDD0",
                        ],
                        greenArray: [
                          "#0E3108",
                          "#246319",
                          "#3A9529",
                          "#50C63A",
                          "#65F84B",
                          "#6FF85A",
                          "#88F97A",
                          "#ABFAA3",
                          "#D4FCD1",
                        ],
                        forrestArray: [
                          "#0D321C",
                          "#236338",
                          "#399554",
                          "#4EC771",
                          "#64F98C",
                          "#6DF9A1",
                          "#87FAB9",
                          "#AAFBCF",
                          "#D4FDE7",
                        ],
                        aquaArray: [
                          "#0C3232",
                          "#216465",
                          "#369698",
                          "#4AC9CB",
                          "#5FFBFD",
                          "#69FBFE",
                          "#84FBFE",
                          "#A9FCFE",
                          "#D3FDFE",
                        ],
                        skyArray: [
                          "#021931",
                          "#073462",
                          "#0D4E94",
                          "#1468C6",
                          "#1B83F7",
                          "#409AF8",
                          "#70B3F9",
                          "#9ECBFB",
                          "#CEE5FD",
                        ],
                        blueArray: [
                          "#000230",
                          "#000A61",
                          "#001493",
                          "#001FC3",
                          "#002AF5",
                          "#2342F6",
                          "#606DF7",
                          "#969CF9",
                          "#CBCDFB",
                        ],
                        purpleArray: [
                          "#170430",
                          "#2E0E62",
                          "#441993",
                          "#5C25C4",
                          "#7331F6",
                          "#8E48F6",
                          "#A871F6",
                          "#C59FF9",
                          "#E1CEFC",
                        ],
                        magentaArray: [
                          "#2F0731",
                          "#5F1663",
                          "#902694",
                          "#BF36C5",
                          "#F045F7",
                          "#EF55F7",
                          "#F277F8",
                          "#F5A2FA",
                          "#F9CFFC",
                        ],
                        pinkArray: [
                          "#300518",
                          "#601132",
                          "#911F4C",
                          "#C12C65",
                          "#F13A7F",
                          "#F14D97",
                          "#F373B0",
                          "#F6A0CA",
                          "#FACFE4",
                        ],
                      };

                      const newShade = (hexColor, magnitude) => {
                        hexColor = hexColor.replace(`#`, ``);
                        if (hexColor.length === 6) {
                          const decimalColor = parseInt(hexColor, 16);
                          let r = (decimalColor >> 16) + magnitude;
                          r > 255 && (r = 255);
                          r < 0 && (r = 0);
                          let g = (decimalColor & 0x0000ff) + magnitude;
                          g > 255 && (g = 255);
                          g < 0 && (g = 0);
                          let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
                          b > 255 && (b = 255);
                          b < 0 && (b = 0);
                          return `#${(g | (b << 8) | (r << 16)).toString(
                            16
                          )}`;
                        } else {
                          return hexColor;
                        }
                      };

                      function hexToRgb(hex) {
                        var shorthandRegex =
                          /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
                        hex = hex.replace(
                          shorthandRegex,
                          function (m, r, g, b) {
                            return r + r + g + g + b + b;
                          }
                        );

                        var result =
                          /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
                            hex
                          );
                        return result
                          ? {
                              r: parseInt(result[1], 16),
                              g: parseInt(result[2], 16),
                              b: parseInt(result[3], 16),
                            }
                          : null;
                      }
                      function luminance(r, g, b) {
                        var a = [r, g, b].map(function (v) {
                          v /= 255;
                          return v <= 0.03928
                            ? v / 12.92
                            : Math.pow((v + 0.055) / 1.055, 2.4);
                        });
                        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
                      }

                      function calculateRatio(color1, color2) {
                        const color1rgb = hexToRgb(color1);
                        const color2rgb = hexToRgb(color2);

                        const color1luminance = luminance(
                          color1rgb.r,
                          color1rgb.g,
                          color1rgb.b
                        );
                        const color2luminance = luminance(
                          color2rgb.r,
                          color2rgb.g,
                          color2rgb.b
                        );

                        const ratio =
                          color1luminance > color2luminance
                            ? (color2luminance + 0.05) /
                              (color1luminance + 0.05)
                            : (color1luminance + 0.05) /
                              (color2luminance + 0.05);

                        return ratio;
                      }

                      // conso
                      return (
                        <div
                          style={{
                            padding: 10,
                          }}
                        >
                          <div style={{ fontSize: 12, marginBottom: 10 }}>
                            Text Colour
                          </div>
                          <div style={{ display: "flex", marginBottom: 8 }}>
                            {greyArray &&
                              greyArray.map((hex) => (
                                <div
                                  type="button"
                                  style={{
                                    ...buttonStyle,
                                    backgroundColor: hex,
                                    border:
                                      calculateRatio(hex, "#ffffff") < 1 / 4.5
                                        ? null
                                        : `1px solid ${newShade(hex, -40)}`,
                                  }}
                                  onClick={() => setColour(hex)}
                                ></div>
                              ))}
                          </div>

                          <div style={{ display: "flex" }}>
                            {colourArrays &&
                              Object.keys(colourArrays).map((colour) => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {colourArrays[colour].map((hex) => (
                                    <div
                                      type="button"
                                      style={{
                                        ...buttonStyle,
                                        backgroundColor: hex,
                                        border:
                                          calculateRatio(hex, "#ffffff") <
                                          1 / 4.5
                                            ? null
                                            : `1px solid ${newShade(
                                                hex,
                                                -40
                                              )}`,
                                      }}
                                      onClick={() => setColour(hex)}
                                    ></div>
                                  ))}
                                </div>
                              ))}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: 10,
                            }}
                          >
                            <button onClick={resetText}>
                              Reset to default
                            </button>
                          </div>
                        </div>
                      );
                    },
                    execute: (state, api) => {
                      api.textArea.selectionStart = state.selection.start;
                      api.textArea.selectionEnd = state.selection.end;
                    },
                    buttonProps: { "aria-label": "Insert Colour" },
                  }),
                  commands.link,
                ]}
                extraCommands={[]}
                height="20vh"
                // height="60vh"
                value={emailMarkdown}
                onChange={setEmailMarkdown}
              />
            </div>
            <div style={{ height: markdownEditorOpen ? "52vh" : "100%" }}>
              <AllSupplyEmailContainer
                jobData={jobData}
                editMode={true}
                emailMarkdown={emailMarkdown}
                setEmailMarkdown={setEmailMarkdown}
                markdownEditorOpen={markdownEditorOpen}
                handleMarkdownEditorClose={handleMarkdownEditorClose}
                handleMarkdownEditorClick={handleMarkdownEditorClick}
                Popover={Popover}
                links={links}
                linkButtons={linkButtons}
                selectsUrl={selectsUrl}
                previewText={previewText}
                isMobile={isMobile}
                emailKind={emailKind}
              />
            </div>
          </div>
        </div>
      </>
    )}
  </>);
};
// overFileAllocation
export default EmailModal;

// const UploadQuickproofsContainer = ({
//   showQuickproofsUploadScreen,
//   setShowQuickproofsUploadScreen,
//   theme,
//   jobData,
// }) => {
//   const [isUploading, setIsUploading] = useState();
//   const [percentage, setPercentage] = useState(0);
//   const classes = makeStyles({
//     loadingContainer: {
//       display: showQuickproofsUploadScreen ? "block" : "none",
//       position: "fixed",
//       top: 0,
//       bottom: 0,
//       left: 0,
//       right: 0,
//       zIndex: 300,
//       backgroundColor: theme === "dark" ? "#1f1f1f" : "#f4f5f7",
//     },
//   })();
//   const loading = {
//     box: {
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       flexDirection: "column",
//       height: "100%",
//     },
//     progress: {
//       width: 90,
//       height: 90,
//     },
//   };
//   const buttonStyle = {
//     width: 150,
//     margin: 2,
//   };
//   const buttonContainer = {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   };
//   const pContainer = {
//     display: "flex",
//     justifyContent: "center",
//     width: "60%",
//   };
//   const basePath = `/${jobData.client}/${jobData.jobNumber}-${jobData.client}${
//     jobData.jobTypeShort
//   }${jobData.multipleDays ? `/${jobData.multipleDays}` : ``}`;
//   const handleFileChange = async (e) => {
//     if (e.target.files) {
//       setIsUploading(true);
//       for (let i = 0; i < e.target.files.length; i++) {
//         const file = e.target.files[i];
//         await dbx
//           .filesUpload({
//             path: `${basePath}/Quickproofs/${file.name}`,
//             autorename: false,
//             mode: "add",
//             mute: false,
//             contents: file,
//           })
//           .catch((e) => {
//             alert("an error occured, please upload manually");
//             setShowQuickproofsUploadScreen(false);
//           });
//         setPercentage(calculatePercentage(i + 1, e.target.files.length));
//       }
//       setShowQuickproofsUploadScreen(false);
//     }
//   };
//   return (
//     <div className={classes.loadingContainer}>
//       <Box sx={loading.box}>
//         <input
//           id="quickproofsUploadInput"
//           type="file"
//           style={{ display: "none" }}
//           onChange={handleFileChange}
//           multiple={true}
//         />
//         {isUploading ? (
//           <>
//             <div style={pContainer}>
//               <p>Uploading Quickproofs</p>
//             </div>
//             <div style={{ width: "80%" }}>
//               <LinearProgress variant="determinate" value={percentage} />
//             </div>
//           </>
//         ) : (
//           <>
//             <div
//               style={{
//                 ...pContainer,
//                 flexDirection: "column",
//                 alignItems: "center",
//               }}
//             >
//               <p>
//                 The Quickproofs Dropbox folder has to be created automatically,
//                 do you want to upload the quickproofs now or later?
//               </p>
//               <p>
//                 It might be best to do these in finder as there are typically a
//                 lot of files.
//               </p>
//             </div>
//             <div style={buttonContainer}>
//               <Button
//                 sx={buttonStyle}
//                 variant="outlined"
//                 onClick={() => {
//                   document.getElementById("quickproofsUploadInput").click();
//                 }}
//               >
//                 Upload Now
//               </Button>
//               <Button
//                 sx={buttonStyle}
//                 variant="outlined"
//                 onClick={() => {
//                   setShowQuickproofsUploadScreen(false);
//                 }}
//               >
//                 Upload Later
//               </Button>
//             </div>
//           </>
//         )}
//       </Box>
//     </div>
//   );
// };

// const UploadStoryboardsContainer = ({
//   showStoryboardsUploadScreen,
//   setShowStoryboardsUploadScreen,
//   theme,
//   jobData,
// }) => {
//   const [isUploading, setIsUploading] = useState();
//   const [percentage, setPercentage] = useState(0);
//   const classes = makeStyles({
//     loadingContainer: {
//       display: showStoryboardsUploadScreen ? "block" : "none",
//       position: "fixed",
//       top: 0,
//       bottom: 0,
//       left: 0,
//       right: 0,
//       zIndex: 300,
//       backgroundColor: theme === "dark" ? "#1f1f1f" : "#f4f5f7",
//     },
//   })();
//   const loading = {
//     box: {
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       flexDirection: "column",
//       height: "100%",
//     },
//     progress: {
//       width: 90,
//       height: 90,
//     },
//   };
//   const buttonStyle = {
//     width: 150,
//     margin: 2,
//   };
//   const buttonContainer = {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   };
//   const pContainer = {
//     display: "flex",
//     justifyContent: "center",
//     width: "60%",
//   };
//   const basePath = `/${jobData.client}/${jobData.jobNumber}-${jobData.client}${
//     jobData.jobTypeShort
//   }${jobData.multipleDays ? `/${jobData.multipleDays}` : ``}`;
//   // console.log(`${basePath}/Storyboards`);
//   const handleFileChange = async (e) => {
//     if (e.target.files) {
//       setIsUploading(true);
//       for (let i = 0; i < e.target.files.length; i++) {
//         const file = e.target.files[i];
//         await dbx
//           .filesUpload({
//             path: `${basePath}/Storyboards/${file.name}`,
//             autorename: false,
//             mode: "add",
//             mute: false,
//             contents: file,
//           })
//           .catch((e) => {
//             alert("an error occured, please upload manually");
//             setShowStoryboardsUploadScreen(false);
//           });
//         setPercentage(calculatePercentage(i + 1, e.target.files.length));
//       }
//       setShowStoryboardsUploadScreen(false);
//     }
//   };
//   return (
//     <div className={classes.loadingContainer}>
//       <Box sx={loading.box}>
//         <input
//           id="storyboardsUploadInput"
//           type="file"
//           style={{ display: "none" }}
//           onChange={handleFileChange}
//           multiple={true}
//         />
//         {isUploading ? (
//           <>
//             <div style={pContainer}>
//               <p>Uploading Storyboards</p>
//             </div>
//             <div style={{ width: "80%" }}>
//               <LinearProgress variant="determinate" value={percentage} />
//             </div>
//           </>
//         ) : (
//           <>
//             <div style={pContainer}>
//               <p>
//                 The Storyboards Dropbox folder has been created automatically,
//                 do you want to upload the storyboards now or later?
//               </p>
//             </div>
//             <div style={buttonContainer}>
//               <Button
//                 sx={buttonStyle}
//                 variant="outlined"
//                 onClick={() => {
//                   document.getElementById("storyboardsUploadInput").click();
//                 }}
//               >
//                 Upload Now
//               </Button>
//               <Button
//                 sx={buttonStyle}
//                 variant="outlined"
//                 onClick={() => {
//                   setShowStoryboardsUploadScreen(false);
//                 }}
//               >
//                 Upload Later
//               </Button>
//             </div>
//           </>
//         )}
//       </Box>
//     </div>
//   );
// };

// const UploadStoryboardSelectsContainer = ({
//   showStoryboardSelectsUploadScreen,
//   setShowStoryboardSelectsUploadScreen,
//   theme,
//   jobData,
// }) => {
//   const [isUploading, setIsUploading] = useState();
//   const [percentage, setPercentage] = useState(0);
//   const classes = makeStyles({
//     loadingContainer: {
//       display: showStoryboardSelectsUploadScreen ? "block" : "none",
//       position: "fixed",
//       top: 0,
//       bottom: 0,
//       left: 0,
//       right: 0,
//       zIndex: 299,
//       backgroundColor: theme === "dark" ? "#1f1f1f" : "#f4f5f7",
//     },
//   })();
//   const loading = {
//     box: {
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       flexDirection: "column",
//       height: "100%",
//     },
//     progress: {
//       width: 90,
//       height: 90,
//     },
//   };
//   const buttonStyle = {
//     width: 150,
//     margin: 2,
//   };
//   const buttonContainer = {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   };
//   const pContainer = {
//     display: "flex",
//     justifyContent: "center",
//     width: "60%",
//   };
//   const basePath = `/${jobData.client}/${jobData.jobNumber}-${jobData.client}${
//     jobData.jobTypeShort
//   }${jobData.multipleDays ? `/${jobData.multipleDays}` : ``}`;
//   const handleFileChange = async (e) => {
//     if (e.target.files) {
//       setIsUploading(true);
//       for (let i = 0; i < e.target.files.length; i++) {
//         const file = e.target.files[i];
//         await dbx
//           .filesUpload({
//             path: `${basePath}/Storyboard Selects/${file.name}`,
//             autorename: false,
//             mode: "add",
//             mute: false,
//             contents: file,
//           })
//           .catch((e) => {
//             alert("an error occured, please upload manually");
//             setShowStoryboardSelectsUploadScreen(false);
//           });
//         setPercentage(calculatePercentage(i + 1, e.target.files.length));
//       }
//       setShowStoryboardSelectsUploadScreen(false);
//     }
//   };
//   return (
//     <div className={classes.loadingContainer}>
//       <Box sx={loading.box}>
//         <input
//           id="storyboardsUploadInput"
//           type="file"
//           style={{ display: "none" }}
//           onChange={handleFileChange}
//           multiple={true}
//         />
//         {isUploading ? (
//           <>
//             <div style={pContainer}>
//               <p>Uploading Storyboard Selects</p>
//             </div>
//             <div style={{ width: "80%" }}>
//               <LinearProgress variant="determinate" value={percentage} />
//             </div>
//           </>
//         ) : (
//           <>
//             <div style={pContainer}>
//               <p>
//                 The Storyboard Selects Dropbox folder has been created
//                 automatically, do you want to upload the storyboard selects now
//                 or later?
//               </p>
//             </div>
//             <div style={buttonContainer}>
//               <Button
//                 sx={buttonStyle}
//                 variant="outlined"
//                 onClick={() => {
//                   document.getElementById("storyboardsUploadInput").click();
//                 }}
//               >
//                 Upload Now
//               </Button>
//               <Button
//                 sx={buttonStyle}
//                 variant="outlined"
//                 onClick={() => {
//                   setShowStoryboardSelectsUploadScreen(false);
//                 }}
//               >
//                 Upload Later
//               </Button>
//             </div>
//           </>
//         )}
//       </Box>
//     </div>
//   );
// };

async function getDropboxFileRequest(path, title) {
  await dbx
    .filesCreateFolderV2({
      path: path,
      autorename: false,
    })
    .catch((error) => {
      if (error.error.error_summary.includes("path/conflict/folder")) {
        console.log(
          "technically im an error, but im an expected error so its all g bro"
        );
        console.log(
          "all im saying is, there was already a folder with that name, all good"
        );
      } else console.error(JSON.stringify(error));
    });
  let existingRequestURL;
  const existingRequests = await dbx.fileRequestsListV2().catch((error) => {
    console.log(error);
    return { error: true, errorText: "Could not connect to Dropbox..." };
  });
  existingRequests.result.file_requests.forEach((req) => {
    if (req.is_open) {
      if (req.title === title) {
        existingRequestURL = req.url;
      }
    }
  });
  if (existingRequestURL) return existingRequestURL;
  const res = await dbx
    .fileRequestsCreate({
      title: title,
      destination: path,
      open: true,
    })
    .catch(function (error) {
      console.error(JSON.stringify(error));
      return { error: true, errorText: error.error.error_summary };
    });
  console.log(res);
  return res.result.url;
}

async function getDropboxLink(path, setShowUploadScreen = () => {}) {
  const existingLinkRes = await dbx
    .sharingListSharedLinks({
      path: path,
      direct_only: true,
    })
    .catch((error) => {
      console.error(JSON.stringify(error));
      console.log(error);
      return {
        error: true,
        errorText: error.error.error_summary || error,
        result: { links: [] },
      };
    });
  if (!existingLinkRes) {
    return { error: true, errorText: "Could not connect to Dropbox..." };
  }

  if (existingLinkRes.error) {
    if (existingLinkRes.errorText.includes("path/not_found")) {
      console.log("path not found");
      // setShowUploadScreen(true);
      const createFolderRes = await dbx
        .filesCreateFolderV2({
          path: path,
          autorename: false,
        })
        .catch((error) => {
          console.error(JSON.stringify(error));
          return { error: true, errorText: error.error.error_summary };
        });
      if (createFolderRes.error) {
        return createFolderRes;
      }
    } else return existingLinkRes;
  }

  if (existingLinkRes.result.links.length === 0) {
    const createLinkRes = await dbx
      .sharingCreateSharedLinkWithSettings({
        path: path,
        settings: {
          requested_visibility: "public",
          audience: "public",
          access: "viewer",
        },
      })
      .catch((error) => {
        console.error(JSON.stringify(error));
        return { error: true, errorText: error.error.error_summary };
      });
    return createLinkRes.result.url;
  } else {
    return existingLinkRes.result.links[0].url;
  }
}

const EmailField = ({ emails, setEmails, kind, visible, marginRight }) => {
  const [newEmail, setNewEmail] = useState("");
  const [newEmailVisible, setNewEmailVisible] = useState(false);
  const [newEmailError, setNewEmailError] = useState(false);
  const [newEmailButtonVisible, setNewEmailButtonVisible] = useState(true);

  const newEmailRef = useRef(newEmail);
  newEmailRef.current = newEmail;

  const handleDeleteEmailChip = (index) => {
    const tmpClients = [...emails];
    tmpClients.splice(index, 1);
    setEmails([...tmpClients]);
  };
  const handleNewEmailClick = () => {
    setNewEmailButtonVisible(false);
    setNewEmailVisible(true);
    setTimeout(() => {
      document.getElementById("newEmailField").focus();
    }, 100);
    setTimeout(() => {
      if (!newEmailRef.current) {
        setNewEmailButtonVisible(true);
        setNewEmailVisible(false);
        setNewEmailError(false);
      }
    }, 10000);
  };

  const confirmNewEmail = () => {
    if (!newEmailError) {
      setEmails([...emails, { email: newEmail }]);
      setNewEmailVisible(false);
      setNewEmailButtonVisible(true);
      setNewEmail("");
    }
  };

  const emailsContainerStyle = {
    minHeight: 10,
    display: visible ? "flex" : "none",
    marginBottom: 2,
    marginTop: 2,
    marginRight: marginRight,
    alignItems: "center",
  };
  return (
    (<div style={emailsContainerStyle}>
      <p
        style={{
          marginTop: 8,
          marginBottom: 8,
          marginRight: 10,
          fontSize: "0.8em",
        }}
      >
        {kind}:
      </p>
      <div style={{ marginTop: 0 }}>
        <Stack direction="row" spacing={1}>
          <div
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            {emails &&
              emails.map((toEmail, i) => {
                if (toEmail) {
                  if (toEmail.email) {
                    return (
                      <Tooltip
                        key={`EmailChipToolTip-${i}`}
                        title={toEmail.email}
                      >
                        <Chip
                          style={{
                            margin: 2,
                            fontWeight: "bold",
                            fontSize: "0.7em",
                          }}
                          size="small"
                          key={`EmailChip-${i}`}
                          label={toEmail.name || toEmail.email}
                          onDelete={() => {
                            handleDeleteEmailChip(i);
                          }}
                        />
                      </Tooltip>
                    );
                  } else return null;
                } else return null;
              })}
            <TextField
              id="newEmailField"
              style={{
                marginLeft: 4,
                marginTop: 3,
                marginBottom: 2,
                display: newEmailVisible ? "block" : "none",
              }}
              // autoFocus
              size="small"
              // inputRef={(input) => {
              //   if (input) {
              //     newEmailVisible && input.focus();
              //   }
              // }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    size="small"
                    onClick={() => {
                      setNewEmailButtonVisible(true);
                      setNewEmailVisible(false);
                      setNewEmail("");
                      setNewEmailError(false);
                    }}
                  >
                    <CancelIcon fontSize="small" />
                  </IconButton>
                ),
              }}
              inputProps={{ sx: { height: 10, width: 190, fontSize: "11px" } }}
              error={newEmailError}
              value={newEmail || ""}
              onChange={(e) => {
                setNewEmail(e.target.value);
                // eslint-disable-next-line
                if (
                  e.target.value.match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  )
                ) {
                  setNewEmailError(false);
                } else setNewEmailError(true);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  confirmNewEmail();
                }
              }}
            />
            <IconButton
              sx={{ width: 26, height: 26 }}
              style={{ display: newEmailButtonVisible ? "flex" : "none" }}
              onClick={handleNewEmailClick}
            >
              <AddCircleIcon color="primary" sx={{ width: 20 }} />
            </IconButton>
          </div>
        </Stack>
      </div>
    </div>)
  );
};

const addLabelToCardFB = async (firebase, jobData, labelId) => {
  if (jobData.idLabelsPermanent) {
    if (!jobData.idLabelsPermanent.includes(labelId)) {
      jobData.idLabelsPermanent.push(labelId);
    }
  } else {
    jobData.idLabelsPermanent = [labelId];
  }
  const noDupes = [...new Set(jobData.idLabelsPermanent)];
  jobData.idLabelsPermanent = noDupes;

  firebase
    .firestore()
    .collection("JobData")
    .doc(jobData.id)
    .update({ idLabelsPermanent: jobData.idLabelsPermanent });
};

// eslint-disable-next-line
// function getGreeting() {
//   var d = new Date(),
//     e = new Date(d);
//   var msSinceMidnight = e - d.setHours(0, 0, 0, 0);
//   var sSinceMidnight = msSinceMidnight / 1000;
//   if (sSinceMidnight < 42600) {
//     return "Good Morning";
//   } else if (sSinceMidnight > 42600 && sSinceMidnight < 43800) {
//     return "Hi";
//   } else if (sSinceMidnight > 43800) {
//     return "Good Afternoon";
//   }
// }

// function calculatePercentage(partialValue, totalValue) {
//   return (100 * partialValue) / totalValue;
// }

function numToLetter(num) {
  let s = "",
    t;

  while (num > 0) {
    t = (num - 1) % 26;
    s = String.fromCharCode(65 + t) + s;
    num = ((num - t) / 26) | 0;
  }
  return s || undefined;
}

function noop() {}
