import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";
import { useAtom } from "jotai";
import { themeAtom, isAdminAtom } from "../-Atoms";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

const ModelsContainer = ({ jobData, models, setModels, firebase }) => {
  const [themeA] = useAtom(themeAtom);
  const [isAdmin] = useAtom(isAdminAtom);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [openPicker, setOpenPicker] = useState(null);
  const autosizeOptions = {
    columns: ["name", "agency"],
  };

  useEffect(() => {
    setModels(
      Array.isArray(jobData?.models)
        ? jobData.models.map((model) => ({
            ...model,
            id: model.id || generateUniqueId(model),
          }))
        : []
    );
  }, [jobData,setModels]);

  const generateUniqueId = (model) => {
    return (
      model.name +
      model.agency +
      model.callTime +
      model.finishTime +
      model.bookedBy +
      model.paidBy +
      model.faceNoFace
    );
  };

  const updateDatabase = async (updatedModels) => {
    try {
      await firebase.firestore().collection("JobData").doc(jobData.id).update({
        models: updatedModels,
      });
      console.log("Database updated with new models.");
    } catch (error) {
      console.error("Error updating database:", error);
    }
  };

  const handleAddModel = () => {
    if (!isAdmin) return;
    const newModel = {
      id: uuidv4(),
      name: "",
      agency: "",
      callTime: null,
      finishTime: null,
      insta: "",
    };
    const updatedModels = [...models, newModel];
    setModels(updatedModels);
    updateDatabase(updatedModels);
    console.log("Updated Models:", updatedModels);
  };

  const handleDeleteModels = () => {
    if (!isAdmin || rowSelectionModel.length === 0) return;
    const updatedModels = models.filter(
      (model) => !rowSelectionModel.includes(model.id)
    );
    setModels(updatedModels);
    updateDatabase(updatedModels);
    console.log("Deleted Models:", updatedModels);
    setRowSelectionModel([]);
  };

  const handleTimeChange = (newValue, rowId, timeType) => {
    const newTime = newValue?.toISOString();
    setModels((prevModels) => {
      const updatedModels = prevModels.map((model) =>
        model.id === rowId ? { ...model, [timeType]: newTime } : model
      );
      updateDatabase(updatedModels);
      return updatedModels;
    });
    setOpenPicker(null);
  };

  const handleTimePickerOpen = (rowId, timeType, defaultTime) => {
    setOpenPicker(timeType);
    setModels((prevModels) => {
      const currentModel = prevModels.find((model) => model.id === rowId);
      if (!currentModel[timeType]) {
        const updatedModels = prevModels.map((model) =>
          model.id === rowId
            ? { ...model, [timeType]: defaultTime.toISOString() }
            : model
        );
        updateDatabase(updatedModels);

        return updatedModels;
      }
      return prevModels;
    });
  };

  const columns = [
    { field: "name", headerName: "Name", minWidth: 120, editable: isAdmin },
    { field: "agency", headerName: "Agency", width: 115, editable: isAdmin },
    {
      field: "callTime",
      headerName: "Call Time",
      width: 148,
      renderCell: (params) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            value={params.row.callTime ? dayjs(params.row.callTime) : null}
            disabled={openPicker === "finishTime"}
            onOpen={() =>
              handleTimePickerOpen(
                params.row.id,
                "callTime",
                dayjs().hour(8).minute(30)
              )
            }
            onClose={() => setOpenPicker(null)}
            onChange={(newValue) =>
              handleTimeChange(newValue, params.row.id, "callTime")
            }
            onAccept={(newValue) =>
              handleTimeChange(newValue, params.row.id, "callTime")
            }
            slotProps={{
              textField: {
                size: "small",
                variant: "filled",
                InputProps: { disableUnderline: true },
              },
            }}
          />
        </LocalizationProvider>
      ),
      editable: isAdmin,
    },
    {
      field: "finishTime",
      headerName: "Finish Time",
      width: 148,
      renderCell: (params) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            value={params.row.finishTime ? dayjs(params.row.finishTime) : null}
            disabled={openPicker === "callTime"}
            onOpen={() =>
              handleTimePickerOpen(
                params.row.id,
                "finishTime",
                dayjs().hour(16).minute(30)
              )
            }
            onClose={() => setOpenPicker(null)}
            onChange={(newValue) =>
              handleTimeChange(newValue, params.row.id, "finishTime")
            }
            onAccept={(newValue) =>
              handleTimeChange(newValue, params.row.id, "finishTime")
            }
            sx={
              {
                // width: 140,
              }
            }
            slotProps={{
              textField: {
                size: "small",
                variant: "filled",
                InputProps: { disableUnderline: true },
              },
            }}
          />
        </LocalizationProvider>
      ),
      editable: isAdmin,
    },
    { field: "insta", headerName: "Insta", width: 100, editable: true },
  ];

  return (
    <div
      style={{
        width: "100%",
        marginBottom: 10,
        padding: 10,
        overflowY: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <h3
          style={{
            marginTop: 10,
            color:
              themeA === "dark" ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.6)",
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            fontWeight: "bold",
            fontSize: "1.1rem",
            lineHeight: "1.4375em",
            letterSpacing: "0.00938em",
          }}
        >
          Models
        </h3>
        <div style={{ display: isAdmin ? "flex" : "none" }}>
          <IconButton onClick={handleAddModel}>
            <AddIcon />
          </IconButton>
          <IconButton
            onClick={handleDeleteModels}
            disabled={rowSelectionModel.length === 0}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
      <DataGrid
        rows={models}
        columns={columns}
        checkboxSelection={isAdmin}
        disableRowSelectionOnClick
        autosizeOnMount
        autosizeOptions={autosizeOptions}
        rowSelectionModel={rowSelectionModel}
        disableColumnSorting
        onRowSelectionModelChange={(newSelection) => {
          setRowSelectionModel(newSelection);
        }}
        processRowUpdate={(updatedRow) => {
          const updatedModels = models.map((model) =>
            model.id === updatedRow.id ? updatedRow : model
          );
          setModels(updatedModels);
          updateDatabase(updatedModels);
          return updatedRow;
        }}
        onProcessRowUpdateError={(error) => console.error(error)}
        getRowId={(row) => row.id || generateUniqueId(row)}
        autoHeight
        // density={"compact"}
      />
    </div>
  );
};

export default ModelsContainer;
