import React from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { setExpressLabel } from "../../GlobalFunctions/setExpressLabel";

const DueDateExpressContainer = ({
  isMobile,
  dueDate,
  setDueDate,
  firebase,
  jobData,
  express,
  setExpress,
  expressDueDate,
  setExpressDueDate,
  canEdit,
}) => {
  // Ensure that dueDate and expressDueDate are dayjs objects
  const dueDateValue = dueDate ? dayjs(dueDate) : null;
  const expressDueDateValue = expressDueDate ? dayjs(expressDueDate) : null;

  const handleDueDateChange = (newValue) => {
    if (!canEdit) return;
    try {
      const dateWithTime = newValue.hour(17).minute(30);
      setDueDate(dateWithTime.toISOString());
      firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update({ dueDate: dateWithTime.toISOString() }, { merge: true });
    } catch (e) {
      setDueDate(null);
      firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update({ dueDate: null }, { merge: true });
    }
  };

  const handleExpressDueDateChange = (newValue) => {
    if (!canEdit) return;
    let date = null;
    try {
      date = newValue.toISOString();
    } catch (e) {
      console.log(e);
    }
    setExpressDueDate(date);
    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ expressDueDate: date || null }, { merge: true })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          flexDirection: isMobile ? "column" : "row",
          marginTop: 0,
        }}
      >
        <MobileDatePicker
          label="Due Date"
          format="DD/MM/YYYY"
          value={dueDateValue}
          onChange={handleDueDateChange}
          slotProps={{
            textField: {
              variant: "filled",
              InputProps: { disableUnderline: true },
              error: dueDate ? false : true,
              sx: { marginTop: 2, width: isMobile ? "100%" : "56%" },
            },
          }}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100%",
          }}
        >
          <FormGroup
            style={{
              width: isMobile ? "100%" : "80%",
              display: "flex",
              alignItems: "center",
            }}
            sx={{ marginTop: 2 }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={express || false}
                  onChange={(e) => {
                    if (!canEdit) return;
                    setExpressLabel(firebase, jobData.id, e.target.checked);
                    setExpress(e.target.checked);
                    firebase
                      .firestore()
                      .collection("JobData")
                      .doc(jobData.id)
                      .update({ express: e.target.checked }, { merge: true });
                  }}
                />
              }
              label="Express"
            />
          </FormGroup>

          <DateTimePicker
            label="Express Due Date"
            value={expressDueDateValue}
            format="DD/MM/YYYY hh:mm A"
            onChange={handleExpressDueDateChange}
            slotProps={{
              textField: {
                variant: "filled",
                InputProps: { disableUnderline: true },
                fullWidth: true,
                sx: { marginTop: 2 },
              },
            }}
          />
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default DueDateExpressContainer;
