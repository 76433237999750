import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircleIcon from "@mui/icons-material/Circle";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useAtom } from "jotai";
import { themeAtom, currentStaffDataAtom } from "../-Atoms";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FiltersContainer = ({
  filtersMenuVisible,
  queryFilter,
  setQueryFilter,
  clientData,
  staffData,
  studios,
  calendarColours,
  // currentUser,
  showStaff,
  setShowStaff,
  broadClientFilter,
  setBroadClientFilter,
  showDoubles,
  setShowDoubles,
}) => {
  // console.log(queryFilter)
  const [currentStaffData] = useAtom(currentStaffDataAtom);
  const resetFilters = () => {
    if (currentStaffData?.id) {
      if (currentStaffData.isUtility) {
        setQueryFilter(null);
        return;
      }
      // if (currentStaffData.isAdmin) {
      //   setQueryFilter(null);
      //   return;
      // }
      // if (currentStaffData.isSuperAdmin) {
      //   setQueryFilter(null);
      //   return;
      // }
      setQueryFilter({ staff: [currentStaffData] });
      return;
    }
    setQueryFilter(null);
  };

  const clearFilters = () => {
    setQueryFilter(null);
  };
  return (
    <div
      style={{
        width: "99.5vw",
        height: 65,
        marginTop: 15,
        marginBottom: 15,
        display: filtersMenuVisible ? "block" : "none",
        justifyContent: "center",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        {/* <JobNumberSelector /> */}
        <StaffSelector
          staffData={staffData}
          staff={queryFilter?.staff || []}
          queryFilter={queryFilter}
          setQueryFilter={setQueryFilter}
        />
        <CalendarSelector
          studios={studios}
          studio={queryFilter?.studio || []}
          queryFilter={queryFilter}
          setQueryFilter={setQueryFilter}
          calendarColours={calendarColours}
        />
        <ClientSelector
          clientData={clientData}
          client={queryFilter?.client || []}
          queryFilter={queryFilter}
          setQueryFilter={setQueryFilter}
        />
        <Button
          variant="outlined"
          onClick={resetFilters}
          style={{ height: 40, fontSize: 10 }}
        >
          Filter My Jobs
        </Button>
        <Button
          style={{ marginLeft: 10, height: 40, fontSize: 10 }}
          variant="outlined"
          onClick={clearFilters}
        >
          Remove All Filters
        </Button>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showStaff}
              onChange={(e) => {
                setShowStaff(e.target.checked);
              }}
            />
          }
          label="Show Staff"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={broadClientFilter}
              onChange={(e) => {
                setBroadClientFilter(e.target.checked);
              }}
            />
          }
          label="Broad Client Filter"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showDoubles}
              onChange={(e) => {
                localStorage.setItem("showDoubles", e.target.checked);
                setShowDoubles(e.target.checked);
              }}
            />
          }
          label="Show Doubles"
        />
      </div>
    </div>
  );
};

export default FiltersContainer;

// eslint-disable-next-line
const JobNumberSelector = ({}) => {
  return (
    <>
      <TextField
        size="small"
        style={{ marginRight: 16, width: 240 }}
        label="Job Number Filter"
      />
    </>
  );
};

const CalendarSelector = ({
  studio,
  queryFilter,
  setQueryFilter,
  firebase,
  jobData,
  studios,
  calendarColours,
}) => {
  const [theme] = useAtom(themeAtom);

  return (
    (<FormControl size="small" sx={{ marginRight: 2, width: 400 }}>
      <InputLabel id="studio-calendar">Studio Filter</InputLabel>
      <Select
        variant="filled"
        disableUnderline
        labelId="studio-calendar-select"
        value={studio || []}
        label="Studio Filter"
        multiple
        onChange={(e, value) => {
          setQueryFilter({ ...queryFilter, studio: [...e.target.value] });
        }}
      >
        <MenuItem key={"All Studios"} value={"All Studios"}>
          All Studios
        </MenuItem>
        {studios.map((s) => (
          <MenuItem key={s} value={s}>
            <span style={{ display: "flex", aliognItems: "center" }}>
              <CircleIcon
                style={{
                  fill:
                    theme === "dark"
                      ? calendarColours[s].darkBackground.replace(
                          /(#(?:[\da-fA-F]{6}|[\da-fA-F]{3}))[\da-fA-F]{2}/,
                          "$1"
                        ) || "#000000"
                      : calendarColours[s].background || "#000000",
                  marginRight: 10,
                }}
              />
              {s}
            </span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>)
  );
};

const StaffSelector = ({ staffData, staff, queryFilter, setQueryFilter }) => {
  const employedStaff = staffData.filter((staff) => staff.currentlyEmployed);

  return (
    <Autocomplete
      multiple
      disablePortal
      sx={{ marginRight: 2, width: 400 }}
      size="small"
      disableCloseOnSelect
      disableClearable
      options={employedStaff}
      getOptionLabel={(option) => findStaff(option, employedStaff)}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.firstName} {option.lastName}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          InputProps={{ ...params.InputProps, disableUnderline: true }}
          label="Staff Filter"
        />
      )}
      value={staff || []}
      onChange={(e, value) => {
        setQueryFilter({ ...queryFilter, staff: [...value] });
      }}
    />
  );
};

const ClientSelector = ({
  clientData,
  client,
  queryFilter,
  setQueryFilter,
}) => {
  return (
    <Autocomplete
      multiple
      disablePortal
      sx={{ marginRight: 2, width: 400 }}
      size="small"
      disableCloseOnSelect
      disableClearable
      options={clientData.map((c) => c.brand)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          InputProps={{ ...params.InputProps, disableUnderline: true }}
          label="Client Filter"
        />
      )}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </li>
        );
      }}
      value={client || []}
      onChange={(e, value) => {
        setQueryFilter({ ...queryFilter, client: [...value] });
      }}
    />
  );
};

const findStaff = (option, staffData) => {
  if (option.firstName) return `${option.firstName} ${option.lastName}`;
  // console.log(option);
  for (let i = 0; i < staffData.length; i++) {
    if (staffData[i].id === option) {
      return `${staffData[i].firstName} ${staffData[i].lastName}`;
    }
  }
  return option;
};
