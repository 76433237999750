import React, { useState, useEffect, useCallback } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import debounce from "lodash/debounce";

const roundUpToNearest5 = (date) => {
  const minutes = date.minute();
  const remainder = 5 - (minutes % 5);
  return date.add(remainder, "minute").second(0);
};

const formatOvertime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  if (hours > 0) {
    return `${hours} Hour${hours > 1 ? "s" : ""} ${
      remainingMinutes > 0
        ? `${remainingMinutes} Minute${remainingMinutes > 1 ? "s" : ""}`
        : ""
    }`;
  }
  return `${remainingMinutes} Minute${remainingMinutes > 1 ? "s" : ""}`;
};

const stripDate = (date) => {
  return dayjs().hour(date.hour()).minute(date.minute()).second(0);
};

const Dates = ({
  titleStyle,
  bookedStart,
  bookedFinish,
  startTime,
  setStartTime,
  firebase,
  jobData,
  finishTime,
  setFinishTime,
  overtime,
  setOvertime,
}) => {
  const [isStartDisabled, setIsStartDisabled] = useState(false);
  const [isFinishDisabled, setIsFinishDisabled] = useState(false);
  const [manualOvertime, setManualOvertime] = useState(false);

  const updateField = (id, field, value) => {
    firebase
      .firestore()
      .collection("JobData")
      .doc(id)
      .update({ [field]: value }, { merge: true })
      .catch((error) => {
        console.log(error);
      });
  };

  // Debounced update for overtime field
  const debouncedUpdateOvertime = useCallback(
    debounce((value) => {
      updateField(jobData.id, "overtime", value);
    }, 500),
    [jobData.id]
  );

  useEffect(() => {
    if (startTime && finishTime && !manualOvertime) {
      const actualStart = stripDate(dayjs(startTime));
      const actualFinish = stripDate(dayjs(finishTime));
      const bookedStartTime = stripDate(dayjs(bookedStart));
      const bookedFinishTime = stripDate(dayjs(bookedFinish));

      const actualDuration = actualFinish.diff(actualStart, "minute");
      const bookedDuration = bookedFinishTime.diff(bookedStartTime, "minute");

      const overtimeMinutes = actualDuration - bookedDuration;
      if (overtimeMinutes > 0) {
        const formattedOvertime = formatOvertime(overtimeMinutes);
        setOvertime(formattedOvertime);
        updateField(jobData.id, "overtime", formattedOvertime);
      } else {
        setOvertime("");
        updateField(jobData.id, "overtime", "");
      }
    }
  }, [
    startTime,
    finishTime,
    bookedStart,
    bookedFinish,
    jobData.id,
    setOvertime,
    manualOvertime,
  ]);

  const handleStartTimeChange = (d) => {
    if (!d || !d.isValid()) return;
    const isoString = d.toISOString();
    setStartTime(isoString);
    updateField(jobData.id, "startTime", isoString);
    setIsFinishDisabled(false);
    setManualOvertime(false); // Reset manual input if time changes
  };

  const handleFinishTimeChange = (d) => {
    if (!d || !d.isValid()) return;
    const isoString = d.toISOString();
    setFinishTime(isoString);
    updateField(jobData.id, "finishTime", isoString);
    setIsStartDisabled(false);
    setManualOvertime(false); // Reset manual input if time changes
  };

  const handleOvertimeChange = (e) => {
    const value = e.target.value;
    setOvertime(value);
    setManualOvertime(true); // Set flag for manual overtime input
    debouncedUpdateOvertime(value); // Debounced update
  };

  return (
    <>
      <div style={titleStyle}>
        <span>Dates</span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: -10,
          gap: 15,
          padding: 30,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div style={{ display: "flex", gap: 15 }}>
            <TimePicker
              label="Booked Start"
              value={bookedStart ? dayjs(bookedStart) : null}
              onChange={() => {}}
              readOnly
              disabled
              slotProps={{
                textField: {
                  variant: "filled",
                  fullWidth: true,
                  size: "small",
                  inputProps: {
                    style: { fontSize: 15 },
                  },
                  InputLabelProps: {
                    style: { fontSize: 15 },
                  },
                  InputProps: {
                    disableUnderline: true,
                  },
                },
              }}
            />
            <TimePicker
              label="Booked Finish"
              value={bookedFinish ? dayjs(bookedFinish) : null}
              onChange={() => {}}
              readOnly
              disabled
              slotProps={{
                textField: {
                  variant: "filled",
                  fullWidth: true,
                  size: "small",
                  inputProps: {
                    style: { fontSize: 15 },
                  },
                  InputLabelProps: {
                    style: { fontSize: 15 },
                  },
                  InputProps: {
                    disableUnderline: true,
                  },
                },
              }}
            />
          </div>
          <div style={{ display: "flex", gap: 15 }}>
            <TimePicker
              label="Start Time"
              value={startTime ? dayjs(startTime) : null}
              onChange={handleStartTimeChange}
              onOpen={() => {
                if (!startTime) {
                  setStartTime(bookedStart);
                  updateField(jobData.id, "startTime", bookedStart);
                }
                setIsFinishDisabled(true);
              }}
              onClose={() => setIsFinishDisabled(false)}
              disabled={isStartDisabled}
              slotProps={{
                textField: {
                  variant: "filled",
                  fullWidth: true,
                  size: "small",
                  inputProps: {
                    style: { fontSize: 15 },
                    onInput: (e) => e.preventDefault(),
                  },
                  InputLabelProps: {
                    style: { fontSize: 15 },
                  },
                  InputProps: {
                    disableUnderline: true,
                  },
                },
              }}
            />
            <TimePicker
              label="Finish Time"
              value={finishTime ? dayjs(finishTime) : null}
              onChange={handleFinishTimeChange}
              onOpen={() => {
                if (!finishTime) {
                  const now = roundUpToNearest5(dayjs());
                  setFinishTime(now);
                  updateField(jobData.id, "finishTime", now.toISOString());
                }
                setIsStartDisabled(true);
              }}
              onClose={() => setIsStartDisabled(false)}
              disabled={isFinishDisabled}
              slotProps={{
                textField: {
                  variant: "filled",
                  fullWidth: true,
                  size: "small",
                  inputProps: {
                    style: { fontSize: 15 },
                    onInput: (e) => e.preventDefault(),
                  },
                  InputLabelProps: {
                    style: { fontSize: 15 },
                  },
                  InputProps: {
                    disableUnderline: true,
                  },
                },
              }}
            />
          </div>
        </LocalizationProvider>
        <TextField
          multiline
          label="Overtime"
          value={overtime || ""}
          onChange={handleOvertimeChange}
          size="small"
          variant="filled"
          fullWidth
          inputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          InputProps={{ disableUnderline: true }}
        />
      </div>
    </>
  );
};

export default Dates;