import React, { useState, useEffect, useRef, useCallback } from "react";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dayjs from "dayjs";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import { debounce } from "lodash";
import ContactPageIcon from "@mui/icons-material/ContactPage";

import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";

import { jsPDF } from "jspdf";

import { useAtom } from "jotai";
import { themeAtom } from "../-Atoms";

const { DateTime } = require("luxon");

const StaffCard = ({
  firebase,
  selectedStaff,
  staffData,
  windowSize,
  listWidth,
  user,
}) => {
  const [staff, setStaff] = useState({});
  const [avatar, setAvatar] = useState("staff.avatar");
  const [dateOfBirth, setDateOfBirth] = useState(dayjs());
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [preferredName, setPreferredName] = useState("");
  const [email, setEmail] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [phone, setPhone] = useState("");
  const [employmentBasis, setEmploymentBasis] = useState("");
  const [nextOfKin1Name, setNextOfKin1Name] = useState("");
  const [nextOfKin1Phone, setNextOfKin1Phone] = useState("");
  const [nextOfKin2Name, setNextOfKin2Name] = useState("");
  const [nextOfKin2Phone, setNextOfKin2Phone] = useState("");
  const [taxFileNumber, setTaxFileNumber] = useState("");
  const [salaryHourlyRate, setSalaryHourlyRate] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAccountName, setBankAccountName] = useState("");
  const [BSB, setBSB] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [superannuationProvider, setSuperannuationProvider] = useState("");
  const [superannuationMemberNumber, setSuperannuationMemberNumber] =
    useState("");
  const [superannuationFundUSI, setSuperannuationFundUSI] = useState("");
  const [currentlyEmployed, setCurrentlyEmployed] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  //eslint-disable-next-line
  const [isUtility, setIsUtility] = useState(false);
  const [streetNumber, setStreetNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [postcode, setPostcode] = useState("");
  const [suburb, setSuburb] = useState("");

  const [wwccCheckNumber, setWwccCheckNumber] = useState("");
  const [wwccExpiry, setWwccExpiry] = useState(dayjs());

  const setUserDisabled = firebase.functions().httpsCallable("setUserDisabled");

  useEffect(() => {
    setAvatar("");
    setIsAdmin(staff.isAdmin);
    setIsSuperAdmin(staff.isSuperAdmin);
    setIsUtility(false);
    setFirstName(staff.firstName);
    setLastName(staff.lastName);
    setPreferredName(staff.preferredName);
    setEmail(staff.email);
    setJobTitle(staff.jobTitle);
    setPhone(staff.phoneNumber);

    setWwccCheckNumber(staff.wwccCheckNumber);
    setWwccExpiry(dayjs(staff.wwccExpiry));

    setEmploymentBasis(staff.employmentBasis);
    if (staff.currentlyEmployed !== undefined) {
      setCurrentlyEmployed(staff.currentlyEmployed);
    }

    if (staff.dateOfBirth) {
      try {
        setDateOfBirth(dayjs(staff.dateOfBirth.toDate()) || "");
      } catch {
        setDateOfBirth(dayjs(staff.dateOfBirth) || "");
      }
    } else {
      setDateOfBirth(dayjs());
    }
    if (staff.address) {
      setStreetNumber(staff.address.streetNumber || "");
      setStreetName(staff.address.streetName || "");
      setPostcode(staff.address.postcode || "");
      setSuburb(staff.address.suburb || "");
    } else {
      setStreetNumber("");
      setStreetName("");
      setPostcode("");
      setSuburb("");
    }
    setNextOfKin1Name(staff.nextOfKin1Name || "");
    setNextOfKin1Phone(staff.nextOfKin1Phone || "");
    setNextOfKin2Name(staff.nextOfKin2Name || "");
    setNextOfKin2Phone(staff.nextOfKin2Phone || "");
    setTaxFileNumber(staff.taxFileNumber || "");
    setSalaryHourlyRate(staff.salaryHourlyRate || "");
    setBankName(staff.bankName || "");
    setBankAccountName(staff.bankAccountName || "");
    setBSB(staff.BSB || "");
    setBankAccountNumber(staff.bankAccountNumber || "");
    setSuperannuationProvider(staff.superannuationProvider || "");
    setSuperannuationMemberNumber(staff.superannuationMemberNumber || "");
    setSuperannuationFundUSI(staff.superannuationFundUSI || "");
    setCurrentlyEmployed(staff.currentlyEmployed);

    setTimeout(() => {
      setAvatar(staff.avatar);
      setIsAdmin(staff.isAdmin);
      setIsSuperAdmin(staff.isSuperAdmin);
      setIsUtility(staff.isUtility);
    }, 20);
  }, [staff]);

  const [textFieldStyleWidth, setTextFieldStyleWidth] = useState(
    windowSize.width < 900 ? windowSize.width / 2 - 40 : 200
  );

  useEffect(() => {
    setTextFieldStyleWidth(
      windowSize.width < 900 ? windowSize.width / 2 - 40 : 200
    );
  }, [windowSize, setTextFieldStyleWidth]);

  useEffect(() => {
    staffData.forEach((s) => {
      if (s.id === selectedStaff) {
        setStaff({ ...s });
      }
    });
    // eslint-disable-next-line
  }, [selectedStaff]);

  const containerStyle = {
    width:
      windowSize.width > 900
        ? `calc(100vw - ${listWidth}px - 16px)`
        : `calc(100vw - 10px)`,
    // background: 'red',
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
  const iconStyle = {
    width: 150,
    height: 150,
    marginTop: 30,
    marginBottom: 30,
  };
  const textFieldStyle = {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
    width: textFieldStyleWidth,
  };
  const employmentBasisStyle = {
    display: "flex",
    justifyContent: "center",
    width: textFieldStyleWidth * 2 + 20,
    marginTop: 40,
    marginBottom: 10,
  };
  const handleFirstNameChange = (e) => {
    if (canView(staff, user)) {
      setFirstName(e.target.value);
      updateFSNow(firebase, staff.id, "firstName", e.target.value);
      // firebase.auth().currentUser.updateProfile({ firstName: e.target.value });
      // firebase.auth().currentUser.updateProfile({
      //   displayName: `${e.target.value} ${lastName}`,
      // });
    } else console.log("stop.");
  };
  const handleLastNameChange = (e) => {
    if (canView(staff, user)) {
      setLastName(e.target.value);
      updateFSNow(firebase, staff.id, "lastName", e.target.value);
      // firebase.auth().currentUser.updateProfile({ lastName: e.target.value });
      // firebase.auth().currentUser.updateProfile({
      //   displayName: `${firstName} ${e.target.value}`,
      // });
    } else console.log("stop.");
  };
  const handleJobTitleChange = (e) => {
    if (canView(staff, user)) {
      setJobTitle(e.target.value);
      updateFSNow(firebase, staff.id, "jobTitle", e.target.value);
    } else console.log("stop.");
  };
  const handlePhoneNumberChange = (e) => {
    // if (canView(staff, user)) {
    //   setPhone(e.target.value);
    //   updateFSNow(firebase, staff.id, "phoneNumber", e.target.value);
    // } else console.log("stop.");
  };
  const handlePreferredNameChange = (e) => {
    if (canView(staff, user)) {
      setPreferredName(e.target.value);
      updateFSNow(firebase, staff.id, "preferredName", e.target.value);
      updateFSNow(firebase, staff.id, "preferredName", e.target.value);
    } else console.log("stop.");
  };
  const handleDateOfBirthChange = (e) => {
    console.log(new Date(e).toISOString());
    if (canView(staff, user)) {
      try {
        setDateOfBirth(dayjs(new Date(e).toISOString()));
        updateFSNow(
          firebase,
          staff.id,
          "dateOfBirth",
          new Date(e).toISOString()
        );
      } catch (e) {
        console.log(e);
      }
    } else console.log("stop.");
  };
  const handleEmailChange = (e) => {
    if (canView(staff, user)) {
      setEmail(e.target.value);
      updateFSNow(firebase, staff.id, "email", e.target.value);
    } else console.log("stop.");
  };
  const handleStreetNumberChange = (e) => {
    if (canView(staff, user)) {
      setStreetNumber(e.target.value);
      updateFSNow(firebase, staff.id, "address.streetNumber", e.target.value);
    } else console.log("stop.");
  };
  const handleStreetNameChange = (e) => {
    if (canView(staff, user)) {
      setStreetName(e.target.value);
      updateFSNow(firebase, staff.id, "address.streetName", e.target.value);
    } else console.log("stop.");
  };
  const handlePostcodeChange = (e) => {
    if (canView(staff, user)) {
      setPostcode(e.target.value);
      updateFSNow(firebase, staff.id, "address.postcode", e.target.value);
    } else console.log("stop.");
  };
  const handleSuburbChange = (e) => {
    if (canView(staff, user)) {
      setSuburb(e.target.value);
      updateFSNow(firebase, staff.id, "address.suburb", e.target.value);
    } else console.log("stop.");
  };
  const handleEmploymentBasisChange = (e) => {
    if (canView(staff, user)) {
      setEmploymentBasis(e.target.value);
      updateFSNow(firebase, staff.id, "employmentBasis", e.target.value);
    } else console.log("stop.");
  };
  const handleNextOfKin1NameChange = (e) => {
    if (canView(staff, user)) {
      setNextOfKin1Name(e.target.value);
      updateFSNow(firebase, staff.id, "nextOfKin1Name", e.target.value);
    } else console.log("stop.");
  };
  const handleNextOfKin1PhoneChange = (e) => {
    if (canView(staff, user)) {
      setNextOfKin1Phone(e.target.value);
      updateFSNow(firebase, staff.id, "nextOfKin1Phone", e.target.value);
    } else console.log("stop.");
  };
  const handleNextOfKin2NameChange = (e) => {
    if (canView(staff, user)) {
      setNextOfKin2Name(e.target.value);
      updateFSNow(firebase, staff.id, "nextOfKin2Name", e.target.value);
    } else console.log("stop.");
  };
  const handleNextOfKin2PhoneChange = (e) => {
    if (canView(staff, user)) {
      setNextOfKin2Phone(e.target.value);
      updateFSNow(firebase, staff.id, "nextOfKin2Phone", e.target.value);
    } else console.log("stop.");
  };
  const handleTaxFileNumberChange = (e) => {
    if (canView(staff, user)) {
      setTaxFileNumber(e.target.value);
      updateFSNow(firebase, staff.id, "taxFileNumber", e.target.value);
    } else console.log("stop.");
  };
  const handleSalaryHourlyRateChange = (e) => {
    if (canView(staff, user)) {
      setSalaryHourlyRate(e.target.value);
      updateFSNow(firebase, staff.id, "salaryHourlyRate", e.target.value);
    } else console.log("stop.");
  };
  const handleBankNameChange = (e) => {
    if (canView(staff, user)) {
      setBankName(e.target.value);
      updateFSNow(firebase, staff.id, "bankName", e.target.value);
    } else console.log("stop.");
  };
  const handleBankAccountNameChange = (e) => {
    if (canView(staff, user)) {
      setBankAccountName(e.target.value);
      updateFSNow(firebase, staff.id, "bankAccountName", e.target.value);
    } else console.log("stop.");
  };
  const handleBSBChange = (e) => {
    if (canView(staff, user)) {
      setBSB(e.target.value);
      updateFSNow(firebase, staff.id, "BSB", e.target.value);
    } else console.log("stop.");
  };
  const handleBankAccountNumberChange = (e) => {
    if (canView(staff, user)) {
      setBankAccountNumber(e.target.value);
      updateFSNow(firebase, staff.id, "bankAccountNumber", e.target.value);
    } else console.log("stop.");
  };
  const handleSuperannuationProviderChange = (e) => {
    if (canView(staff, user)) {
      setSuperannuationProvider(e.target.value);
      updateFSNow(firebase, staff.id, "superannuationProvider", e.target.value);
    } else console.log("stop.");
  };
  const handleSuperannuationMemberNumberChange = (e) => {
    if (canView(staff, user)) {
      setSuperannuationMemberNumber(e.target.value);
      updateFSNow(
        firebase,
        staff.id,
        "superannuationMemberNumber",
        e.target.value
      );
    } else console.log("stop.");
  };
  const handleSuperannuationFundUSIChange = (e) => {
    if (canView(staff, user)) {
      setSuperannuationFundUSI(e.target.value);
      updateFSNow(firebase, staff.id, "superannuationFundUSI", e.target.value);
    } else console.log("stop.");
  };
  const handleCurrentlyEmployedChange = (e) => {
    if (canView(staff, user)) {
      setCurrentlyEmployed(e.target.checked);
      // updateFSNow(firebase, staff.id, "currentlyEmployed", e.target.checked);
      console.log({
        uid: staff.uid,
        disabled: !e.target.checked,
        staffID: staff.id,
        currentlyEmployed: e.target.checked,
      });
      // return
      setUserDisabled({
        uid: staff.uid,
        disabled: !e.target.checked,
        staffID: staff.id,
        currentlyEmployed: e.target.checked,
      });
    } else console.log("stop.");
  };
  const handleIsAdminChange = (e) => {
    if (isCurrentUserAdmin(user) || isCurrentUserSuperAdmin(user)) {
      setIsAdmin(e.target.checked);
      updateFSNow(firebase, staff.id, "isAdmin", e.target.checked);
    } else console.log("stop.");
  };
  const handleIsSuperAdminChange = (e) => {
    if (isCurrentUserSuperAdmin(user)) {
      setIsSuperAdmin(e.target.checked);
      updateFSNow(firebase, staff.id, "isSuperAdmin", e.target.checked);
    } else console.log("stop.");
  };
  const parseDOB = (dob) => {
    const date = new Date(dob);
    const day = date.getDate();
    let month = date.getMonth() + 1;
    if (month < 10) month = `0${month}`;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.setFont("helvetica");
    doc.setFontSize(18);
    doc.text("IMAGES THAT SELL", 75, 20);
    doc.setFontSize(14);
    doc.text("EMPLOYEE DETAILS", 80, 32);

    doc.setFontSize(12);

    doc.setFont("helvetica", "bold");
    doc.text("NAME:", 20, 50);
    doc.text("ADDRESS:", 20, 62);
    doc.text("PHONE:", 20, 74);
    doc.text("EMAIL:", 20, 86);
    doc.text("DATE OF BIRTH:", 20, 98);
    doc.text("EMPLOYMENT BASIS:", 20, 115);
    doc.text("JOB TITLE / POSITION:", 20, 125);
    doc.text("NEXT OF KIN 1 NAME:", 20, 140);
    doc.text("NEXT OF KIN 1 PHONE:", 20, 150);
    doc.text("NEXT OF KIN 2 NAME:", 20, 160);
    doc.text("NEXT OF KIN 2 PHONE:", 20, 170);
    doc.text("TAX FILE NUMBER:", 20, 185);
    doc.text("COMMENCING SALARY / HOURLY RATE:", 20, 195);
    doc.text("BANK NAME:", 20, 210);
    doc.text("ACCOUNT NAME:", 20, 220);
    doc.text("BSB:", 20, 230);
    doc.text("ACCOUNT NUMBER:", 20, 240);
    doc.text("SUPERANNUATION PROVIDER:", 20, 258);
    doc.text("SUPERANNUATION MEMBER NUMBER:", 20, 268);
    doc.text("SUPERANNUATION FUND USI:", 20, 278);

    //Name
    doc.setFont("helvetica", "normal");
    doc.text(`${firstName} ${lastName}`, 48, 50);
    //Address
    doc.text(`${streetNumber} ${streetName}, ${suburb}, ${postcode}`, 48, 62);
    //Phone
    doc.text(phone, 48, 74);
    //Email
    doc.text(email, 48, 86);
    //DOB
    doc.text(parseDOB(dateOfBirth), 60, 98);
    //Employment Basis
    doc.text(employmentBasis, 70, 115);
    //Title / Role
    doc.text(jobTitle, 70, 125);
    //NOK 1 Name
    doc.text(nextOfKin1Name, 72, 140);
    // NOK 1 Phone
    doc.text(nextOfKin1Phone, 72, 150);
    //NOK 2 Name
    doc.text(nextOfKin2Name, 72, 160);
    // NOK 2 Phone
    doc.text(nextOfKin2Phone, 72, 170);
    //Tax File Number
    doc.text(taxFileNumber, 65, 185);
    //Salary / Hourly Rate
    doc.text(salaryHourlyRate, 110, 195);
    //Bank Name
    doc.text(bankName, 70, 210);
    //Bank Account Name
    doc.text(bankAccountName, 70, 220);
    //BSB
    doc.text(BSB, 70, 230);
    //Bank Account Number
    doc.text(bankAccountNumber, 70, 240);
    //Super provider
    doc.text(superannuationProvider, 110, 258);
    //Super Member Number
    doc.text(superannuationMemberNumber, 110, 268);
    //Super Fund USI
    doc.text(superannuationFundUSI, 110, 278);

    doc.save(`${firstName} ${lastName} - Employee Details.pdf`);
  };
  const checkURL = (string) => {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  };
  const [openModal, setOpenModal] = useState(false);
  const [
    uploadNewProfilePicButtonOpacity,
    setUploadNewProfilePicButtonOpacity,
  ] = useState(0);

  const [showMoreInfo, setShowMoreInfo] = useState(false);

  const [editEmail] = useState(true);

  // eslint-disable-next-line
  const updateFSNow = useCallback(
    debounce((firebase, id, field, value) => {
      // if (specialEvent) {
      //   if (!canEdit) return;
      // }
      firebase
        .firestore()
        .collection("StaffData")
        .doc(id)
        .update({ [field]: value }, { merge: true });
    }, 600),
    []
  );

  const [addContactButtonText, setAddContactButtonText] =
    useState("Add Contact");

  return (
    <div style={containerStyle}>
      {selectedStaff ? (
        <>
          {checkURL(avatar) ? (
            <img
              src={avatar || ""}
              style={{ ...iconStyle, cursor: "pointer" }}
              id="avatar"
              onLoad={(e) => {}}
              alt="profile"
              onClick={() => {
                setOpenModal(true);
                document.getElementById("profilePicture").click();
              }}
            />
          ) : isLoggedInUser(staff, user) ? (
            <UploadProfilePicture
              iconStyle={iconStyle}
              firebase={firebase}
              staff={staff}
              setOpenModal={setOpenModal}
              setUploadNewProfilePicButtonOpacity={
                setUploadNewProfilePicButtonOpacity
              }
            />
          ) : (
            <Avatar
              style={iconStyle}
              variant="circular"
              alt={`${staff.firstName} ${staff.lastName}`}
              src={staff.avatar}
              sx={{ width: 100, height: 100, fontSize: "3rem" }}
            />
          )}
          <UploadProfilePictureModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            firebase={firebase}
            staff={staff}
            setAvatar={setAvatar}
            uploadNewProfilePicButtonOpacity={uploadNewProfilePicButtonOpacity}
            setUploadNewProfilePicButtonOpacity={
              setUploadNewProfilePicButtonOpacity
            }
            updateFSNow={updateFSNow}
          />

          <div>
            <Button
              variant="contained"
              style={{ margin: 10, width: 190, height: 36 }}
              onClick={async () => {
                setAddContactButtonText(<CircularProgress size={25} />);

                let img;
                try {
                  if (avatar) img = await getBase64FromUrl(avatar);
                } catch (e) {
                  console.log(e);
                  console.log("cant load image for some reason");
                }

                const vCard = `BEGIN:VCARD
VERSION:3.0
PRODID:-//Apple Inc.//macOS 12.5.1//EN
N:${lastName};${firstName};;;
FN:${firstName} ${lastName}
preferredName:${preferredName ? preferredName : ""}
TEL;type=IPHONE;type=CELL;type=VOICE;type=pref:${phone}
BDAY:${DateTime.fromISO(new Date(dateOfBirth).toISOString())
                  .setZone("Australia/Sydney")
                  .toFormat("yyyy-MM-dd")}
${img ? `PHOTO;ENCODING=b;TYPE=JPEG:${img.replace(/data.*base64,/, "")}` : ""}
END:VCARD
                `;

                const a = document.createElement("a");
                const file = new Blob([vCard], { type: "text/vcard" });

                a.href = URL.createObjectURL(file);
                a.download = `${firstName} ${lastName}.vcf`;
                a.click();

                URL.revokeObjectURL(a.href);
                setAddContactButtonText("Add Contact");
              }}
            >
              {addContactButtonText === "Add Contact" ? (
                <ContactPageIcon
                  style={{
                    marginRight: 10,
                  }}
                />
              ) : null}
              {addContactButtonText}
            </Button>
          </div>
          <div
            style={{
              display: canView(staff, user) ? "block" : "none",
            }}
          >
            <Button
              variant="contained"
              style={{ margin: 10, width: 190, height: 36 }}
              onClick={downloadPDF}
            >
              <DownloadIcon style={{ marginRight: 10 }} />
              Download PDF
            </Button>
          </div>

          <FormGroup style={{ marginLeft: 15 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={currentlyEmployed || false}
                  onChange={handleCurrentlyEmployedChange}
                />
              }
              label="Currently Employed"
            />
          </FormGroup>
          <div>
            <TextField
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              label="First Name"
              value={firstName || ""}
              onChange={handleFirstNameChange}
              style={textFieldStyle}
              disabled={!canView(staff, user)}
            />
            <TextField
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              label="Last Name"
              value={lastName || ""}
              onChange={handleLastNameChange}
              style={textFieldStyle}
              disabled={!canView(staff, user)}
            />
          </div>
          <div>
            <TextField
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              label="Job Title"
              value={jobTitle || ""}
              onChange={handleJobTitleChange}
              style={textFieldStyle}
              disabled={!canView(staff, user)}
            />
            <TextField
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              label="Phone Number"
              value={phone || ""}
              onChange={handlePhoneNumberChange}
              style={textFieldStyle}
              disabled={true}
              // disabled={!canView(staff, user)}
            />
          </div>
          <div>
            <TextField
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              label="preferredName (If Preferred)"
              value={preferredName || ""}
              onChange={handlePreferredNameChange}
              style={textFieldStyle}
              disabled={!canView(staff, user)}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date of Birth"
                format="DD/MM/YYYY"
                value={dateOfBirth || null}
                onChange={handleDateOfBirthChange}
                onInput={() => {}}
                sx={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  marginTop: "20px",
                  width: textFieldStyleWidth,
                }}
                disabled={!canView(staff, user)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: "filled",
                    InputProps: { disableUnderline: true },
                  },
                }}
              />
            </LocalizationProvider>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: 10,
              marginRight: 10,
              marginTop: 20,
            }}
          >
            <TextField
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              label="Email"
              value={email || ""}
              onChange={handleEmailChange}
              style={{
                width: canView(staff, user)
                  ? textFieldStyleWidth * 2 + 110 - 95
                  : textFieldStyleWidth * 2 + 110,
              }}
              disabled={!editEmail}
            />
            {/* {canView(staff, user) ? (
              !editEmail ? (
                <Button
                  variant="contained"
                  style={{ marginLeft: 15, width: 80 }}
                  onClick={() => {
                    setEditEmail(true);
                  }}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  variant="contained"
                  style={{ marginLeft: 15, width: 80 }}
                  onClick={async () => {
                    if (canView(staff, user)) {
                      await firebase
                        .auth()
                        .currentUser.updateEmail(email)
                        .catch((e) => {
                          alert("Screenshot this and show to Alex", e);
                          setEditEmail(false);
                        });
                      updateFSNow(firebase, staff.id, "email", email);
                      setEditEmail(false);
                    }
                  }}
                >
                  Save
                </Button>
              )
            ) : null} */}
          </div>
          <div
            style={{
              display: "flex",
              marginTop: 25,
              flexDirection: "column",
            }}
          >
            <p>Working With Childrens Check</p>
            <TextField
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              label="Check Number"
              sx={{
                marginTop: "20px",
                width: canView(staff, user)
                  ? textFieldStyleWidth * 2 + 20 - 95
                  : textFieldStyleWidth * 2 + 20,
              }}
              value={wwccCheckNumber || ""}
              disabled={!canView(staff, user)}
              onChange={(e) => {
                // if (isCurrentUserAdmin(user) || isCurrentUserSuperAdmin(user)) {
                setWwccCheckNumber(e.target.value);
                updateFSNow(
                  firebase,
                  staff.id,
                  "wwccCheckNumber",
                  e.target.value
                );
                // } else console.log("stop.");
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Expiry Date"
                format="DD/MM/YYYY"
                value={wwccExpiry || null}
                onChange={(e) => {
                  // if (
                  //   isCurrentUserAdmin(user) ||
                  //   isCurrentUserSuperAdmin(user)
                  // ) {
                  setWwccExpiry(dayjs(e.toISOString()));
                  updateFSNow(
                    firebase,
                    staff.id,
                    "wwccExpiry",
                    e.toISOString()
                  );
                  // } else console.log("stop.");
                }}
                sx={{
                  marginTop: "20px",
                  width: canView(staff, user)
                    ? textFieldStyleWidth * 2 + 20 - 95
                    : textFieldStyleWidth * 2 + 20,
                }}
                disabled={!canView(staff, user)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: "filled",
                    InputProps: { disableUnderline: true },
                  },
                }}
              />
            </LocalizationProvider>
          </div>
          {/* </div> */}
          <div style={{ display: "flex", marginTop: 30 }}>
            {isCurrentUserAdmin(user) || isCurrentUserSuperAdmin(user) ? (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAdmin || false}
                      onChange={handleIsAdminChange}
                    />
                  }
                  label="Admin"
                />
              </FormGroup>
            ) : null}

            <FormGroup style={{ marginLeft: 15 }}>
              {isCurrentUserSuperAdmin(user) ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSuperAdmin || false}
                      onChange={handleIsSuperAdminChange}
                    />
                  }
                  label="Super Admin"
                />
              ) : null}
            </FormGroup>
          </div>
          {canView(staff, user) ? (
            <Button
              variant="contained"
              style={{ marginTop: 30 }}
              onClick={() => {
                setShowMoreInfo(!showMoreInfo);
              }}
            >
              Show More Info
            </Button>
          ) : null}
          {canView(staff, user) && showMoreInfo ? (
            <>
              <h4 style={{ marginTop: 30, marginBottom: 10 }}>Address</h4>
              <div>
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label="Street Number"
                  value={streetNumber || ""}
                  onChange={handleStreetNumberChange}
                  style={textFieldStyle}
                />
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label="Street Name"
                  value={streetName || ""}
                  onChange={handleStreetNameChange}
                  style={textFieldStyle}
                />
              </div>
              <div>
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label="Postcode"
                  value={postcode || ""}
                  onChange={handlePostcodeChange}
                  style={textFieldStyle}
                />
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label="Suburb"
                  value={suburb || ""}
                  onChange={handleSuburbChange}
                  style={textFieldStyle}
                />
              </div>
              <div style={employmentBasisStyle}>
                <FormControl>
                  <FormLabel>Employment Basis</FormLabel>
                  <RadioGroup
                    row
                    value={employmentBasis || ""}
                    onChange={handleEmploymentBasisChange}
                  >
                    <FormControlLabel
                      value="permanent"
                      control={<Radio />}
                      label="Permanent"
                    />
                    <FormControlLabel
                      value="part-time"
                      control={<Radio />}
                      label="Part-Time"
                    />
                    <FormControlLabel
                      value="casual"
                      control={<Radio />}
                      label="Casual"
                    />
                    <FormControlLabel
                      value="freelancer"
                      control={<Radio />}
                      label="Freelancer"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <h4 style={{ marginTop: 30, marginBottom: 10 }}>
                Next of Kin Details
              </h4>
              <div>
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label="Next of Kin 1 Name"
                  value={nextOfKin1Name || ""}
                  onChange={handleNextOfKin1NameChange}
                  style={textFieldStyle}
                />
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label="Next of Kin 1 Phone"
                  value={nextOfKin1Phone || ""}
                  onChange={handleNextOfKin1PhoneChange}
                  style={textFieldStyle}
                />
              </div>
              <div>
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label="Next of Kin 2 Name"
                  value={nextOfKin2Name || ""}
                  onChange={handleNextOfKin2NameChange}
                  style={textFieldStyle}
                />
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label="Next of Kin 2 Phone"
                  value={nextOfKin2Phone || ""}
                  onChange={handleNextOfKin2PhoneChange}
                  style={textFieldStyle}
                />
              </div>

              <h4 style={{ marginTop: 30, marginBottom: 10 }}>Tax Details</h4>
              <div>
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label="Tax File Number"
                  value={taxFileNumber || ""}
                  onChange={handleTaxFileNumberChange}
                  style={textFieldStyle}
                />
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label="Salary / Hourly Rate"
                  value={salaryHourlyRate || ""}
                  onChange={handleSalaryHourlyRateChange}
                  style={textFieldStyle}
                />
              </div>
              <h4 style={{ marginTop: 30, marginBottom: 10 }}>Bank Details</h4>
              <div>
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label="Bank Name"
                  value={bankName || ""}
                  onChange={handleBankNameChange}
                  style={textFieldStyle}
                />
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label="Account Name"
                  value={bankAccountName || ""}
                  onChange={handleBankAccountNameChange}
                  style={textFieldStyle}
                />
              </div>
              <div>
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label="BSB"
                  value={BSB || ""}
                  onChange={handleBSBChange}
                  style={textFieldStyle}
                />
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label="Account Number"
                  value={bankAccountNumber || ""}
                  onChange={handleBankAccountNumberChange}
                  style={textFieldStyle}
                />
              </div>
              <h4 style={{ marginTop: 30, marginBottom: 10 }}>
                Superannuation Fund
              </h4>
              <div>
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label="Provider"
                  value={superannuationProvider || ""}
                  onChange={handleSuperannuationProviderChange}
                  style={textFieldStyle}
                />
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label="Member Number"
                  value={superannuationMemberNumber || ""}
                  onChange={handleSuperannuationMemberNumberChange}
                  style={textFieldStyle}
                />
              </div>
              <div>
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label="Fund USI"
                  value={superannuationFundUSI || ""}
                  onChange={handleSuperannuationFundUSIChange}
                  style={textFieldStyle}
                />
              </div>
            </>
          ) : (
            <></>
          )}
          <div style={{ marginBottom: 50 }}></div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default StaffCard;

const UploadProfilePictureModal = ({
  openModal,
  setOpenModal,
  firebase,
  staff,
  setAvatar,
  uploadNewProfilePicButtonOpacity,
  setUploadNewProfilePicButtonOpacity,
  updateFSNow,
}) => {
  const [theme] = useAtom(themeAtom);
  const [completedCrop, setCompletedCrop] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const [crop, setCrop] = useState();
  // const [uploadButtonText] = useState("Upload");
  const [uploadButtonText, setUploadButtonText] = useState("Upload");

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  const loadFile = (event) => {
    document.getElementById("newProfilePic").src = URL.createObjectURL(
      event.target.files[0]
    );
  };

  const uploadImage = async () => {
    // alert("im broken at the moment");
    //
    setUploadButtonText(<CircularProgress />);
    await new Promise((r) => setTimeout(r, 100));
    const img = previewCanvasRef.current.toDataURL("image/jpeg");
    const blob = await fetch(img).then((res) => res.blob());
    const storageRef = firebase
      .storage()
      .ref()
      .child(`/Staff Profile Pictures/${staff.id}.jpeg`);
    storageRef.put(blob).then(async (snapshot) => {
      const url = await storageRef.getDownloadURL();
      updateFSNow(firebase, staff.id, "avatar", url);
      firebase.auth().currentUser.updateProfile({ photoURL: url });
      setAvatar(url);
      await new Promise((r) => setTimeout(r, 200));
      document.getElementById("profilePicture").value = "";
      document.getElementById("newProfilePic").src = "";
      setOpenModal(false);
      setUploadNewProfilePicButtonOpacity(0);
      setCrop(null);
      setCompletedCrop(null);
      setUploadButtonText("Upload");
    });
    return;
  };
  const closeModal = () => {
    setOpenModal(false);
    setCrop(null);
    setCompletedCrop(null);
    setUploadNewProfilePicButtonOpacity(0);
  };
  function onImageLoad(e) {
    setTimeout(() => {
      setUploadNewProfilePicButtonOpacity(1);
    }, 4000);

    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          // You don't need to pass a complete crop into
          // makeAspectCrop or centerCrop.
          unit: "%",
          width: 98,
        },
        1 / 1,
        width,
        height
      ),
      width,
      height
    );

    setCrop(crop);
  }
  return (
    // <AvatarEditor
    //   image={staff.avatar}
    //   width={250}
    //   height={250}
    //   border={50}
    //   color={[255, 255, 255, 0.6]} // RGBA
    //   scale={1.2}
    //   rotate={0}
    // />
    <div
      id="profilePictureModalBG"
      style={{
        display: openModal ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(0,0,0,0.5)",
        zIndex: 10000,
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
      onClick={(e) => {
        if (e.target.id === "profilePictureModalBG") {
          // setOpenModal(false)
        }
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "column",
          width: 400,
          height: 600,
          borderRadius: 10,
          background:
            theme === "dark" ? "rgba(40,40,40,1)" : "rgba(240,240,240,1)",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <input
          style={{ display: "none" }}
          type="file"
          id="profilePicture"
          onChange={loadFile}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {/* <IconButton onClick={closeModal}> */}
          <CloseIcon style={{ fill: "rgba(0,0,0,0)" }} />
          {/* </IconButton> */}
          <span>Please choose a crop</span>
          <IconButton onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </div>
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => {
            setCrop(percentCrop);
          }}
          onComplete={(c) => {
            setCompletedCrop(c);
          }}
          aspect={1 / 1}
        >
          {/* // eslint-disable-next-line */}
          <img
            id="newProfilePic"
            ref={imgRef}
            style={{
              maxWidth: "100%",
              maxHeight: 500,
              minHeight: 80,
              minWidth: 80,
              // cursor: "pointer",
            }}
            src=""
            aria-hidden
            alt="Click to choose new image"
            onLoad={onImageLoad}
            onClick={() => {
              document.getElementById("profilePicture").click();
            }}
          />
        </ReactCrop>
        <div style={{ display: "none" }}>
          {Boolean(completedCrop) && (
            <canvas
              ref={previewCanvasRef}
              style={{
                border: "1px solid black",
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          )}
        </div>
        <Button
          id="uploadNewProfilePicButton"
          style={{
            opacity: uploadNewProfilePicButtonOpacity,
            width: 120,
            height: 50,
            marginTop: 20,
          }}
          onClick={uploadImage}
          variant="contained"
        >
          {uploadButtonText}
        </Button>
      </div>
    </div>
  );
};

const UploadProfilePicture = ({
  iconStyle,
  setOpenModal,
  setUploadNewProfilePicButtonOpacity,
}) => {
  return (
    <div style={{ ...iconStyle }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: 40,
          cursor: "pointer",
        }}
        onClick={() => {
          setOpenModal(true);
          document.getElementById("profilePicture").click();
        }}
      >
        <UploadIcon />
        <span style={{ marginTop: 20 }}>Upload Picture</span>
      </div>
    </div>
  );
};

const isLoggedInUser = (staff, user) => {
  if (user.phoneNumber === staff.phoneNumber) {
    return true;
  }
  if (user.email === staff.email) {
    return true;
  }
  return false;
};
const isCurrentUserAdmin = (user) => {
  if (user?.staffData?.isAdmin === true) {
    return true;
  }
  return false;
};
const isCurrentUserSuperAdmin = (user) => {
  if (user?.staffData?.isSuperAdmin === true) {
    return true;
  }
  return false;
};

const canView = (staff, user) => {
  if (user.phoneNumber === staff.phoneNumber) {
    return true;
  }
  if (user.email === staff.email) {
    return true;
  }
  if (user?.staffData?.isSuperAdmin === true) {
    return true;
  }
  return false;
};

const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};
