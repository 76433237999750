import React, { useState, useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from "@fullcalendar/rrule";

import timeGridPlugin from "@fullcalendar/timegrid";
import enAu from "@fullcalendar/core/locales/en-au";

import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import { use100vh } from "react-div-100vh";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as useMUITheme } from "@mui/material/styles";
import Slide from "@mui/material/Slide";
import CircleIcon from "@mui/icons-material/Circle";
import MenuIcon from "@mui/icons-material/Menu";
import Modal from "@mui/material/Modal";

import EventDataModal from "./EventDataModal";
// import PostProductionContainer from "./PostProductionContainer";
import FiltersContainer from "./FiltersContainer";
import ConfirmationEmailContainer from "./Event Data Modal Components/ConfirmationEmailContainer";
import FirstConfirmationEmailContainer from "./Event Data Modal Components/FirstConfirmationEmailContainer";
import "./calendar.css";
import MobileMenuContainer from "./MobileMenuContainer";
// import LoadingFullScreen from "./LoadingFullScreen";
import { useSwipeable } from "react-swipeable";

import ExportDatesModal from "./ExportDatesModal";

import { RRule } from "rrule";
import { v4 as uuidv4 } from "uuid";

import { createGlobalStyle } from "styled-components";

import { useAtom } from "jotai";
import {
  isIdleAtom,
  showEveryJobAtom,
  newJobObjectAtom,
  themeAtom,
  isElectronAtom,
  isMobileAtom,
  isMobileBrowserAtom,
  calendarColoursAtom,
  calendarEventsAtom,
  isDebouncingAtom,
  exportDatesModalOpenAtom,
  eventDataModalOpenAtom,
  isAdminAtom,
  showWeekendsAtom,
  queryFilterAtom,
  currentStaffDataAtom,
} from "../-Atoms";

import { subscribe, unsubscribe } from "../GlobalFunctions/customEvent";

import DockFunctions from "../GlobalFunctions/DockFunctions";

const confetti = require("canvas-confetti");

const { DateTime } = require("luxon");

const crypto = require("crypto");

const studios = [
  "Studio 1",
  "Studio 2A",
  "Studio 2B",
  "Studio 2C",
  "Studio 3",
  "Studio 4",
  "Staff",
  "Critical Events",
  "Post Production",
  "Adam",
  "Location",
];

const jobTypes = [
  "Misc (-M)",
  "Creative Life (-LC)",
  "Creative Flatlay (-PC)",
  "Design (-D)",
  "Life (-L)",
  "Location Shoot (-L)",
  "Ghost (-P)",
  "Ghost/Flatlay (-P)",
  "Ghost/Product (-P)",
  "Ghost/Jewellery (-P)",
  "Flatlay (-P)",
  "Hanging Flatlay (-P)",
  "Jewellery (-P)",
  "Product (-P)",
  "Retouching (-R)",
  "Video (-V)",
  "Video Editing (-E)",
];

const eventConfirmationStatus = {
  "not-confirmed": "#cc0000",
  tbc: "#cc0000",
  pending: "#ffa70f",
  confirmed: "#32CD32",
};

// const shouldShowWeekends = () => {
//   const val = JSON.parse(localStorage.getItem("showWeekends"));
//   if (val === null) {
//     localStorage.setItem("showWeekends", true);
//     return true;
//   }
//   return val;
// };

const shouldShowDoubles = () => {
  const val = JSON.parse(localStorage.getItem("showDoubles"));
  if (val === null) {
    localStorage.setItem("showDoubles", false);
    return false;
  }
  return val;
};

const Calendar = ({ firebase, user, anon, newCardID }) => {
  const [theme] = useAtom(themeAtom);
  const [newJobObject] = useAtom(newJobObjectAtom);
  const [isElectron] = useAtom(isElectronAtom);
  const [isMobileBrowser] = useAtom(isMobileBrowserAtom);
  const [isMobile] = useAtom(isMobileAtom);
  const [calendarColours] = useAtom(calendarColoursAtom);
  const [, setCalendarEvents] = useAtom(calendarEventsAtom);
  const [exportDatesModalOpen, setExportDatesModalOpen] = useAtom(
    exportDatesModalOpenAtom
  );
  const [eventDataModalOpen, setEventDataModalOpen] = useAtom(
    eventDataModalOpenAtom
  );
  const [isAdmin] = useAtom(isAdminAtom);

  const { showDock, hideDock } = DockFunctions();

  const [startEndDate, setStartEndDate] = useState({
    startDate: null,
    endDate: null,
  });
  const notificationButtonRef = useRef(null);

  const [currentStaffData] = useAtom(currentStaffDataAtom);

  // const [exportDatesModalOpen, setExportDatesModalOpen] = useState(false);

  // const query = useQuery();
  const history = useHistory();
  const location = useLocation();

  const [showEveryJob, setShowEveryJob] = useAtom(showEveryJobAtom);
  const [eventDataModal, setEventDataModal] = useState({});
  const [currentView, setCurrentView] = useState("timeGridWeek");
  // const [events, setEvents] = useState(
  //   localStorage.getItem("initialevents")
  //     ? JSON.parse(localStorage.getItem("initialevents"))
  //     : []
  // );
  const [events, setEvents] = useState([]);
  // console.log(events);
  const [birthdays, setBirthdays] = useState([]);

  const [isIdle] = useAtom(isIdleAtom);

  const sendNotificationToUID = firebase
    .functions()
    .httpsCallable("sendNotificationToUID");

  const [showDoubles, setShowDoubles] = useState(shouldShowDoubles);
  useEffect(() => {
    const keyDownHandler = (event) => {
      try {
        if (event.key === "Alt") {
          if (!isAdmin) return;
          event.preventDefault();
          localStorage.setItem("showDoubles", !showDoubles);
          setShowDoubles(!showDoubles);
        }
      } catch (e) {
        console.log(e);
      }
      // }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line
  }, [setShowDoubles, showDoubles]);

  const [filteredEvents, setFilteredEvents] = useState([]);
  const [recurringEvents, setRecurringEvents] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [showWeekends, setShowWeekends] = useAtom(showWeekendsAtom);

  const [queryFilter, setQueryFilter] = useAtom(queryFilterAtom);

  const [showStaff, setShowStaff] = useState(true);
  const [broadClientFilter, setBroadClientFilter] = useState(false);

  function isPushNotificationSupported() {
    return "serviceWorker" in navigator && "PushManager" in window;
  }
  // async function askUserPermission() {
  //   return await Notification.requestPermission();
  // }

  function getUserSubscription() {
    //wait for service worker installation to be ready, and then
    return navigator.serviceWorker.ready
      .then(function (serviceWorker) {
        return serviceWorker.pushManager.getSubscription();
      })
      .then(function (pushSubscription) {
        return pushSubscription;
      });
  }

  function registerServiceWorker() {
    return navigator.serviceWorker.register("/sw.js");
  }
  const savePushNotificationSubscriptionToUserData = firebase
    .functions()
    .httpsCallable("savePushNotificationSubscriptionToUserData");
  function createHash(input) {
    const md5sum = crypto.createHash("md5");
    md5sum.update(Buffer.from(input));
    // console.log(md5sum.digest("hex"));
    return md5sum.digest("hex");
    // console.log(input)
    // console.log(md5Hash.default(input))
    // return md5Hash.default(Buffer.from(input))
  }
  const checkRegistered = async () => {
    let res = false;
    console.log("checkRegistered");
    // if (!currentStaffData?.notificationSubscriptions) {
    //   console.log(1);
    //   return false;
    // }

    if (!isPushNotificationSupported()) {
      console.log(2);
      return false;
    }
    if (!("serviceWorker" in navigator)) {
      console.log(3);
      return false;
    }
    console.log(currentStaffData);

    const serviceWorkers = await navigator.serviceWorker.getRegistrations();

    if (serviceWorkers.length === 0) {
      console.log(4);
      return false;
    }

    const subscription = await getUserSubscription();

    const subscriptionId = createHash(JSON.stringify(subscription));
    currentStaffData.notificationSubscriptions.forEach((sub) => {
      if (sub.id === subscriptionId) {
        res = true;
      }
    });
    // console.log(`checkRegistered ${res}`);
    return res;
  };

  const setupNotifications = async () => {
    console.log("setupNotifications");
    const registered = await checkRegistered();
    console.log(`checkRegistered result: ${registered}`);
    if (registered) {
      return;
    }
    if (!isPushNotificationSupported()) {
      console.log("isPushNotificationSupported false??");
      return;
    }
    if (Notification.permission !== "granted") {
      console.log("Notification.permission not granted??");
      return;
    }
    registerServiceWorker();
    const serviceWorker = await navigator.serviceWorker.ready;
    const subscriptionRaw = await serviceWorker.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey:
        "BKluKzkYf9wTIVmdWJMy9szdvr-utCUC5XqlAZgLvcTrqDAuGYynQMFZaHYFLozzyxukkGZ6-FxjGYvUxPv2x7w",
    });
    const subscriptionString = JSON.stringify(subscriptionRaw);
    const subscription = JSON.parse(subscriptionString);
    console.log(subscription);
    const success = await savePushNotificationSubscriptionToUserData({
      subscription: subscription,
    });
    console.log(success);
  };

  const swipeHandlers = useSwipeable({
    onSwipedRight: (eventData) => {
      calendarRef.current.getApi().prev();
      if (isMobileBrowser) {
        setupNotifications();
      }
    },
    onSwipedLeft: (eventData) => {
      calendarRef.current.getApi().next();
      if (isMobileBrowser) {
        setupNotifications();
      }
    },
    preventScrollOnSwipe: true,
  });

  useEffect(() => {
    if (anon) {
      document.title = "ITS Calendar";
    }
  }, [anon]);

  useEffect(() => {
    const main = () => {
      if (!currentStaffData?.id) return;
      if (showEveryJob) {
        // setQueryFilter(null);
      } else {
        if (currentStaffData.isUtility) return;
        if (currentStaffData.isAdmin) return;
        if (currentStaffData.isSuperAdmin) return;
        setQueryFilter({ ...queryFilter, staff: [currentStaffData] });
        return;
      }
    };
    main();
    // eslint-disable-next-line
  }, [user, currentStaffData, setQueryFilter]);

  const [stopModalClose, setStopModalClose] = useState(false);

  useEffect(() => {
    const ele = document.getElementById("calendarRoot");
    if (!ele) return;
    if (eventDataModalOpen) {
      ele.style.filter = "blur(1px)";
    } else {
      ele.style.filter = "";
    }
  }, [eventDataModalOpen]);

  const calendarRef = useRef(null);

  const { search } = useLocation();

  useEffect(() => {
    if (calendarRef) {
      const qs = queryString.parse(search);
      if (qs.jumpto) {
        const api = calendarRef?.current?.getApi();
        try {
          const d = new Date(parseInt(qs.jumpto));
          api.gotoDate(d);
        } catch (e) {}
      }
    }
  }, [search, calendarRef]);

  const height = use100vh();

  const MUITheme = useMUITheme();
  // const isMobile = useMediaQuery("@media (max-width:780px)");
  const isMidSize = useMediaQuery(MUITheme.breakpoints.down("lg"));
  // const isMobile = useMediaQuery(MUITheme.breakpoints.down("sm"));
  // const isMobile = useMediaQuery(MUITheme.breakpoints.down("md"));

  useEffect(() => {
    if (calendarRef) {
      if (!isMobile) {
        calendarRef.current.getApi().changeView("timeGridWeek");
      } else {
        calendarRef.current.getApi().changeView("timeGridDay");
      }
    }
  }, [isMobile, calendarRef]);

  useEffect(() => {
    const handleCalendarPrevious = () => {
      calendarRef.current.getApi().prev();
    };
    const handleCalendarNext = () => {
      calendarRef.current.getApi().next();
    };
    subscribe("calendarPrevious", handleCalendarPrevious);
    subscribe("calendarNext", handleCalendarNext);

    return () => {
      unsubscribe("calendarPrevious", handleCalendarPrevious);
      unsubscribe("calendarNext", handleCalendarNext);
    };
  }, [calendarRef]);

  useEffect(() => {
    // console.log("StaffData Birthdays");
    let unsub = firebase
      .firestore()
      .collection("StaffData")
      .orderBy("firstName", "asc")
      .onSnapshot(
        (collection) => {
          const sd = collection.docs.map((staff) => {
            const staffDataRaw = staff.data();
            const data = {};
            data.id = staff.id;
            data.email = staffDataRaw.email;
            data.firstName = staffDataRaw.firstName;
            data.lastName = staffDataRaw.lastName;
            data.isUtility = staffDataRaw.isUtility;
            data.isAdmin = staffDataRaw.isAdmin;
            data.isSuperAdmin = staffDataRaw.isSuperAdmin;
            data.preferredName = staffDataRaw.preferredName;
            data.avatar = staffDataRaw.avatar;
            data.dateOfBirth = staffDataRaw.dateOfBirth;
            data.currentlyEmployed = staffDataRaw.currentlyEmployed;
            data.wwccCheckNumber = staffDataRaw.wwccCheckNumber;
            data.wwccExpiry = staffDataRaw.wwccExpiry;
            return data;
            // if (staffDataRaw.currentlyEmployed) {
            //   return data;
            // } else {
            //   return false;
            // }
          });
          let newSd = [];
          let bdays = [];
          sd.forEach((s) => {
            // console.log(startEndDate);
            // let year =
            // if (startEndDate.startDate) {
            // }
            //Birthdays
            const bDayObject = {
              id: `${s.id}-Birthday`,
              studio: "----",
              isBirthday: true,
              photographer: s.id,
              // background:
              //   "linear-gradient(90deg, rgba(226,156,228,1) 0%, rgba(171,240,243,1) 100%)",
              borderColor: "#900f70",
              textColor: "#900f70",
              allDay: true,
              title: `🎂 ${
                s.preferredName ? s.preferredName : s.firstName
              }'s Birthday 🎂`,
              startOrig: s.dateOfBirth,
            };
            if (s) newSd.push(s);
            if (
              s.dateOfBirth &&
              s.currentlyEmployed &&
              typeof s.dateOfBirth === "string"
            ) {
              bdays.push({
                ...bDayObject,
                rrule: {
                  freq: RRule.YEARLY,
                  interval: 1,
                  dtstart: s.dateOfBirth,
                },
              });
              const thisYear = DateTime.now().setZone("Australia/Sydney").year;
              const theBirthday = DateTime.fromISO(s.dateOfBirth);
              const newBirthday = theBirthday.set({ year: thisYear });
              const newBirthdayDayOfWeek = newBirthday.toLocaleString({
                weekday: "short",
              });
              if (newBirthdayDayOfWeek === "Sat") {
                const tmp = newBirthday
                  .minus({ days: 1 })
                  .setZone("Australia/Sydney")
                  .toUTC()
                  .toISO();
                bdays.push({
                  ...bDayObject,
                  startOrig: tmp,
                  start: tmp,
                  title: `🎂 ${
                    s.preferredName ? s.preferredName : s.firstName
                  }'s Birthday (Sat) 🎂`,
                });
              } else if (newBirthdayDayOfWeek === "Sun") {
                const tmp = newBirthday
                  .minus({ days: 2 })
                  .setZone("Australia/Sydney")
                  .toUTC()
                  .toISO();
                bdays.push({
                  ...bDayObject,
                  startOrig: tmp,
                  start: tmp,
                  title: `🎂 ${
                    s.preferredName ? s.preferredName : s.firstName
                  }'s Birthday (Sun) 🎂`,
                });
              }
            }
            // if (s.email === user?.email) {
            //   setCurrentUser(s);
            // }
          });
          setStaffData([...newSd]);

          setBirthdays(bdays);
        },
        (error) => {
          console.log("Snapshot Error - StaffData Birthdays", error);
          alert("Snapshot Error - StaffData Birthdays", error);
        }
      );
    // .catch((e) => {
    //   console.log(e);
    // });

    // if (isIdle) {
    //   unsub();
    // }

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [firebase, user, isIdle, startEndDate]);

  useEffect(() => {
    // console.log("ClientDatabase");
    let unsub = firebase
      .firestore()
      .collection("ClientDatabase")
      .orderBy("brand", "asc")
      .onSnapshot(
        (collection) => {
          const cd = collection.docs.map((client) => {
            const data = client.data();
            data.id = client.id;
            return data;
          });
          setClientData([
            { id: "addNewClient", brand: "Add New Client..." },
            // { id: "itsClient", brand: "Images That Sell" },
            ...cd,
          ]);
        },
        (error) => {
          console.log("Snapshot Error - ClientDatabase", error);
          alert("Snapshot Error - ClientDatabase", error);
        }
      );
    // .catch((e) => {
    //   console.log(e);
    // });
    // if (isIdle) {
    //   unsub();
    // }
    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [firebase, isIdle]);

  useEffect(() => {
    let unsub;
    const main = () => {
      if (startEndDate === null) return;
      unsub = firebase
        .firestore()
        .collection("JobData")
        .where("bookedStart", ">=", startEndDate.startDate)
        .where("bookedStart", "<=", startEndDate.endDate)
        .orderBy("bookedStart", "desc")
        .onSnapshot(
          async (collection) => {
            const jd = collection.docs.map((job) => {
              const data = job.data();

              const event = { extendedProps: { ...data } };
              if (!data.shootDate) {
                return false;
              }
              if (data.recurringEvent) return false;

              if (!data.bookedStart || !data.bookedFinish) return false;
              if (data.allDay) {
                event.title = data.title;

                event.start = parseStartEndTime(
                  data.bookedStart,
                  data.bookedFinish,
                  data.shootDate,
                  "startDate",
                  data
                );
                event.end = parseStartEndTime(
                  data.bookedStart,
                  data.bookedFinish,
                  data.shootDate,
                  "endDate",
                  data
                );
                if (data.studio === "N/A") {
                  console.log(data);
                }
                if (calendarColours[data.studio]) {
                  event.backgroundColor = data.studio
                    ? theme === "dark"
                      ? calendarColours[data.studio].darkBackground || "#000000"
                      : calendarColours[data.studio].background || "#000000"
                    : theme === "dark"
                    ? calendarColours["Studio 1"].darkBackground || "#000000"
                    : calendarColours["Studio 1"].background || "#000000";
                  event.borderColor = data.studio
                    ? theme === "dark"
                      ? calendarColours[data.studio].darkBackground || "#000000"
                      : calendarColours[data.studio].background || "#000000"
                    : theme === "dark"
                    ? calendarColours["Studio 1"].darkBackground || "#000000"
                    : calendarColours["Studio 1"].background || "#000000";
                  event.boxShadow = "0px 0px 0px 0px #fff !important";
                  event.textColor = data.studio
                    ? theme === "dark"
                      ? calendarColours[data.studio].darkText || "#000000"
                      : calendarColours[data.studio].text || "#000000"
                    : theme === "dark"
                    ? calendarColours["Studio 1"].darkBackground || "#000000"
                    : calendarColours["Studio 1"].background || "#000000";
                } else {
                  event.backgroundColor = "#000000";
                  event.borderColor = "#000000";
                  event.boxShadow = "0px 0px 0px 0px #fff !important";
                  event.textColor = "#000000";
                }
                event.id = job.id;
                event.allDay = true;
                if (data.recurringEvent) {
                  event.rrule = data.rrule;
                }
              } else {
                if (data.recurringEvent) {
                  event.rrule = data.rrule;
                }
                event.title = `${data.client} ${data.jobTypeFull ? "-" : ""} ${
                  data.jobTypeFull || ""
                } `;
                event.start = parseStartEndTime(
                  data.bookedStart,
                  data.bookedFinish,
                  data.shootDate,
                  "startDate",
                  data
                );
                event.end = parseStartEndTime(
                  data.bookedStart,
                  data.bookedFinish,
                  data.shootDate,
                  "endDate",
                  data
                );

                event.backgroundColor = data.studio
                  ? theme === "dark"
                    ? calendarColours[data.studio].darkBackground || "#000000"
                    : calendarColours[data.studio].background || "#000000"
                  : theme === "dark"
                  ? calendarColours["Studio 1"].darkBackground || "#000000"
                  : calendarColours["Studio 1"].background || "#000000";
                event.borderColor = data.studio
                  ? theme === "dark"
                    ? calendarColours[data.studio].darkBackground || "#000000"
                    : calendarColours[data.studio].background || "#000000"
                  : theme === "dark"
                  ? calendarColours["Studio 1"].darkBackground || "#000000"
                  : calendarColours["Studio 1"].background || "#000000";
                event.boxShadow = "0px 0px 0px 0px #fff !important";
                event.textColor = data.studio
                  ? theme === "dark"
                    ? calendarColours[data.studio].darkText || "#000000"
                    : calendarColours[data.studio].text || "#000000"
                  : theme === "dark"
                  ? calendarColours["Studio 1"].darkBackground || "#000000"
                  : calendarColours["Studio 1"].background || "#000000";
                event.id = job.id;
                event.allDay = false;
              }

              return event;
            });

            const jdUpdated = [];
            jd.forEach((j) => {
              if (j) jdUpdated.push(j);
              if (j?.extendedProps?.additionalDates) {
                j.extendedProps.additionalDates.forEach((ad, index) => {
                  if (ad.start && ad.end) {
                    const newJ = {};
                    const keys = Object.keys(j);
                    keys.forEach((key) => {
                      newJ[key] = j[key];
                    });
                    newJ.start = ad.start;
                    newJ.end = ad.end;
                    newJ.isAdditionalDate = true;
                    newJ.additionalDateIndex = index;
                    newJ.title = `🔗 ${newJ.title}`;
                    newJ.editable = false;
                    newJ.extendedProps = { ...j.extendedProps };
                    newJ.extendedProps.photographer = ad.photographer;
                    newJ.extendedProps.additionalPhotographers =
                      ad.additionalPhotographers;
                    newJ.extendedProps.operator = ad.operator;
                    newJ.extendedProps.additionalOperators =
                      ad.additionalOperators;
                    newJ.extendedProps.stylist = ad.stylist;
                    newJ.extendedProps.additionalStylists =
                      ad.additionalStylists;

                    jdUpdated.push(newJ);
                  }
                });
              }
            });

            // setRecurringEvents
            // console.log(recurringEvents);
            // console.log(recurringEvents)
            // console.log(jdUpdated);

            // setEvents([...jdUpdated, ...recurringEvents]);
            // setCalendarEvents([...jdUpdated, ...recurringEvents]);
            setEvents([...jdUpdated]);
            setCalendarEvents([...jdUpdated]);

            // console.log(collection.docs.length);
            setTimeout(() => {
              setLoadingVisible(false);
            }, 500);
          },
          (error) => {
            console.log("Snapshot Error - JobData", error);
            alert("Snapshot Error - JobData", error);
          }
        );
    };
    main();
    // if (isIdle) {
    //   try {
    //     unsub();
    //   } catch (e) {
    //     console.log("no unsub");
    //   }
    // }
    return () => {
      try {
        unsub();
      } catch (e) {
        console.log("no unsub");
      }
    };
  }, [
    firebase,
    startEndDate,
    isIdle,
    theme,
    setCalendarEvents,
    calendarColours,
  ]);
  // }, [firebase, startDate, endDate, isIdle]);

  useEffect(() => {
    let unsub;
    const main = async () => {
      // const recurringTmp = [];
      unsub = firebase
        .firestore()
        .collection("JobData")
        .where("recurringEvent", "==", true)
        .onSnapshot(
          async (collection) => {
            const jd = collection.docs.map((re) => {
              const data = re.data();
              data.id = re.id;
              const event = { extendedProps: { ...data } };
              event.title = data.title;
              event.allDay = true;
              event.backgroundColor =
                theme === "dark"
                  ? calendarColours[data.studio].darkBackground || "#000000"
                  : calendarColours[data.studio].background || "#000000";
              event.borderColor =
                theme === "dark"
                  ? calendarColours[data.studio].darkBackground || "#000000"
                  : calendarColours[data.studio].background || "#000000";
              event.boxShadow = "0px 0px 0px 0px #fff !important";
              event.textColor = data.studio
                ? theme === "dark"
                  ? calendarColours[data.studio].darkText || "#000000"
                  : calendarColours[data.studio].text || "#000000"
                : null;
              event.start = parseStartEndTime(
                data.bookedStart,
                data.bookedFinish,
                data.shootDate,
                "startDate",
                data
              );
              event.end = parseStartEndTime(
                data.bookedStart,
                data.bookedFinish,
                data.shootDate,
                "endDate",
                data
              );
              if (data.recurringEvent) {
                event.rrule = data.rrule;
                if (data.exdate) {
                  event.exdate = data.exdate;
                }
              }
              return event;
            });
            // console.log(jd);
            setRecurringEvents([...jd]);
          },
          (error) => {
            console.log("Snapshot Error - RecurringEvents", error);
            alert("Snapshot Error - RecurringEvents", error);
          }
        );
    };
    main();
    // if (isIdle) {
    //   try {
    //     unsub();
    //   } catch (e) {
    //     console.log("no unsub");
    //   }
    // }
    return () => {
      try {
        unsub();
      } catch (e) {
        console.log("no unsub");
      }
    };
  }, [firebase, isIdle, calendarColours, theme]);

  const addAllDayEvents = (array, arrayToUpdate) => {
    const staffAllDay = [];
    array.forEach((j) => {
      //   console.log(j);
      if (j) {
        if (!j.allDay) {
          const date = new Date(j.start);
          date.setHours(0);
          date.setMinutes(0);
          const obj = {
            shootDate: date,
            start: j.start,
            end: j.end,
            studio: j.extendedProps.studio,
            photographer: j.extendedProps.photographer,
            additionalPhotographers: j.extendedProps.additionalPhotographers,
            operator: j.extendedProps.operator,
            additionalOperators: j.extendedProps.additionalOperators,
            stylist: j.extendedProps.stylist,
            additionalStylists: j.extendedProps.additionalStylists,
            allDay: true,
            id: j.extendedProps.id || j.id,
            backgroundColor: j.extendedProps.studio
              ? theme === "dark"
                ? calendarColours[j.extendedProps.studio].darkBackground ||
                  "#000000"
                : calendarColours[j.extendedProps.studio].background ||
                  "#000000"
              : null,
            textColor: j.extendedProps.studio
              ? theme === "dark"
                ? calendarColours[j.extendedProps.studio].darkText || "#000000"
                : calendarColours[j.extendedProps.studio].text || "#000000"
              : null,
            borderColor: j.extendedProps.studio
              ? theme === "dark"
                ? calendarColours[j.extendedProps.studio].darkBackground ||
                  "#000000"
                : calendarColours[j.extendedProps.studio].background ||
                  "#000000"
              : null,
            confirmationStatus: j.extendedProps.confirmationStatus,
            staffAllDay: true,
            editable: false,
            jobTypeFull: j.extendedProps.jobTypeFull,
            eventPrefix: j.extendedProps.eventPrefix,
            eventPrefixCustom: j.extendedProps.eventPrefixCustom,
            display: currentView === "dayGridMonth" ? "none" : "auto",
          };

          const arrayContains = staffAllDay.some((s) => {
            let shootDate = false;
            let photographer = false;
            let operator = false;
            let stylist = false;
            let studio = false;
            if (s.shootDate.toString() === obj.shootDate.toString()) {
              shootDate = true;
            }
            if (s.photographer === obj.photographer) {
              photographer = true;
            }
            if (s.operator === obj.operator) {
              operator = true;
            }
            if (s.stylist === obj.stylist) {
              stylist = true;
            }
            if (s.studio === obj.studio) {
              studio = true;
            }
            if (shootDate && photographer && operator && stylist && studio) {
              return true;
            }
            return false;
          });
          if (!arrayContains) {
            staffAllDay.push(obj);
          }
        }
      }
    });
    staffAllDay.forEach((s) => {
      if (s) {
        if (s.studio !== "Critical Events") {
          if (s.studio !== "Post Production") {
            if (s.studio !== "Staff") {
              if (s.studio !== "Adam") {
                arrayToUpdate.push(s);
              }
            }
          }
        }
      }
    });
  };

  useEffect(() => {
    if (!queryFilter) {
      let tmpArr = [];
      addAllDayEvents(events, tmpArr);
      setFilteredEvents([...events, ...tmpArr]);
      return;
    }
    let tmpArrUpdated = [];

    const filterStaff = (event, queryFilter) => {
      if (!queryFilter?.staff) return true;
      if (queryFilter?.staff?.length === 0) return true;
      let returnValue = false;
      queryFilter.staff.forEach((filter) => {
        if (event.extendedProps.studio === "Staff") returnValue = true;
        if (
          event.extendedProps.studio === "Critical Events" ||
          event.extendedProps.studio === "Post Production"
        ) {
          if (!event.extendedProps.photographer) returnValue = true;
          if (event.extendedProps.photographer === queryFilter.staff) {
            returnValue = true;
          }
          returnValue = false;
        }

        if (event.extendedProps.photographer === filter.id) {
          returnValue = true;
        }
        if (event.extendedProps.stylist === filter.id) {
          returnValue = true;
        }
        if (event.extendedProps.operator === filter.id) {
          returnValue = true;
        }
        if (event.extendedProps.additionalPhotographers?.includes(filter.id)) {
          returnValue = true;
        }
        if (event.extendedProps.additionalOperators?.includes(filter.id)) {
          returnValue = true;
        }
        if (event.extendedProps.additionalStylists?.includes(filter.id)) {
          returnValue = true;
        }
      });
      return returnValue;
    };

    const filterStudio = (event, queryFilter) => {
      if (!queryFilter.studio) return true;
      if (queryFilter.studio.includes("All Studios")) return true;
      if (queryFilter.studio.includes(event.extendedProps.studio)) return true;
      return false;
    };

    const filterClient = (event, queryFilter) => {
      if (!queryFilter.client) return true;

      if (broadClientFilter) {
        queryFilter.client.forEach((cl) => {
          if (event.extendedProps.client.includes(cl)) {
            return true;
          }
        });
      } else {
        if (queryFilter.client.includes(event.extendedProps.client)) {
          return true;
        }
      }
    };
    const tmpArr = events.filter((event) => {
      return (
        filterStaff(event, queryFilter) &&
        filterStudio(event, queryFilter) &&
        filterClient(event, queryFilter)
      );
    });
    addAllDayEvents(tmpArr, tmpArrUpdated);
    setFilteredEvents([...tmpArr, ...tmpArrUpdated]);
    // eslint-disable-next-line
  }, [queryFilter, events, broadClientFilter]);

  const toggleFilters = () => {
    if (showEveryJob) {
      if (currentStaffData?.id) {
        if (currentStaffData.isUtility) {
          setQueryFilter(null);
          return;
        }
        if (currentStaffData.isAdmin) {
          setQueryFilter(null);
          return;
        }
        if (currentStaffData.isSuperAdmin) {
          setQueryFilter(null);
          return;
        }
        setQueryFilter({ staff: [currentStaffData] });
        setShowEveryJob(false);
        firebase
          .firestore()
          .collection("StaffData")
          .doc(currentStaffData.id)
          .update({ showEveryJob: false });
        return;
      }
    } else {
      setQueryFilter(null);
      setShowEveryJob(true);
      firebase
        .firestore()
        .collection("StaffData")
        .doc(currentStaffData.id)
        .update({ showEveryJob: true });
    }
  };

  // const adminToolbar = {
  //   left: "exportDates postProduction",
  // };
  const desktopToolbar = {
    left: "title",
    // left: " ",
    center: "dayGridMonth,timeGridWeek,timeGridDay",
    right: `filters toggleWeekends prev,today,next`,
  };
  const mobileToolbar = {
    left: "title",
    center: "",
    // right: "prev,next mobileMenuButton",
    right: "myJobsButton mobileMenuButton",
  };
  const resetEvent = (event) => {
    calendarRef.current.getApi().unselect();
    event.event.setStart(event.oldEvent.start);
    event.event.setEnd(event.oldEvent.end);
  };
  const createNewEvent = async (event) => {
    if (!currentStaffData) {
      calendarRef.current.getApi().unselect();
      return;
    }
    if (currentStaffData.isUtility) {
      calendarRef.current.getApi().unselect();
      return;
    }
    calendarRef.current.getApi().unselect();
    const startDate = new Date(event.start);
    const endDate = new Date(event.end);
    const startUNIX = startDate.getTime() / 1000;
    const endUNIX = endDate.getTime() / 1000;

    if (endUNIX - startUNIX === 900) if (!event.synthetic) return;

    let bookedStart = event.start.toISOString();
    let bookedFinish = event.end.toISOString();
    let studio = "";

    if (event.allDay) {
      bookedStart = event.startStr;
      bookedFinish = event.endStr;
    }

    if (event.allDay) studio = "Staff";
    if (!currentStaffData.isSuperAdmin) {
      if (!currentStaffData.isAdmin) {
        studio = "Staff";
        if (!event.allDay) return;
      }
    }
    const newEvent = {
      ...newJobObject,
      allDay: event.allDay,
      end: event.end,
      start: event.start,
      shootDate: bookedStart,
      bookedStart: bookedStart,
      bookedFinish: bookedFinish,
      studio: studio,
      year: new Date(event.start).getFullYear(),
      tracking: {
        created: {
          by: currentStaffData.id,
          at: new Date().toISOString(),
        },
        modified: [],
        trelloCardModified: [],
      },
      checklists: [
        {
          name: "Colour Corrections",
          id: uuidv4(),
          checklistItems: [],
        },
        {
          name: "Colour Changes",
          id: uuidv4(),
          checklistItems: [],
        },
        {
          name: "Retouching Notes",
          id: uuidv4(),
          checklistItems: [],
        },
      ],
    };
    const id = await firebase
      .firestore()
      .collection("JobData")
      .add(newEvent)
      .then((doc) => {
        return doc.id;
      });
    console.log(id);
  };
  const handleEventResize = async (event) => {
    if (!currentStaffData) {
      resetEvent(event);
      return;
    }
    if (currentStaffData.isUtility) {
      resetEvent(event);
      return;
    }
    if (!currentStaffData.isAdmin) {
      resetEvent(event);
      return;
    }

    let bookedStart = event.event.start.toISOString();
    let bookedFinish = event.event.end;
    if (event.event.end) bookedFinish = event.event.end.toISOString();

    if (event.event.allDay) {
      bookedStart = event.event.start.toISOString();
      bookedFinish = event.event.end.toISOString();
    }

    const newData = {
      allDay: event.event.allDay,
      end: event.event.end.toISOString(),
      start: event.event.start.toISOString(),
    };

    const oldData = {
      allDay: event.oldEvent.allDay,
      end: event.oldEvent.end.toISOString(),
      start: event.oldEvent.start.toISOString(),
    };

    firebase
      .firestore()
      .collection("JobData")
      .doc(event.event.id)
      .update(
        {
          ...newData,
          shootDate: bookedStart,
          bookedStart: bookedStart,
          bookedFinish: bookedFinish,
        },
        { merge: true }
      )
      .catch((error) => {
        console.log(error);
      });
    firebase
      .firestore()
      .collection("JobData")
      .doc(event.event.id)
      .get()
      .then((jobDataRaw) => {
        const jobData = jobDataRaw.data();
        const tracking = jobData?.tracking || {};
        const modified = tracking.modified || [];
        modified.push({
          kind: "resize",
          prev: oldData,
          new: newData,
          by: currentStaffData.id,
          at: new Date().toISOString(),
        });
        firebase
          .firestore()
          .collection("JobData")
          .doc(event.event.id)
          .set({ tracking: tracking }, { merge: true });
      });

    if (!event.event.extendedProps) return;
    if (!event.event.start) return;
    if (!event.event.extendedProps.client) return;
    if (!event.event.extendedProps.jobTypeFull) return;

    if (event.event.extendedProps.photographer) {
      sendTimeChangeNotification(
        new Date(event.event.start).toISOString(),
        event.event.extendedProps.photographer,
        event.event.extendedProps.client,
        event.event.extendedProps.jobTypeFull,
        isEventTomorrow,
        sendNotificationToUID
      );
    }
  };
  const getNewEnd = (start) => {
    const d = new Date(start);
    d.setMinutes(d.getMinutes() + 30);
    return d.toISOString();
  };
  const handleEventDrop = async (event) => {
    if (!currentStaffData) {
      resetEvent(event);
      return;
    }
    if (currentStaffData.isUtility) {
      resetEvent(event);
      return;
    }
    if (!currentStaffData.isAdmin) {
      resetEvent(event);
      return;
    }
    // if (!currentStaffData.isSuperAdmin) {
    //   resetEvent(event);
    //   return;
    // }
    let bookedStart = event.event.start.toISOString();
    let bookedFinish = event.event.end;
    if (event.event.end) bookedFinish = event.event.end.toISOString();
    if (event.event.allDay) {
      bookedStart = event.event.start.toISOString();
      bookedFinish =
        event?.event?.end?.toISOString() || getNewEnd(event.event.start);
    }
    if (!event.event.end) {
      bookedFinish = new Date(event.event.start.getTime() + 30 * 60000);
    }
    const newData = {
      allDay: event.event.allDay,
      end: event?.event?.end?.toISOString() || getNewEnd(event.event.start),
      start: event.event.start.toISOString(),
    };
    const oldData = {
      allDay: event.oldEvent.allDay,
      end:
        event?.oldEvent?.end?.toISOString() || getNewEnd(event.oldEvent.start),
      start: event.oldEvent.start.toISOString(),
    };

    firebase
      .firestore()
      .collection("JobData")
      .doc(event.event.id)
      .update(
        {
          ...newData,
          shootDate: bookedStart,
          bookedStart: bookedStart,
          bookedFinish: bookedFinish,
        },
        { merge: true }
      )
      .catch((error) => {
        console.log(error);
      });
    firebase
      .firestore()
      .collection("JobData")
      .doc(event.event.id)
      .get()
      .then((jobDataRaw) => {
        const jobData = jobDataRaw.data();
        const tracking = jobData?.tracking || {};
        const modified = tracking.modified || [];
        modified.push({
          kind: "move",
          prev: oldData,
          new: newData,
          by: currentStaffData.id,
          at: new Date().toISOString(),
        });
        firebase
          .firestore()
          .collection("JobData")
          .doc(event.event.id)
          .set({ tracking: tracking }, { merge: true });
      });
  };

  let clicks = 0;
  let timer = setTimeout(() => {}, 500);
  let dateClicked = "";
  const resetClicks = () => {
    clicks = 0;
    clearTimeout(timer);
    dateClicked = "";
  };

  const [postProductionDrawerOpen, setPostProductionDrawerOpen] =
    useState(false);
  const postProductionButtonClick = () => {
    setPostProductionDrawerOpen(!postProductionDrawerOpen);
  };
  const parseCalendarWidth = () => {
    if (postProductionDrawerOpen) {
      if (!isMobile) {
        return "calc(99.5vw - 25px - 35vw)";
      }
    } else return "99.5vw";
  };
  const [filtersMenuVisible, setFiltersMenuVisible] = useState(false);
  const handleFiltersMenuClick = (event) => {
    setFiltersMenuVisible(!filtersMenuVisible);
  };
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handleMobileMenuButtonClick = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const [, setLoadingVisible] = useState(false);

  const [confirmationStatusEmailOpen, setConfirmationStatusEmailOpen] =
    useState(false);

  const [confirmationEmailData, setConfirmationEmailData] = useState({});

  useEffect(() => {
    if (!eventDataModalOpen) {
      setConfirmationEmailData({});
    }
    // eslint-disable-next-line
  }, [confirmationStatusEmailOpen, eventDataModalOpen]);

  const [
    firstConfirmationStatusEmailOpen,
    setFirstConfirmationStatusEmailOpen,
  ] = useState(false);

  // const bgRand = 3333

  const adamFace = 42;
  const adamImage =
    "https://firebasestorage.googleapis.com/v0/b/its-connect-main.appspot.com/o/Staff%20Profile%20Pictures%2FCLJqPv7r8yHYwUv87TpH.jpeg?alt=media&token=e2e23596-5745-4a8d-b67b-820dc81086dc";
  const soriahface = 3333;
  const soriahImage =
    "https://firebasestorage.googleapis.com/v0/b/its-connect-main.appspot.com/o/Random%20Images%2FIMG_2040.jpg?alt=media&token=29d981c9-b621-4f43-b7d3-2427a9d0b6b1";

  const bgRand = randomIntFromInterval(
    1,
    20000,
    firebase,
    [adamFace, soriahface],
    isMobile
  );

  const GlobalStyles = createGlobalStyle`
  :root {
    --fc-button-text-color: #fff;
    --fc-button-bg-color: #${theme === "dark" ? "202733" : "485773"};
    --fc-button-border-color: #${theme === "dark" ? "202733" : "485773"};
    --fc-button-hover-bg-color: #${theme === "dark" ? "283140" : "404d66"};
    --fc-button-hover-border-color: #${theme === "dark" ? "283140" : "404d66"};
    --fc-button-active-bg-color: #${theme === "dark" ? "2b3545" : "3d4a61"};
    --fc-button-active-border-color: #${theme === "dark" ? "2b3545" : "3d4a61"};
  }
  ::-webkit-scrollbar {
    z-index:999999999999999;
    background-color: rgba(0, 0, 0, 0);
    width: ${bgRand === adamFace || bgRand === soriahface ? "60px" : "8px"};
    height: ${bgRand === adamFace || bgRand === soriahface ? "60px" : "8px"};
  }

  ::-webkit-scrollbar-thumb {
    z-index:999999999999999;
    ${
      bgRand === adamFace || bgRand === soriahface
        ? `background-image: url("${
            bgRand === adamFace
              ? adamImage
              : bgRand === soriahface
              ? soriahImage
              : ""
          }");
    background-repeat: no-repeat;
    background-size: 220%;
    background-position: center;`
        : "background-color: rgba(80, 80, 80, 0.6);"
    }
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid #0000004D
  }
  ::-webkit-scrollbar-thumb:hover {
    z-index:999999999999999;
    background-color: rgba(120, 120, 120, 0.6);
  }
  .darkModeBorder td,
  .darkModeBorder th {
    border: 1px solid ${
      theme === "dark" ? "rgba(255, 255, 255, 0.05)" : "#dddddd"
    } !important;
  }
  .fc-footer-toolbar{
    margin-top: 15px !important;
  }

  // code{
  //   background-color: #${theme === "dark" ? "b31214" : "cc474a"} !important;
  //   color: #fafafa !important;
  // }
`;

  useEffect(() => {
    const allDayMaxHeight = () => {
      if (!isMobile) return;
      const elements = Array.from(
        document.getElementsByClassName("fc-scroller-harness")
      );
      elements[1].style.maxHeight = "230px";
      elements[1].style.overflow = "scroll";
    };
    allDayMaxHeight();
  }, [isMobile]);

  const [isDebouncing] = useAtom(isDebouncingAtom);

  return (
    <>
      <GlobalStyles />
      {/* <ThemeProvider theme={darkTheme}> */}
      {/* <LoadingFullScreen loadingVisible={loadingVisible} /> */}
      <ExportDatesModal
        firebase={firebase}
        exportDatesModalOpen={exportDatesModalOpen}
        setExportDatesModalOpen={setExportDatesModalOpen}
        jobTypes={jobTypes}
        studios={studios}
        clientData={clientData}
        staffData={staffData}
        theme={theme}
      />
      <Modal
        open={confirmationStatusEmailOpen}
        onClose={() => {
          setConfirmationStatusEmailOpen(false);
        }}
        closeAfterTransition
      >
        <Slide
          direction="up"
          in={confirmationStatusEmailOpen}
          mountOnEnter
          unmountOnExit
          timeout={200}
        >
          <div
            id="confirmationEmailModalBG"
            style={{
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              height: height,
            }}
            onClick={(e) => {
              if (e.target.id === "confirmationEmailModalBG") {
                setConfirmationStatusEmailOpen(false);
              }
            }}
          >
            <div
              style={{
                width: isMobile ? "90vw" : 750,
                height: "90vh",
                background: "#ffffff",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <ConfirmationEmailContainer
                firebase={firebase}
                confirmationEmailData={confirmationEmailData}
                setConfirmationEmailData={setConfirmationEmailData}
                setConfirmationStatusEmailOpen={setConfirmationStatusEmailOpen}
                isMobile={isMobile}
                theme={theme}
              />
            </div>
          </div>
        </Slide>
      </Modal>
      {/* FirstConfirmationEmailContainer */}
      <Modal
        open={firstConfirmationStatusEmailOpen}
        onClose={() => {
          setFirstConfirmationStatusEmailOpen(false);
        }}
        closeAfterTransition
      >
        <Slide
          direction="up"
          in={firstConfirmationStatusEmailOpen}
          mountOnEnter
          unmountOnExit
          timeout={200}
        >
          <div
            id="confirmationEmailModalBG"
            style={{
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              height: height,
            }}
            onClick={(e) => {
              if (e.target.id === "confirmationEmailModalBG") {
                setFirstConfirmationStatusEmailOpen(false);
              }
            }}
          >
            <div
              style={{
                width: isMobile ? "90vw" : 750,
                height: "90vh",
                background: "#ffffff",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <FirstConfirmationEmailContainer
                firebase={firebase}
                confirmationEmailData={confirmationEmailData}
                setConfirmationEmailData={setConfirmationEmailData}
                setFirstConfirmationStatusEmailOpen={
                  setFirstConfirmationStatusEmailOpen
                }
                isMobile={isMobile}
                theme={theme}
              />
            </div>
          </div>
        </Slide>
      </Modal>

      <Slide
        direction="right"
        in={eventDataModalOpen}
        mountOnEnter
        unmountOnExit
        timeout={isElectron || isMobileBrowser ? 0 : 200}
      >
        <div
          style={{
            width: isMobile ? "100vw" : isMidSize ? "60vw" : "40vw",
            height: height,
            background: "#ffffff",
            zIndex: 1100,
            position: "absolute",
            top: 0,
          }}
        >
          <div>
            <EventDataModal
              firebase={firebase}
              staffData={staffData}
              event={eventDataModal}
              findStaff={findStaff}
              events={events}
              eventDataModalOpen={eventDataModalOpen}
              setEventDataModalOpen={setEventDataModalOpen}
              height={height}
              clientData={clientData}
              calendarColours={calendarColours}
              stopModalClose={stopModalClose}
              setStopModalClose={setStopModalClose}
              currentUser={currentStaffData}
              confirmationStatusEmailOpen={confirmationStatusEmailOpen}
              setConfirmationStatusEmailOpen={setConfirmationStatusEmailOpen}
              firstConfirmationStatusEmailOpen={
                firstConfirmationStatusEmailOpen
              }
              setFirstConfirmationStatusEmailOpen={
                setFirstConfirmationStatusEmailOpen
              }
              setConfirmationEmailData={setConfirmationEmailData}
              isEventTomorrow={isEventTomorrow}
              theme={theme}
              newCardID={newCardID}
              modalWidth={isMobile ? "100vw" : isMidSize ? "60vw" : "40vw"}
            />
          </div>
        </div>
      </Slide>
      <Slide direction="left" in={mobileMenuOpen} mountOnEnter unmountOnExit>
        <div
          style={{
            width: isMobile ? "100vw" : isMidSize ? "60vw" : "40vw",
            height: height,
            background: theme === "dark" ? "#1f1f1f" : "#ffffff",
            zIndex: 1100,
            position: "absolute",
            top: 0,
          }}
        >
          <MobileMenuContainer
            mobileMenuOpen={mobileMenuOpen}
            setMobileMenuOpen={setMobileMenuOpen}
            isMobile={isMobile}
            queryFilter={queryFilter}
            setQueryFilter={setQueryFilter}
            clientData={clientData}
            staffData={staffData}
            studios={studios}
            calendarColours={calendarColours}
            currentUser={currentStaffData}
            calendarRef={calendarRef}
            showWeekends={showWeekends}
            setShowWeekends={setShowWeekends}
            postProductionButtonClick={postProductionButtonClick}
            setExportDatesModalOpen={setExportDatesModalOpen}
          />
        </div>
      </Slide>
      <div
        style={{
          display: eventDataModalOpen ? "block" : "none",
          height: "100%",
          background: "rgba(0,0,0,0.1)",
          zIndex: 1099,
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
        onClick={() => {
          if (stopModalClose) {
            console.log("NO CLOSE");
          }
          if (isDebouncing) return;
          if (!stopModalClose) {
            setEventDataModalOpen(false);
            setTimeout(() => {
              showDock();
            }, 150);
            setEventDataModal({});
            history.push(location.pathname);
          }
        }}
      ></div>
      <FiltersContainer
        filtersMenuVisible={filtersMenuVisible}
        queryFilter={queryFilter}
        setQueryFilter={setQueryFilter}
        clientData={clientData}
        staffData={staffData}
        studios={studios}
        calendarColours={calendarColours}
        currentUser={currentStaffData}
        showStaff={showStaff}
        setShowStaff={setShowStaff}
        broadClientFilter={broadClientFilter}
        setBroadClientFilter={setBroadClientFilter}
        showDoubles={showDoubles}
        setShowDoubles={setShowDoubles}
      />
      <div
        id="calendarRoot"
        style={{
          width: parseCalendarWidth(),
          margin: 0,
          padding: 10,
          // margin: !user ? 0 : 10,
          // padding: !user ? 10 : 0,
        }}
        {...swipeHandlers}
      >
        <FullCalendar
          eventSources={[filteredEvents, recurringEvents, birthdays]}
          // events={filteredEvents}
          // events={filteredEvents}
          height={
            filtersMenuVisible
              ? `calc(${height}px - 90px - 75px)`
              : `calc(${height}px - 90px)`
          }
          // height={
          //   filtersMenuVisible
          //     ? `calc(${height}px ${!user ? "- 25px" : "- 90px"} - 75px)`
          //     : `calc(${height}px ${!user ? "- 25px" : "- 90px"})`
          // }

          ref={calendarRef}
          plugins={[
            dayGridPlugin,
            interactionPlugin,
            timeGridPlugin,
            rrulePlugin,
          ]}
          views={{
            dayGridPlugin: {
              titleFormat: {
                year: "",
                month: "short",
                day: "2-digit",
              },
            },
          }}
          allDaySlot={showStaff}
          droppable={true}
          initialView="timeGridWeek"
          longPressDelay={500}
          selectLongPressDelay={500}
          slotEventOverlap={false}
          slotDuration="00:15:00"
          scrollTime="07:50:00"
          eventOrder={"studio,eventPrefix"}
          eventOrderStrict={true}
          locale={enAu}
          dateClick={(date) => {
            if (currentStaffData.isUtility) return;
            clicks++;
            if (clicks === 1) {
              calendarRef.current.getApi().unselect();
              dateClicked = date.dateStr;
              timer = setTimeout(() => {
                resetClicks();
              }, 300);
            } else {
              if (dateClicked === date.dateStr) {
                let end = new Date(date.date);
                end.setMinutes(end.getMinutes() + 15);
                const obj = {
                  start: date.date,
                  end: end,
                  allDay: false,
                  synthetic: true,
                };
                createNewEvent(obj);
              }
              resetClicks();
            }
          }}
          // dragToCreate={true}
          buttonText={{
            today: "Today",
            month: "Month",
            week: "Week",
            day: "Day",
            list: "List",
          }}
          customButtons={{
            exportDates: {
              text: "Export Dates",
              click: () => {
                setExportDatesModalOpen(true);
              },
            },
            toggleWeekends: {
              text: "Weekends",
              click: () => {
                setShowWeekends(!showWeekends);
                firebase
                  .firestore()
                  .collection("StaffData")
                  .doc(currentStaffData.id)
                  .update({ showWeekends: !showWeekends });
                // localStorage.setItem("showWeekends", !showWeekends);
              },
            },
            postProduction: {
              text: "Post Production",
              click: postProductionButtonClick,
            },
            filters: {
              text: "Filters",
              // click: () => {
              //   alert("ass");
              // },
              click: handleFiltersMenuClick,
            },
            mobileMenuButton: {
              text: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 24,
                    width: 16,
                  }}
                >
                  <MenuIcon sx={{ fontSize: 25 }} />
                </div>
              ),
              click: handleMobileMenuButtonClick,
            },
            myJobsButton: {
              text: "My Jobs",
              click: toggleFilters,
            },
          }}
          headerToolbar={isMobile ? mobileToolbar : desktopToolbar}
          // footerToolbar={currentStaffData.isAdmin && !isMobile ? adminToolbar : null}
          selectable={true}
          selectMirror={true}
          weekends={showWeekends}
          // hiddenDays={[0, 6]}
          nowIndicator={true}
          eventTimeFormat={{
            hour: "numeric",
            minute: "2-digit",
            meridiem: "short",
          }}
          editable={true}
          eventDrop={handleEventDrop}
          eventClick={(e) => {
            if (currentStaffData.isUtility) return;
            onEventClick(e, setEventDataModalOpen, setEventDataModal, hideDock);
            if (isElectron) {
              window.ipcRenderer?.send("setShootWindowBounds");
              window.ipcRenderer?.send(
                "eval",
                `const winBounds = calendarWindow.getBounds();

              previousCalendarWindowBounds = winBounds;
              
              const theScreen = screen.getDisplayNearestPoint({
                x: winBounds.x,
                y: winBounds.y,
              });
              
              const windowNewWidth = 580;
              
              calendarWindow.center();
              
              let newWindowBounds = {
                y: 0,
                width: windowNewWidth,
                height: theScreen.workArea.height,
              };
              
              if (previousShootWindowBounds) {
                newWindowBounds.x = previousShootWindowBounds.x;
                newWindowBounds.y = previousShootWindowBounds.y;
                newWindowBounds.height = previousShootWindowBounds.height;
              }
              
              calendarWindow.setBounds(newWindowBounds, false);
              
              if (!previousShootWindowBounds) calendarWindow.center();
              `
              );
            }
          }}
          eventResize={handleEventResize}
          select={createNewEvent}
          eventContent={(e) =>
            eventContent(e, staffData, filteredEvents, showDoubles)
          }
          datesSet={(info) => {
            setCurrentView(info.view.type);

            let start = new Date(info.start);
            let end = new Date(info.end);
            // start.setDate(start.getDate(start) - 15);
            // end.setDate(end.getDate(end) + 15);
            // setStartEndDateDebounce({
            //   startDate: getFilterDate(start),
            //   endDate: getFilterDate(end),
            // });

            if (startEndDate.startDate === null) {
              start.setDate(start.getDate(start) - 15);
              end.setDate(end.getDate(end) + 15);
              setStartEndDate({
                startDate: getFilterDate(start),
                endDate: getFilterDate(end),
              });
              // setStartEndDateDebounce({
              //   startDate: getFilterDate(start),
              //   endDate: getFilterDate(end),
              // });
              return;
            } else {
              let startContains = false;
              let endContains = false;
              if (
                start > new Date(startEndDate.startDate) &&
                start < new Date(startEndDate.endDate)
              ) {
                startContains = true;
              } else {
                // console.log("loaded", startEndDate.startDate);
                // console.log("visible", getFilterDate(start));
              }
              if (
                end > new Date(startEndDate.startDate) &&
                end < new Date(startEndDate.endDate)
              ) {
                endContains = true;
              } else {
                // console.log("loaded", startEndDate.endDate);
                // console.log("visible", getFilterDate(end));
              }

              if (!startContains || !endContains) {
                start.setDate(start.getDate(start) - 15);
                end.setDate(end.getDate(end) + 15);
                setStartEndDate({
                  startDate: getFilterDate(start),
                  endDate: getFilterDate(end),
                });
                // setStartEndDateDebounce({
                //   startDate: getFilterDate(start),
                //   endDate: getFilterDate(end),
                // });
              }
              // if (!startContains) {
              //   start.setDate(start.getDate(start) - 15);
              //   setStartEndDateDebounce({
              //     startDate: getFilterDate(start),
              //     endDate: startEndDate.endDate,
              //   });
              // }
              // if (!endContains) {
              //   end.setDate(end.getDate(end) + 15);
              //   setStartEndDateDebounce({
              //     startDate: startEndDate.startDate,
              //     endDate: getFilterDate(end),
              //   });
              // }
              // console.log(startContains, endContains);
            }
            // console.log(startEndDate.startDate);
            // start.setDate(start.getDate(start) - 15);
            // end.setDate(end.getDate(end) + 15);
            // setStartEndDateDebounce({
            //   startDate: getFilterDate(start),
            //   endDate: getFilterDate(end),
            // });
            // setStartDateDebounce(getFilterDate(start));
            // setEndDateDebounce(getFilterDate(end));
          }}
          viewClassNames={theme === "dark" ? "darkModeBorder" : ""}
          // CONTEXT MENU HERE
          eventDidMount={(event) => {
            if (
              event.event.extendedProps.studio &&
              !event.event.extendedProps.studio.includes("Studio")
            ) {
              if (
                getTimeDifferenceInHours(
                  event.event.extendedProps.bookedStart,
                  event.event.extendedProps.bookedFinish
                ) < 2
              ) {
                event.el.parentElement.style.width = "100%";
                event.el.parentElement.style.zIndex = "10";
                event.el.parentElement.style.left = "10";
              }
            }
            let zIn = event.el.parentElement.style.zIndex;
            let bg = event.el.style.backgroundColor;
            let borderColour = event.el.style.borderColor;

            event.el.parentElement.onmouseenter = () => {
              event.el.parentElement.style.zIndex = "999999999";
              event.el.style.backgroundColor = setAlphaToOne(bg);
              event.el.style.borderColour = setAlphaToOne(borderColour);
              event.el.parentElement.parentElement.insertAdjacentHTML(
                "beforeend",
                `<div id="bgDarkenElement" style="${
                  theme === "dark"
                    ? "background-color: rgba(0, 0, 0, 0.02);"
                    : "background-color: rgba(0, 0, 0, 0.01);"
                }">&nbsp</div>`
              );
            };

            event.el.parentElement.onmouseleave = () => {
              event.el.parentElement.style.zIndex = zIn;
              event.el.style.backgroundColor = bg;
              event.el.style.borderColour = borderColour;
              try {
                document.getElementById("bgDarkenElement").remove();
              } catch (e) {}
            };
            if (
              event.event.extendedProps.studio &&
              event.event.extendedProps.studio.includes("N/A")
            ) {
              event.el.style.background =
                "repeating-linear-gradient(45deg,hsla(355, 15%, 20%, 0.8),hsla(355, 15%, 20%, 0.8) 10px,hsla(355, 15%, 30%, 0.8) 10px,hsla(355, 15%, 30%, 0.8) 20px)";
              // "repeating-linear-gradient(45deg,hsla(355, 25%, 60%, 0.8),hsla(355, 25%, 60%, 0.8) 10px,hsla(355, 10%, 70%, 0.8) 10px,hsla(355, 10%, 70%, 0.8) 20px)";
            }
            if (event.event.extendedProps.isBirthday) {
              let sameDay = false;
              const now = DateTime.now()
                .setZone("Australia/Sydney")
                .startOf("day");
              const eStart = DateTime.fromISO(
                new Date(event.event.start).toISOString()
              ).startOf("day");

              if (now.ts === eStart.ts) sameDay = true;

              event.el.style.background =
                "linear-gradient(90deg, rgba(226,156,228,1) 0%, rgba(171,240,243,1) 100%)";
              const { top, bottom, left, right } =
                event.el.getBoundingClientRect();

              confetti.default({
                origin: {
                  x: (left + right) / 2 / window.innerWidth,
                  y: (top + 100 + bottom) / 2 / window.innerHeight,
                },
                particleCount: sameDay ? 100 : 2,
                spread: sameDay ? 40 : 10,
                ticks: 250,
              });
            }

            event.el.style.boxShadow = "0px 0px 0px 0px #fff !important";
            event.el.style.marginTop = event.event.allDay ? "0.6px" : "2.5px";
            event.el.style.marginBottom = event.event.allDay
              ? "0.6px"
              : "2.5px";
            event.el.style.marginLeft = "1.5px";
            event.el.style.marginRight = "1.5px";

            event.el.style.borderRadius = "5px";

            if (!currentStaffData) return;
            // console.log(currentStaffData);
            if (!currentStaffData.id) return;
            let border = false;
            if (!event.event.extendedProps.isAdditionalDate) {
              if (
                currentStaffData.id === event.event.extendedProps.photographer
              ) {
                border = true;
              }
              if (currentStaffData.id === event.event.extendedProps.operator) {
                border = true;
              }
              if (currentStaffData.id === event.event.extendedProps.stylist) {
                border = true;
              }
              if (
                event.event.extendedProps.additionalPhotographers?.includes(
                  currentStaffData.id
                )
              ) {
                border = true;
              }
              if (
                event.event.extendedProps.additionalOperators?.includes(
                  currentStaffData.id
                )
              ) {
                border = true;
              }
              if (
                event.event.extendedProps.additionalStylists?.includes(
                  currentStaffData.id
                )
              ) {
                border = true;
              }
            } else {
              let exProps = event.event.extendedProps;
              let theDate =
                exProps.additionalDates[exProps.additionalDateIndex];
              if (currentStaffData.id === theDate.photographer) {
                border = true;
              }
              if (currentStaffData.id === theDate.operator) {
                border = true;
              }
              if (currentStaffData.id === theDate.stylist) {
                border = true;
              }
              if (
                theDate.additionalPhotographers?.includes(currentStaffData.id)
              ) {
                border = true;
              }
              if (theDate.additionalOperators?.includes(currentStaffData.id)) {
                border = true;
              }
              if (theDate.additionalStylists?.includes(currentStaffData.id)) {
                border = true;
              }
            }
            if (border) {
              event.el.style.border = `3px solid ${event.textColor}`;
            }
          }}
        />
      </div>
      {/* <PostProductionContainer
        firebase={firebase}
        isMobile={isMobile}
        open={postProductionDrawerOpen}
        setOpen={setPostProductionDrawerOpen}
        height={height}
        retouchingJobs={retouchingJobs}
        setRetouchingJobs={setRetouchingJobs}
        videoEditingJobs={videoEditingJobs}
        setVideoEditingJobs={setVideoEditingJobs}
        designJobs={designJobs}
        setDesignJobs={setDesignJobs}
        onEventClick={onEventClick}
        currentUser={currentStaffData}
        setEventDataModalOpen={setEventDataModalOpen}
        setEventDataModal={setEventDataModal}
        theme={theme}
      /> */}
      {/* </ThemeProvider> */}
    </>
  );
};
export default Calendar;

const eventContent = (event, staffData, events, showDoubles) => {
  const containerStyle = {
    display: "flex",
    marginLeft: 4,
    marginBottom: 2,
    flexDirection: event.view.type === "dayGridMonth" ? "row" : "column",
    width: "100%",
    height: "100%",
  };
  const notStaffAllDayStyle = {
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
    fontSize: 12,
    flexWrap: "wrap",
  };
  const staffAllDayStyle = {
    fontSize: 11,
    fontFamily:
      '"HelveticaNeue-Bold", "Helvetica Neue Bold", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
  };
  const circleIconStyle = {
    marginRight: 4,
    fill: event.event.extendedProps.confirmationStatus
      ? eventConfirmationStatus[event.event.extendedProps.confirmationStatus]
      : eventConfirmationStatus["tbc"],
  };

  if (event.event.extendedProps.isBirthday) {
    // const birthdayPerson = staffData.find(
    //   (obj) => obj.id === event.event.extendedProps.photographer
    // );
    const age = false;
    // const age = parseBirthday(birthdayPerson.dateOfBirth, event) || null;
    return (
      <span style={{ marginLeft: 4, fontWeight: 600, fontSize: 11 }}>
        {age
          ? `${event.event.title.replace("Birthday", `${age} Birthday`)}`
          : event.event.title}
      </span>
    );
  }

  if (event.event.allDay) {
    if (!event.event.extendedProps.staffAllDay) {
      return (
        <div>
          <span style={{ marginLeft: 4, fontWeight: 600, fontSize: 11 }}>
            {event.event.extendedProps.eventPrefix === "AM"
              ? `${event.event.extendedProps.eventPrefix} - `
              : event.event.extendedProps.eventPrefix === "PM"
              ? `${event.event.extendedProps.eventPrefix} - `
              : event.event.extendedProps.eventPrefix === "Custom"
              ? `${event.event.extendedProps.eventPrefixCustom} - `
              : null}
            {event.event.extendedProps.studio === "Critical Events" ||
            event.event.extendedProps.studio === "Post Production" ? (
              <span>
                {findStaff(
                  event.event.extendedProps.photographer,
                  staffData,
                  event,
                  events,
                  showDoubles
                )}
                {parseAdditionalStaff(
                  "photographer",
                  event,
                  staffData,
                  showDoubles,
                  events
                )}{" "}
                {event.event.extendedProps.photographer ? "-" : ""}{" "}
                {event.event.title}
              </span>
            ) : (
              event.event.title
            )}
          </span>
        </div>
      );
    }
  }

  const timeTextRaw = event.timeText;
  const match = timeTextRaw.match(/(.*)( -)/);
  let timeText;
  if (match) {
    timeText = match[1];
  } else timeText = timeTextRaw;
  timeText = timeText.replace("am", " am");
  timeText = timeText.replace("pm", " pm");

  const match2 = event.timeText.match(/(- )(.*)/);
  let endtimeText = event.timeText;

  if (match2) {
    endtimeText = match2[2];
  }
  endtimeText = endtimeText.replace("am", " am");
  endtimeText = endtimeText.replace("pm", " pm");

  const returnExtendedPropsTitle = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <div>
        <span style={{ ...notStaffAllDayStyle, fontSize: 10, marginBottom: 6 }}>
          {timeText}
        </span>
        <span style={{ marginLeft: 0, fontWeight: 600, fontSize: 11 }}>
          {event.event.extendedProps.eventPrefix === "AM"
            ? `${event.event.extendedProps.eventPrefix} - `
            : event.event.extendedProps.eventPrefix === "PM"
            ? `${event.event.extendedProps.eventPrefix} - `
            : event.event.extendedProps.eventPrefix === "Custom"
            ? `${event.event.extendedProps.eventPrefixCustom} - `
            : null}
          {event.event.extendedProps.studio === "Critical Events" ||
          event.event.extendedProps.studio === "Post Production" ? (
            <span>
              {findStaff(
                event.event.extendedProps.photographer,
                staffData,
                event,
                showDoubles
              )}
              {parseAdditionalStaff(
                "photographer",
                event,
                staffData,
                showDoubles,
                events
              )}{" "}
              {event.event.extendedProps.photographer ? "-" : ""}{" "}
            </span>
          ) : null}
          {event.event.extendedProps.title}
        </span>
      </div>
      <span style={{ ...notStaffAllDayStyle, fontSize: 10 }}>
        {endtimeText}
      </span>
    </div>
  );

  if (event.event.extendedProps.studio === "Critical Events") {
    return returnExtendedPropsTitle;
  }
  if (event.event.extendedProps.studio === "Post Production") {
    return returnExtendedPropsTitle;
  }
  if (event.event.extendedProps.studio === "Staff") {
    return returnExtendedPropsTitle;
  }
  if (event.event.extendedProps.studio === "Adam") {
    return returnExtendedPropsTitle;
  }
  if (!event.event.extendedProps.jobTypeFull) return "";

  let smallEvent = false;
  if (event.event.end) {
    if (!event.event.extendedProps.staffAllDay) {
      const start = new Date(event.event.bookedStart).getTime();
      const end = new Date(event.event.bookedFinish).getTime();
      if (end - start < 6000000) {
        smallEvent = true;
      }
    }
    smallEvent = false;
  }
  const clientNameContainerStyle = {
    display: "flex",
    flexDirection: event.view.type === "dayGridMonth" ? "row" : "column",
    fontSize: "0.95em",
  };

  const html = (
    <div style={containerStyle}>
      {!event.event.extendedProps.staffAllDay ? (
        <>
          {/* <span
            style={{
              width: "100%",
              background: `${event.event.extendedProps.confirmationStatus}B3`
                ? `${eventConfirmationStatus[
                    event.event.extendedProps.confirmationStatus
                  ]}B3`
                : `${eventConfirmationStatus["tbc"]}B3`,
              height: 30,
              marginLeft: -4,
              paddingLeft: 2,
              borderRadius: 3,
              // marginTop: 4,
              marginBottom: 4,
              color: "black",
              fontWeight: "bold",
            }}
          >
            {timeText}
          </span> */}
          <span style={notStaffAllDayStyle}>
            {
              <CircleIcon
                fontSize="small"
                sx={{ width: 15 }}
                style={circleIconStyle}
              />
            }
            {event.event.extendedProps.express ? (
              <CircleIcon
                fontSize="small"
                sx={{ width: 15 }}
                style={{ fill: "#d9418d", marginRight: 4, stroke: "#303030" }}
              />
            ) : null}
            {timeText}
          </span>
          {parseStudioSmall(event.event.extendedProps.studio) ? (
            <span
              style={{
                fontWeight: "bold",
                fontSize: "0.7rem",
                marginTop: 8,
                marginBottom: 8,
              }}
            >
              {parseStudioSmall(event.event.extendedProps.studio)}
            </span>
          ) : null}
          {event.view.type === "dayGridMonth" ? <span>&nbsp;</span> : null}
          <div style={clientNameContainerStyle}>
            <span
              style={{
                marginLeft: event.view.type === "dayGridMonth" ? 5 : 0,
                fontFamily:
                  '"HelveticaNeue-Bold", "Helvetica Neue Bold", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
              }}
            >
              {event.event.extendedProps.isAdditionalDate ? "🔗 " : ""}
              {event.event.extendedProps.client}{" "}
              {event.event.extendedProps.multipleDays
                ? ` - ${event.event.extendedProps.multipleDays}`
                : ""}{" "}
              {event.event.extendedProps.comments
                ? ` - ${event.event.extendedProps.comments}`
                : ""}{" "}
              {event.event.extendedProps.imagesScheduledToBeShot
                ? ` - ${event.event.extendedProps.imagesScheduledToBeShot}`
                : ""}
            </span>
            <span
              style={{
                marginLeft: event.view.type === "dayGridMonth" ? 5 : 0,
                fontSize: 11,
              }}
            >
              {event.event.extendedProps.jobNumber}{" "}
              {event.event.extendedProps.jobTypeFull ? "-" : ""}{" "}
              {smallEvent
                ? event.event.extendedProps.jobTypeFull.replace(/ \(.*\)/, "")
                : event.event.extendedProps.jobTypeFull}
            </span>
            <span style={{ marginTop: 10 }}>
              {event.event.extendedProps.eventNotes}
            </span>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              fontWeight: "bold",
              fontSize: "0.7rem",
            }}
          >
            {endtimeText}
          </div>
        </>
      ) : (
        <div style={staffAllDayStyle}>
          {event.event.extendedProps.eventPrefix === "AM"
            ? `${event.event.extendedProps.eventPrefix} - `
            : event.event.extendedProps.eventPrefix === "PM"
            ? `${event.event.extendedProps.eventPrefix} - `
            : event.event.extendedProps.eventPrefix === "Custom"
            ? `${event.event.extendedProps.eventPrefixCustom} - `
            : null}
          {parseShooter(event.event.extendedProps)}{" "}
          {findStaff(
            event.event.extendedProps.photographer,
            staffData,
            event,
            events,
            showDoubles
          )}
          {parseAdditionalStaff(
            "photographer",
            event,
            staffData,
            showDoubles,
            events
          )}{" "}
          {parseOperator(event.event.extendedProps)}{" "}
          {findStaff(
            event.event.extendedProps.operator,
            staffData,
            event,
            events,
            showDoubles
          )}
          {parseAdditionalStaff(
            "operator",
            event,
            staffData,
            showDoubles,
            events
          )}{" "}
          (S){" "}
          {findStaff(
            event.event.extendedProps.stylist,
            staffData,
            event,
            events,
            showDoubles
          )}
          {parseAdditionalStaff(
            "stylist",
            event,
            staffData,
            showDoubles,
            events
          )}
        </div>
      )}
    </div>
  );

  return html;
};
const checkIfStaffEmployed = (staff, staffData) => {
  let res = true;
  staffData.forEach((sd) => {
    if (sd.id === staff) {
      if (!sd.currentlyEmployed) {
        res = false;
      }
    }
  });
  return res;
};
const findStaff = (staff, staffData, event, events, showDoubles) => {
  for (let i = 0; i < staffData.length; i++) {
    if (staffData[i].id === staff) {
      if (staffData[i].preferredName)
        return (
          <span
            style={{
              display: "inline",
              color: checkIfStaffAssigned(
                staffData[i],
                events,
                event,
                showDoubles
              )
                ? "#e53232"
                : checkIfStaffEmployed(staff, staffData)
                ? ""
                : "#e532ac",
              textDecoration: checkIfStaffAssigned(
                staffData[i],
                events,
                event,
                showDoubles
              )
                ? "underline"
                : checkIfStaffEmployed(staff, staffData)
                ? ""
                : "underline",
            }}
          >
            {staffData[i].preferredName}
          </span>
        );
      return (
        <span
          style={{
            display: "inline",
            color: checkIfStaffAssigned(
              staffData[i],
              events,
              event,
              showDoubles
            )
              ? "#e53232"
              : checkIfStaffEmployed(staff, staffData)
              ? ""
              : "#e532ac",
            textDecoration: checkIfStaffAssigned(
              staffData[i],
              events,
              event,
              showDoubles
            )
              ? "underline"
              : checkIfStaffEmployed(staff, staffData)
              ? ""
              : "underline",
          }}
        >
          {staffData[i].firstName}
        </span>
      );
    }
  }
  return staff;
};
const parseAdditionalStaff = (kind, event, staffData, showDoubles, events) => {
  const additionalStaff =
    event.event.extendedProps[`additional${capitalizeFirstLetter(kind)}s`];
  if (!additionalStaff) return;
  const value = additionalStaff.map((s, index) => {
    return (
      <span key={`${s}-${index}`}>
        /{findStaff(s, staffData, event, showDoubles)}
      </span>
    );
  });
  return value;
};
const parseShooter = (data) => {
  if (data.jobTypeFull.toLowerCase().includes("video")) {
    return `(V) `;
  } else {
    return `(P) `;
  }
};
const parseOperator = (data) => {
  if (data.jobTypeFull.toLowerCase().includes("video")) {
    return `(VO) `;
  } else {
    return `(O) `;
  }
};
const onEventClick = (
  event,
  setEventDataModalOpen,
  setEventDataModal,
  hideDock
) => {
  setEventDataModal({});
  if (!event.event.extendedProps.staffAllDay) {
    setEventDataModalOpen(true);
    setTimeout(() => {
      hideDock();
    }, 150);
    setTimeout(() => {
      setEventDataModal(event.event);
      // history.push({
      //   search: `?jobID=${event.event.id}`,
      // });
    }, 5);
  }
};
const parseStartEndTime = (
  bookedStart,
  bookedFinish,
  shootDate,
  kind,
  data
) => {
  let which;
  if (kind === "startDate") {
    which = bookedStart;
  } else if (kind === "endDate") {
    which = bookedFinish;
  }
  try {
    shootDate = shootDate.toDate();
  } catch (e) {
    shootDate = parseDate(shootDate);
  }
  try {
    shootDate.setMinutes(which.toDate().getMinutes());
    shootDate.setHours(which.toDate().getHours());

    const newDate = new Date(shootDate);
    return newDate.toISOString();
  } catch (e) {
    return new Date(which);
  }
};
const parseDate = (date) => {
  if (!date) {
    return null;
  }
  const datepattern = new RegExp(/\D* \d\d\/\d\d\/\d\d\d\d/);
  let datetmp;
  try {
    datepattern.test(date)
      ? (datetmp = parseit(date))
      : (datetmp = date.toDate());
    datetmp = new Date(datetmp);
  } catch (e) {
    datetmp = null;
  }
  return datetmp;

  function parseit(date) {
    const replace = date.replace(/\D\D\D /, "");
    const split = replace.split("/");
    let newDate = `${split[2]}-${split[1]}-${split[0]}`;
    newDate = newDate.toString().replace(/ /g, "");
    return newDate;
  }
};
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
function getFilterDate(date) {
  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-",
    pad = function (num) {
      return (num < 10 ? "0" : "") + num;
    };

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ":" +
    pad(Math.abs(tzo) % 60)
  );
}
const checkIfStaffAssigned = (option, events, event, showDoubles, t) => {
  if (!option) return false;
  if (!events) return false;
  if (!event) return false;
  if (!event.event) return false;
  if (!showDoubles) return false;
  let jobCount = 0;
  let studio;
  let recurringStaffEvents = [];
  events.forEach((e) => {
    if (e?.extendedProps?.recurringEvent) {
      recurringStaffEvents.push(e);
    }
  });
  let recurringStaffEventsRrule = [];
  recurringStaffEvents.forEach((e) => {
    let freq = parseFreq(e.rrule.freq);
    let byweekday = parseByWeekDay(e.rrule.byweekday);

    const rule = new RRule({
      freq: freq,
      interval: e.rrule.interval,
      byweekday: byweekday,
      dtstart: new Date(e.rrule.dtstart),
      until: new Date(e.rrule.until),
    });
    if (e?.extendedProps?.photographer === option.id) {
      recurringStaffEventsRrule.push({
        event: e,
        staff: e?.extendedProps?.photographer,
        dates: rule.all(),
      });
    }
  });
  recurringStaffEventsRrule.forEach((r) => {
    r?.dates?.forEach((d) => {
      const repeatDateRaw = new Date(d);
      const repeatDate = `${repeatDateRaw.getFullYear()}-${
        repeatDateRaw.getMonth() + 1
      }-${repeatDateRaw.getDate()}`;
      const date = `${event.event.start.getFullYear()}-${
        event.event.start.getMonth() + 1
      }-${event.event.start.getDate()}`;

      if (repeatDate === date) {
        jobCount = jobCount + 1;
      }
    });
  });

  if (jobCount > 1) {
    return true;
  }

  const date = `${event.event.start.getFullYear()}-${
    event.event.start.getMonth() + 1
  }-${event.event.start.getDate()}`;
  for (let i = 0; i < events.length; i++) {
    let e = events[i];
    if (!e.start) continue;
    let start = new Date(e.start);
    let d = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`;
    if (d === date) {
      if (e?.extendedProps?.photographer === option.id) {
        if (!studio) {
          studio = e?.extendedProps?.studio;
          jobCount = jobCount + 1;
        }
        if (studio && studio !== e?.extendedProps?.studio)
          jobCount = jobCount + 1;
      }
      if (e?.extendedProps?.operator === option.id) {
        if (!studio) {
          studio = e?.extendedProps?.studio;
          jobCount = jobCount + 1;
        }
        if (studio && studio !== e?.extendedProps?.studio)
          jobCount = jobCount + 1;
      }
      if (e?.extendedProps?.stylist === option.id) {
        if (!studio) {
          studio = e?.extendedProps?.studio;
          jobCount = jobCount + 1;
        }
        if (studio && studio !== e?.extendedProps?.studio)
          jobCount = jobCount + 1;
      }

      if (e?.extendedProps?.additionalPhotographers?.includes(option.id)) {
        if (!studio) {
          studio = e?.extendedProps?.studio;
          jobCount = jobCount + 1;
        }
        if (studio && studio !== e?.extendedProps?.studio)
          jobCount = jobCount + 1;
      }
      if (e?.extendedProps?.additionalOperators?.includes(option.id)) {
        if (!studio) {
          studio = e?.extendedProps?.studio;
          jobCount = jobCount + 1;
        }
        if (studio && studio !== e?.extendedProps?.studio)
          jobCount = jobCount + 1;
      }
      if (e?.extendedProps?.additionalStylists?.includes(option.id)) {
        if (!studio) {
          studio = e?.extendedProps?.studio;
          jobCount = jobCount + 1;
        }
        if (studio && studio !== e?.extendedProps?.studio)
          jobCount = jobCount + 1;
      }
    }
  }
  if (jobCount > 1) {
    return true;
  }
  return false;
};
function parseFreq(freq) {
  if (freq === "weekly") return RRule.WEEKLY;
}
function parseByWeekDay(byweekday) {
  const array = [];
  byweekday.forEach((w) => {
    if (w === "mo") {
      array.push(RRule.MO);
    } else if (w === "tu") {
      array.push(RRule.TU);
    } else if (w === "we") {
      array.push(RRule.WE);
    } else if (w === "th") {
      array.push(RRule.TH);
    } else if (w === "fr") {
      array.push(RRule.FR);
    } else if (w === "sa") {
      array.push(RRule.SA);
    } else if (w === "su") {
      array.push(RRule.SU);
    }
  });
  return array;
}
const isEventTomorrow = (isoString) => {
  const jobTime = DateTime.fromISO(isoString).setZone("Australia/Sydney");
  const tomorrowStart = DateTime.now()
    .setZone("Australia/Sydney")
    .startOf("day")
    .plus({ days: 1 });

  const tomorrowEnd = DateTime.now()
    .setZone("Australia/Sydney")
    .endOf("day")
    .plus({ days: 1 });

  return (
    tomorrowStart.toSeconds() <= jobTime.toSeconds() &&
    jobTime.toSeconds() <= tomorrowEnd.toSeconds()
  );
};
const sendTimeChangeNotification = (
  eventStart,
  id,
  client,
  jobType,
  isEventTomorrow,
  sendNotificationToUID
) => {
  if (id === "8jXVYoCymxHLQngklbWE") {
    if (isEventTomorrow(new Date(eventStart).toISOString())) {
      try {
        sendNotificationToUID({
          id: id,
          title: `TIME CHANGED - ${client} - ${jobType.replace(/ \(.*\)/, "")}`,
          text: `The time for your job tomorrow has changed, please tap to check the calendar.`,
          jumpto: new Date(eventStart).getTime(),
          kind: "notificationNextDayChangesMe",
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
};
function randomIntFromInterval(min, max, firebase, specialNums, isMobile) {
  // min and max included
  const db = firebase.firestore();
  const increment = firebase.firestore.FieldValue.increment(1);
  const randRef = db.collection("increments").doc("randomIntFromInterval");
  randRef.update({ int: increment });

  let rand = Math.floor(Math.random() * (max - min + 1) + min);
  if (isMobile) rand = rand + 1;

  specialNums.forEach((num) => {
    if (rand === num) {
      randRef.update({ specialCalled: increment });
    }
  });
  return rand;
}
// eslint-disable-next-line
function parseBirthday(birthday, e) {
  try {
    const bday = new Date(birthday);
    const event = new Date(e.event.start);
    const eventYear = event.getFullYear();
    const bdayYear = bday.getFullYear();
    const age = eventYear - bdayYear;
    return `${age}${nthNumber(age)}`;
  } catch (e) {
    return null;
  }
}
const nthNumber = (number) => {
  if (number > 3 && number < 21) return "th";
  switch (number % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
const parseStudioSmall = (studio) => {
  if (studio === "Studio 1") {
    return "Suite 401";
  } else if (studio === "Studio 2A") {
    return "Suite 402 W";
  } else if (studio === "Studio 2B") {
    return "Suite 402 M";
  } else if (studio === "Studio 2C") {
    return "Suite 402 D";
  } else if (studio === "Studio 3") {
    return "Suite 302";
  } else if (studio === "Studio 4") {
    return "Suite 301";
  } else return "";
};
function setAlphaToOne(rgbaString) {
  const match = rgbaString.match(
    /^rgba\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*[\d.]+\s*\)$/
  );
  return match
    ? `rgba(${match[1]}, ${match[2]}, ${match[3]}, 1.0)`
    : rgbaString;
}
function getTimeDifferenceInHours(isoString1, isoString2) {
  const dateTime1 = DateTime.fromISO(isoString1);
  const dateTime2 = DateTime.fromISO(isoString2);
  const diffInMilliseconds = dateTime2.diff(
    dateTime1,
    "milliseconds"
  ).milliseconds;
  const hoursDifference = diffInMilliseconds / (1000 * 60 * 60);
  return parseFloat(hoursDifference.toFixed(1));
}
