import React, { useState, useEffect } from "react";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const Options = ({ client, updateFSNow, theme }) => {
  const [fileNaming, setFileNaming] = useState(client.fileNaming);
  const [shootDrivePhoto, setShootDrivePhoto] = useState(
    client.shootDrivePhoto
  );
  const [shootDriveVideo, setShootDriveVideo] = useState(
    client.shootDriveVideo
  );
  const [ghostFront, setGhostFront] = useState(client.ghostFront);
  const [ghostBack, setGhostBack] = useState(client.ghostBack);
  const [ghost34, setGhost34] = useState(client.ghost34);
  const [ghostOther, setGhostOther] = useState(client.ghostOther);
  const [flatlayFront, setFlatlayFront] = useState(client.flatlayFront);
  const [flatlayBack, setFlatlayBack] = useState(client.flatlayBack);
  const [flatlayOther, setFlatlayOther] = useState(client.flatlayOther);
  const [shoesFront, setShoesFront] = useState(client.shoesFront);
  const [shoesRight, setShoesRight] = useState(client.shoesRight);
  const [shoesLeft, setShoesLeft] = useState(client.shoesLeft);
  const [showsSole, setShowsSole] = useState(client.showsSole);
  const [shoesAbove, setShoesAbove] = useState(client.shoesAbove);
  const [shoesPair, setShoesPair] = useState(client.shoesPair);
  const [shoesOther, setShoesOther] = useState(client.shoesOther);
  const [referencesAttached, setReferencesAttached] = useState(
    client.referencesAttached
  );
  const [referenceLastJob, setReferenceLastJob] = useState(
    client.referenceLastJob
  );
  const [clientPresentOnSet, setClientPresentOnSet] = useState(
    client.clientPresentOnSet
  );
  const [productFront, setProductFront] = useState(client.productFront);
  const [productSide, setProductSide] = useState(client.productSide);
  const [productBack, setProductBack] = useState(client.productBack);
  const [productDetail, setProductDetail] = useState(client.productDetail);
  const [productReferToLastJob, setProductReferToLastJob] = useState(
    client.productReferToLastJob
  );
  const [treatmentShadow, setTreatmentShadow] = useState(
    client.treatmentShadow
  );
  const [treatmentNoShadow, setTreatmentNoShadow] = useState(
    client.treatmentNoShadow
  );
  const [treatmentBackground, setTreatmentBackground] = useState(
    client.treatmentBackground
  );
  const [treatmentOther, setTreatmentOther] = useState(client.treatmentOther);

  useEffect(() => {
    setFileNaming(client.fileNaming);
    setShootDrivePhoto(client.shootDrivePhoto);
    setShootDriveVideo(client.shootDriveVideo);
    setGhostFront(client.ghostFront);
    setGhostBack(client.ghostBack);
    setGhost34(client.ghost34);
    setGhostOther(client.ghostOther);
    setFlatlayFront(client.flatlayFront);
    setFlatlayBack(client.flatlayBack);
    setFlatlayOther(client.flatlayOther);
    setShoesFront(client.shoesFront);
    setShoesRight(client.shoesRight);
    setShoesLeft(client.shoesLeft);
    setShowsSole(client.showsSole);
    setShoesAbove(client.shoesAbove);
    setShoesPair(client.shoesPair);
    setShoesOther(client.shoesOther);
    setReferencesAttached(client.referencesAttached);
    setReferenceLastJob(client.referenceLastJob);
    setClientPresentOnSet(client.clientPresentOnSet);
    setProductFront(client.productFront);
    setProductSide(client.productSide);
    setProductBack(client.productBack);
    setProductDetail(client.productDetail);
    setProductReferToLastJob(client.productReferToLastJob);
    setTreatmentShadow(client.treatmentShadow);
    setTreatmentNoShadow(client.treatmentNoShadow);
    setTreatmentBackground(client.treatmentBackground);
    setTreatmentOther(client.treatmentOther);
  }, [client]);

  const handleFileNamingChange = () => {
    setFileNaming(!fileNaming);
    updateFSNow(client.id, "fileNaming", !fileNaming);
  };
  const handleshootDrivePhotoChange = () => {
    setShootDrivePhoto(!shootDrivePhoto);
    updateFSNow(client.id, "shootDrivePhoto", !shootDrivePhoto);
  };
  const handleShootDriveVideoChange = () => {
    setShootDriveVideo(!shootDriveVideo);
    updateFSNow(client.id, "shootDriveVideo", !shootDriveVideo);
  };
  const handleGhostFrontChange = () => {
    setGhostFront(!ghostFront);
    updateFSNow(client.id, "ghostFront", !ghostFront);
  };
  const handleGhostBackChange = () => {
    setGhostBack(!ghostBack);
    updateFSNow(client.id, "ghostBack", !ghostBack);
  };
  const handleGhost34Change = () => {
    setGhost34(!ghost34);
    updateFSNow(client.id, "ghost34", !ghost34);
  };
  const handleGhostOtherChange = () => {
    setGhostOther(!ghostOther);
    updateFSNow(client.id, "ghostOther", !ghostOther);
  };
  const handleFlatlayFrontChange = () => {
    setFlatlayFront(!flatlayFront);
    updateFSNow(client.id, "flatlayFront", !flatlayFront);
  };
  const handleFlatlayBackChange = () => {
    setFlatlayBack(!flatlayBack);
    updateFSNow(client.id, "flatlayBack", !flatlayBack);
  };
  const handleFlatlayOtherChange = () => {
    setFlatlayOther(!flatlayOther);
    updateFSNow(client.id, "flatlayOther", !flatlayOther);
  };
  const handleShoesFrontChange = () => {
    setShoesFront(!shoesFront);
    updateFSNow(client.id, "shoesFront", !shoesFront);
  };
  const handleShoesRightChange = () => {
    setShoesRight(!shoesRight);
    updateFSNow(client.id, "shoesRight", !shoesRight);
  };
  const handleShoesLeftChange = () => {
    setShoesLeft(!shoesLeft);
    updateFSNow(client.id, "shoesLeft", !shoesLeft);
  };
  const handleShowsSoleChange = () => {
    setShowsSole(!showsSole);
    updateFSNow(client.id, "showsSole", !showsSole);
  };
  const handleShoesAboveChange = () => {
    setShoesAbove(!shoesAbove);
    updateFSNow(client.id, "shoesAbove", !shoesAbove);
  };
  const handleShoesPairChange = () => {
    setShoesPair(!shoesPair);
    updateFSNow(client.id, "shoesPair", !shoesPair);
  };
  const handleShoesOtherChange = () => {
    setShoesOther(!shoesOther);
    updateFSNow(client.id, "shoesOther", !shoesOther);
  };
  const handleReferencesAttachedChange = () => {
    setReferencesAttached(!referencesAttached);
    updateFSNow(client.id, "referencesAttached", !referencesAttached);
  };
  const handleReferenceLastJobChange = () => {
    setReferenceLastJob(!referenceLastJob);
    updateFSNow(client.id, "referenceLastJob", !referenceLastJob);
  };
  const handleClientPresentOnSetChange = () => {
    setClientPresentOnSet(!clientPresentOnSet);
    updateFSNow(client.id, "clientPresentOnSet", !clientPresentOnSet);
  };
  const handleProductFrontChange = () => {
    setProductFront(!productFront);
    updateFSNow(client.id, "productFront", !productFront);
  };
  const handleProductSideChange = () => {
    setProductSide(!productSide);
    updateFSNow(client.id, "productSide", !productSide);
  };
  const handleProductBackChange = () => {
    setProductBack(!productBack);
    updateFSNow(client.id, "productBack", !productBack);
  };
  const handleProductDetailChange = () => {
    setProductDetail(!productDetail);
    updateFSNow(client.id, "productDetail", !productDetail);
  };
  const handleProductReferToLastJobChange = () => {
    setProductReferToLastJob(!productReferToLastJob);
    updateFSNow(client.id, "productReferToLastJob", !productReferToLastJob);
  };
  const handleTreatmentShadowChange = () => {
    setTreatmentShadow(!treatmentShadow);
    updateFSNow(client.id, "treatmentShadow", !treatmentShadow);
  };
  const handleTreatmentNoShadowChange = () => {
    setTreatmentNoShadow(!treatmentNoShadow);
    updateFSNow(client.id, "treatmentNoShadow", !treatmentNoShadow);
  };
  const handleTreatmentBackgroundChange = () => {
    setTreatmentBackground(!treatmentBackground);
    updateFSNow(client.id, "treatmentBackground", !treatmentBackground);
  };
  const handleTreatmentOtherChange = () => {
    setTreatmentOther(!treatmentOther);
    updateFSNow(client.id, "treatmentOther", !treatmentOther);
  };

  const cardStyle = {
    background: theme==="dark"?"#ffffff05":"#00000005",
    borderRadius: "10px",
    padding: "10px",
    margin: "10px",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    minWidth: "200px",
    maxWidth: "300px",
  };

  return (
    <div
      style={{
        maxWidth: "60vw",
        overflow: "auto",
        maxHeight: "calc(100vh - 220px)",
      }}
    >
      <FormGroup
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          paddingBottom: "50px",
        }}
      >
        <div style={cardStyle}>
          <FormControlLabel
            control={
              <Checkbox
                checked={shootDrivePhoto}
                onChange={handleshootDrivePhotoChange}
              />
            }
            label="Shoot Drive Photo"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={shootDriveVideo}
                onChange={handleShootDriveVideoChange}
              />
            }
            label="Shoot Drive Video"
          />
        </div>
        <div style={cardStyle}>
          <FormControlLabel
            control={
              <Checkbox
                checked={fileNaming}
                onChange={handleFileNamingChange}
              />
            }
            label="File Naming"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={referencesAttached}
                onChange={handleReferencesAttachedChange}
              />
            }
            label="References Attached"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={referenceLastJob}
                onChange={handleReferenceLastJobChange}
              />
            }
            label="Reference Last Job"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={clientPresentOnSet}
                onChange={handleClientPresentOnSetChange}
              />
            }
            label="Client Present On Set"
          />
        </div>
        <div style={cardStyle}>
          <FormControlLabel
            control={
              <Checkbox
                checked={ghostFront}
                onChange={handleGhostFrontChange}
              />
            }
            label="Ghost Front"
          />
          <FormControlLabel
            control={
              <Checkbox checked={ghostBack} onChange={handleGhostBackChange} />
            }
            label="Ghost Back"
          />
          <FormControlLabel
            control={
              <Checkbox checked={ghost34} onChange={handleGhost34Change} />
            }
            label="Ghost 3/4"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={ghostOther}
                onChange={handleGhostOtherChange}
              />
            }
            label="Ghost Other"
          />
        </div>
        <div style={cardStyle}>
          <FormControlLabel
            control={
              <Checkbox
                checked={flatlayFront}
                onChange={handleFlatlayFrontChange}
              />
            }
            label="Flatlay Front"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flatlayBack}
                onChange={handleFlatlayBackChange}
              />
            }
            label="Flatlay Back"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flatlayOther}
                onChange={handleFlatlayOtherChange}
              />
            }
            label="Flatlay Other"
          />
        </div>

        <div style={cardStyle}>
          <FormControlLabel
            control={
              <Checkbox
                checked={productFront}
                onChange={handleProductFrontChange}
              />
            }
            label="Product Front"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={productSide}
                onChange={handleProductSideChange}
              />
            }
            label="Product Side"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={productBack}
                onChange={handleProductBackChange}
              />
            }
            label="Product Back"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={productDetail}
                onChange={handleProductDetailChange}
              />
            }
            label="Product Detail"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={productReferToLastJob}
                onChange={handleProductReferToLastJobChange}
              />
            }
            label="Product Refer To Last"
          />
        </div>
        <div style={cardStyle}>
          <FormControlLabel
            control={
              <Checkbox
                checked={treatmentShadow}
                onChange={handleTreatmentShadowChange}
              />
            }
            label="Treatment Shadow"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={treatmentNoShadow}
                onChange={handleTreatmentNoShadowChange}
              />
            }
            label="Treatment No Shadow"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={treatmentBackground}
                onChange={handleTreatmentBackgroundChange}
              />
            }
            label="Treatment Background"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={treatmentOther}
                onChange={handleTreatmentOtherChange}
              />
            }
            label="Treatment Other"
          />
        </div>
        <div style={cardStyle}>
          <FormControlLabel
            control={
              <Checkbox
                checked={shoesFront}
                onChange={handleShoesFrontChange}
              />
            }
            label="Shoes Front"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={shoesRight}
                onChange={handleShoesRightChange}
              />
            }
            label="Shoes Right"
          />
          <FormControlLabel
            control={
              <Checkbox checked={shoesLeft} onChange={handleShoesLeftChange} />
            }
            label="Shoes Left"
          />
        </div>
        <div style={cardStyle}>
          <FormControlLabel
            control={
              <Checkbox checked={showsSole} onChange={handleShowsSoleChange} />
            }
            label="Shows Sole"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={shoesAbove}
                onChange={handleShoesAboveChange}
              />
            }
            label="Shoes Above"
          />
          <FormControlLabel
            control={
              <Checkbox checked={shoesPair} onChange={handleShoesPairChange} />
            }
            label="Shoes Pair"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={shoesOther}
                onChange={handleShoesOtherChange}
              />
            }
            label="Shoes Other"
          />
        </div>
      </FormGroup>
    </div>
  );
};

export default Options;
