import { useState, useEffect } from 'react'


export function DetectLightDarkMode() {
    const [colourState, setColourState] = useState('dark')
    useEffect(() => {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => setColourState(e.matches ? 'dark' : 'light'));

        // Setup dark/light mode for the first time
        setColourState(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')

        // Remove listener
        return () => {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {
            });
        }
    }, []);
    return colourState
}

export function isDarkMode() {
    if (DetectLightDarkMode() === 'dark') { return true } else return false
}